import axiosClient from "api/axiosClient";

export interface BenefitsTiles {
  ItemID: number;
  UnconfirmedCompanies: number;
  IncompleteCompanies: number;
  PaymentPeriodsNeedingReview: number;
  EmptyWorkHourPeriods: number;
}

export interface BenefitsTilesResult {
  benefitsTiles: BenefitsTiles[];
}

export async function getBenefitsTiles(
  accessToken: String
): Promise<BenefitsTilesResult> {
  const url = `/charts/benefitsTiles`;

  try {
    const { data } = await axiosClient.get<BenefitsTilesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
