import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchSchedulers } from "./SchedulerSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";

export const SchedulerInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    schedulerList,
    isLoading,
    error: schedulersError,
    schedulersById,
  } = useSelector((state: RootState) => state.schedulers);

  const schedulers = schedulerList.map((id) => schedulersById[id]);

  const dataColumns = [
    { field: "ScheduledNotificationTypeName", title: "Notification Type" },
    {
      field: "TriggerDate",
      title: "Trigger Date",
      format: "{0:d}",
      filter: "date",
    },
    { field: "RepeatNotification", title: "Repeat?", filter: "boolean" },
    { field: "ItemCompleted", title: "Completed?", filter: "boolean" },
  ];

  const data = schedulers.map((p) => {
    let newItem = Object.assign({}, p) as any;
    if (p.TriggerDate) {
      newItem.TriggerDate = ConvertDateOffset(p.TriggerDate);
    } else {
      newItem.TriggerDate = null;
    }

    return newItem;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchSchedulers(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (schedulersError) {
    return <ErrorPage errMsg={schedulersError} />;
  }

  let schedulerInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Scheduled Notifications"
        tableData={data}
        idField="SchedulerID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        showAdd={true}
        showUpload={false}
        showTemplate={false}
        sortColumn={1}
        sortReverse={true}
      />
    </div>
  );

  return <div id="scheduler-inventory-page">{schedulerInvView}</div>;
};
