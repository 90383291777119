import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import {
  bulkApprovePayPeriod,
  fetchPaymentPeriods,
  fetchPaymentPeriodsByCompany,
} from "./PaymentPeriodSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";
import { fetchUnconfirmedCompanies } from "../companies/CompaniesSlice";
import { IsMedSmall } from "utils/mediaQueries";

interface paymentPeriodInvProps {
  companyID?: number;
}

export const PaymentPeriodInventoryPage = (props: paymentPeriodInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const compID = props.companyID;

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    paymentPeriodList,
    isLoading,
    error: paymentPeriodsError,
    paymentPeriodsById,
  } = useSelector((state: RootState) => state.paymentPeriods);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const paymentPeriods = paymentPeriodList.map((id) => paymentPeriodsById[id]);

  const { companyList, companiesById } = useSelector(
    (state: RootState) => state.companies
  );

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);

  const pmts = paymentPeriods.map((p) => {
    let newPaymentPeriod = Object.assign({}, p) as any;

    newPaymentPeriod.PaymentPeriodDate = ConvertDateOffset(p.EndDate);
    let date = new Date(p.EndDate);
    // Format the PaymentPeriodDate into "YYYY-MMM" (e.g., 2023-DEC)
    const year = date.getFullYear();
    const month = date
      .toLocaleString("default", {
        month: "short",
      })
      .toUpperCase();

    newPaymentPeriod.Year = year;
    newPaymentPeriod.Month = month;

    // Show 'Referred Back' status chip in Stage column when record is Returned for Correction
    if (p.PaymentPeriodStatusID === 4) {
      newPaymentPeriod.WorkflowStageName = "Referred Back";
    }

    return newPaymentPeriod;
  });

  const pmtCols = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "text",
      format: "SR-{0:0}",
      columnWidth: 80,
    },
    { field: "CompanyName", title: t("objBen:objects.company.name") },
    {
      field: "PaymentPeriodDate",
      title: "Date",
      monthyear: true,
      columnWidth: 110,
    },
    { field: "ProjectName", title: t("objPlt:objects.project.name") },
    ...(IsMedSmall()
      ? []
      : [
          {
            field: "ProjectComponentName",
            title: t("objPlt:objects.projectcomponent.name"),
          },
        ]),
    {
      field: "WorkflowStageName",
      title: t("strGen:inventory.fields.stage"),
      chips: true,
      className: "Submitted for Review",
      columnWidth: 120,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (compID) {
          dispatch(fetchPaymentPeriodsByCompany(accessToken, compID));
        } else {
          dispatch(fetchPaymentPeriods(accessToken));
        }
        // for bulk approval
        if (currentProfile.IsClientAdmin) {
          dispatch(fetchUnconfirmedCompanies(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [compID, dispatch, getAccessTokenSilently]);

  if (paymentPeriodsError) {
    return <ErrorPage errMsg={paymentPeriodsError} />;
  }

  const bulkContent =
    companies.length > 0
      ? [
          `There are ${companies.length} Company Requests that have not yet been approved. Please address these requests that can be found by clicking New Company Requests under the Companies list.`,
          true,
        ]
      : [
          `This will set all periods' status in the currently filtered data as completed. This action cannot be undone. Do you want to continue?`,
          false,
        ];

  const bulkCompletePeriods = (paymentPeriods: any) => {
    // function to delete current Payment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(bulkApprovePayPeriod(accessToken, paymentPeriods));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let paymentPeriodInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objBen:objects.spendreporting.name_other")}
        tableData={pmts}
        idField="PaymentPeriodID"
        columns={pmtCols}
        isFiltered={true}
        showDetail={true}
        showAdd={true}
        showUpload={false}
        showTemplate={false}
        sortColumn={2}
        sortReverse={true}
        searchByColumn={"CompanyName"}
        linkColumn={0}
        searchByPlaceholder={t("strGen:filters.searchfor", {
          fieldname: t("objBen:objects.company.fields.companyname"),
        })}
        bulkTitle={currentProfile.IsClientAdmin ? "Approve Reports" : undefined}
        bulkAction={bulkCompletePeriods}
        bulkContent={bulkContent}
      />
    </div>
  );

  return <div id="paymentPeriod-inventory-page">{paymentPeriodInvView}</div>;
};
