import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchActionReminders } from "./ActionReminderSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";

export const ActionReminderInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    actionReminderList,
    isLoading,
    error: actionRemindersError,
    actionRemindersById,
  } = useSelector((state: RootState) => state.actionReminders);

  const actionReminders = actionReminderList.map(
    (id) => actionRemindersById[id]
  );

  const dataColumns = [
    { field: "ActionSource", title: "Reminder Source" },
    { field: "OffsetDescription", title: "Date Offset" },
  ];

  const data = actionReminders.map((p) => {
    let newItem = Object.assign({}, p) as any;
    let OffsetDescription = "";
    if (p.ActionOffset === 0) {
      OffsetDescription = "Same Day";
    } else if (p.ActionOffset > 0) {
      OffsetDescription = p.ActionOffset.toString() + " days before";
    } else {
      OffsetDescription = -p.ActionOffset.toString() + " days after";
    }
    newItem.OffsetDescription = OffsetDescription;
    return newItem;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchActionReminders(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (actionRemindersError) {
    return <ErrorPage errMsg={actionRemindersError} />;
  }

  let actionReminderInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <IppInventory
      title="Reminders"
      tableData={data}
      idField="ActionReminderID"
      columns={dataColumns}
      isFiltered={true}
      showDetail={true}
      showAdd={true}
      showUpload={false}
      showTemplate={false}
      sortColumn={1}
      sortReverse={true}
    />
  );

  return <div id="actionReminder-inventory-page">{actionReminderInvView}</div>;
};
