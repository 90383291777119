import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Loader } from "@progress/kendo-react-indicators";
import { useTypedTranslation } from "utils/customHooks";

interface LoadingIndicatorProps {
  headerMessage?: string;
  footerMessage?: string;
  message?: string;
}

function LoadingIndicator({
  headerMessage,
  footerMessage,
}: LoadingIndicatorProps) {
  const [count, setCount] = useState(0);
  const t = useTypedTranslation(["strGen"]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(1);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100%" }}
    >
      {headerMessage && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            align={"center"}
            gutterBottom
          >
            {headerMessage}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          variant="body1"
          fontWeight="bold"
          align="center"
          gutterBottom
        >
          {count === 0
            ? t("strGen:components.loadingindicator.loading")
            : t("strGen:components.loadingindicator.sorry")}
        </Typography>
        {count === 1 && (
          <Typography
            variant="body1"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            {t("strGen:components.loadingindicator.stringingsprites")}
          </Typography>
        )}
        <Loader
          type={count === 0 ? "converging-spinner" : "infinite-spinner"}
          size="large"
          themeColor={count === 0 ? "primary" : "success"}
          style={{
            position: "inherit",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Grid>
      {footerMessage && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            {footerMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default LoadingIndicator;
