import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Thread,
  ThreadsResult,
  getThreads,
  createThread,
  updateThread,
  deleteThread,
  getThreadsByProject,
} from "api/stakeholder/threadAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { fetchThreadInteractionsByInteraction } from "./ThreadInteractionSlice";

interface ThreadState {
  threadsById: Record<number, Thread>;
  threadList: number[];
  isLoading: boolean;
  error: string | null;
}

const ThreadInitialState: ThreadState = {
  threadsById: {},
  threadList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ThreadState) {
  state.isLoading = true;
}

function loadingFailed(state: ThreadState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
  state.threadList = [];
}

const threads = createSlice({
  name: "threads",
  initialState: ThreadInitialState,
  reducers: {
    getThreadsStart: startLoading,
    getThreadsSuccess(state, { payload }: PayloadAction<ThreadsResult>) {
      const { threads } = payload;
      state.isLoading = false;
      state.error = null;

      threads.forEach((thread) => {
        state.threadsById[thread.ThreadID] = thread;
      });

      state.threadList = threads.map((thread) => thread.ThreadID);
    },

    getThreadsFailure: loadingFailed,
    createThreadStart: startLoading,
    createThreadSuccess(state, { payload }: PayloadAction<Thread>) {
      const { ThreadID } = payload;
      state.threadsById[ThreadID] = payload;
      state.threadList.push(ThreadID);
      state.isLoading = false;
      state.error = null;
    },
    updateThreadSuccess(state, { payload }: PayloadAction<Thread>) {
      const { ThreadID } = payload;
      state.threadsById[ThreadID] = payload;
      state.isLoading = false;
      state.error = null;
    },
    deleteThreadSuccess(state, { payload }: PayloadAction<number>) {
      const ThreadID = payload;
      delete state.threadsById[ThreadID];
      state.threadList = state.threadList.filter((item) => item !== ThreadID);
      state.isLoading = false;
      state.error = null;
    },
    createThreadFailure: loadingFailed,
  },
});

export const {
  getThreadsStart,
  getThreadsSuccess,
  getThreadsFailure,
  createThreadStart,
  createThreadSuccess,
  updateThreadSuccess,
  deleteThreadSuccess,
  createThreadFailure,
} = threads.actions;

export default threads.reducer;

export const fetchThreads =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getThreadsStart());
      const threads = await getThreads(accessToken);
      dispatch(getThreadsSuccess(threads));
    } catch (err: any) {
      dispatch(getThreadsFailure(err.toString()));
    }
  };

export const fetchThreadsByProject =
  (accessToken: String, projectID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getThreadsStart());
      const threads = await getThreadsByProject(accessToken, projectID);
      dispatch(getThreadsSuccess(threads));
    } catch (err: any) {
      dispatch(getThreadsFailure(err.toString()));
    }
  };

export const addThread =
  (
    accessToken: String,
    newThread: Partial<Thread>,
    setReturnRoute?: boolean,
    returnRoute?: string // used to overwrite default route
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadStart());
      const thread = await createThread(accessToken, newThread);
      dispatch(createThreadSuccess(thread));
      if (setReturnRoute) {
        dispatch(
          push(
            returnRoute ||
              `/engagement/communicationthreads/${thread?.ThreadID}`
          )
        );
      }
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createThreadFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updThread =
  (
    accessToken: String,
    threadID: number,
    newThread: Partial<Thread>,
    setReturnRoute?: boolean,
    interactionID?: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadStart());
      const thread = await updateThread(accessToken, threadID, newThread);
      if (interactionID) {
        await dispatch(
          fetchThreadInteractionsByInteraction(accessToken, interactionID)
        );
      }
      dispatch(updateThreadSuccess(thread));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push(`/engagement/communicationthreads/${threadID}`));
      }
    } catch (err: any) {
      dispatch(createThreadFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delThread =
  (accessToken: String, threadID: number, setReturnRoute?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createThreadStart());
      const result = await deleteThread(accessToken, threadID);
      dispatch(deleteThreadSuccess(threadID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/engagement/communicationthreads"));
      }
    } catch (err: any) {
      dispatch(createThreadFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
