import React, { useState } from "react";
import { RootState } from "app/rootReducer";
import { IconButton, Tooltip, Toolbar } from "@mui/material";
import { School, Settings } from "@mui/icons-material";
import LogoutButton from "features/auth/LogoutButton";
import { push } from "redux-first-history";
import { useDispatch, useSelector } from "react-redux";
import IppNotificationButton from "components/IppNotificationButton";
import Feedback from "features/feedback/Feedback";
//import { setShowMenu } from "./header";
import { useTranslation } from "react-i18next";
import { AdminSidebar } from "features/platform/admin/AdminSidebar";
import PasswordChangeButton from "features/auth/PasswordChangeButton";

interface headerToolbarProps {
  displayMenu?: any;
  menu?: boolean;
}

function HeaderToolbar(props: headerToolbarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation(["strGen"]);

  const { displayMenu, menu } = props;
  const [adminDrawerOpen, setAdminDrawerOpen] = useState(false);

  const { currentProfile, isLoading, error } = useSelector(
    (state: RootState) => state.profile
  );

  const {
    clientId,
    customTrainingUrl,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = () => {
    displayMenu.displayMenu();
    //displayMenu();
  };

  const handleClose = (dest: string) => {
    setAnchorEl(null);
    const route: string = "/" + dest;
    dispatch(push(route));
  };

  const handleAdminClick = () => {
    setAdminDrawerOpen(!adminDrawerOpen);
  };

  const trainingURL =
    customTrainingUrl != ""
      ? customTrainingUrl
      : "https://www.netbenefitsoftware.com/contractor-training-hub";

  return (
    <div>
      <Toolbar>
        {/* <Tooltip title="View Messages">
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <Mail />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Alerts">
          <IconButton color="inherit">
            <Badge badgeContent={2} color="secondary">
              <Alarm />
            </Badge>
          </IconButton>
        </Tooltip> */}
        {/* <Tooltip title="Toggle Menu">
          <IconButton color="inherit" onClick={handleToggle}>
            <ArrowCircleDownIcon />
          </IconButton>
        </Tooltip> */}

        <IppNotificationButton />
        {!currentProfile.IsClient && (
          <Tooltip title={t("objPlt:platformwide.traininghub")}>
            <IconButton color="inherit" href={trainingURL} target="_blank">
              <School />
            </IconButton>
          </Tooltip>
        )}
        <Feedback />
        {/* {currentProfile.IsClientAdmin ? <HeaderGear /> : <div />} */}
        {currentProfile.IsClientAdmin ? (
          <Tooltip title={t("strGen:header.icons.admin.tooltip")}>
            <IconButton
              color="inherit"
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={handleAdminClick}
              //onClick={handleClick}
            >
              {adminDrawerOpen && (
                <AdminSidebar
                  adminDrawerOpen={adminDrawerOpen}
                  setAdminDrawerOpen={setAdminDrawerOpen}
                />
              )}
              <Settings />
            </IconButton>
          </Tooltip>
        ) : (
          <div />
        )}
        <PasswordChangeButton />
        <LogoutButton />
      </Toolbar>
    </div>
  );
}

export default HeaderToolbar;
