import { Grid } from "@mui/material";
import { IppDisplayCheckbox } from "./IppDisplayCheckbox";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useTypedTranslation } from "utils/customHooks";

interface IppDiversityDisplayProps {
  parentTitle: string;
  isEditing: boolean;
  setIsEditing: any;
  showEdit?: boolean;
  womanOwned?: boolean;
  veteranOwned?: boolean;
  disability?: boolean;
  visibleMinority?: boolean;
  local?: boolean;
  lgbtq?: boolean;
  checkboxesDisabled?: boolean;
}

export const IppDiversityDisplay = (props: IppDiversityDisplayProps) => {
  const {
    parentTitle,
    isEditing,
    setIsEditing,
    showEdit,
    womanOwned,
    veteranOwned,
    disability,
    visibleMinority,
    local,
    lgbtq,
    checkboxesDisabled,
  } = props;
  const t = useTypedTranslation(["objBen", "strGen"]);

  const {
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_Disability,
    dm_VisibleMinority,
    dm_LGBTQ,
    dm_Local,
  } = useSelector((state: RootState) => state.client);

  return (
    <Grid container spacing={0}>
      {dm_WomanOwned && womanOwned !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={t("objBen:objects.diversityindicators.fields.womanowned")}
            isChecked={womanOwned}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
      {dm_VeteranOwned && veteranOwned !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={t("objBen:objects.diversityindicators.fields.veteranowned")}
            isChecked={veteranOwned}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
      {dm_Disability && disability !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={
              parentTitle === "Employee"
                ? t("objBen:objects.diversityindicators.fields.disability")
                : t("objBen:objects.diversityindicators.fields.disabilityowned")
            }
            isChecked={disability}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
      {dm_VisibleMinority && visibleMinority !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={
              parentTitle === "Employee"
                ? t("objBen:objects.diversityindicators.fields.visibleminority")
                : t(
                    "objBen:objects.diversityindicators.fields.visibleminorityowned"
                  )
            }
            isChecked={visibleMinority}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
      {dm_LGBTQ && lgbtq !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={
              parentTitle === "Employee"
                ? t("objBen:objects.diversityindicators.fields.lgbtq")
                : t("objBen:objects.diversityindicators.fields.lgbtqowned")
            }
            isChecked={lgbtq}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
      {dm_Local && local !== undefined && (
        <Grid item xs={6}>
          <IppDisplayCheckbox
            label={t("objBen:objects.diversityindicators.fields.local")}
            isChecked={local}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
            disabled={checkboxesDisabled}
          />
        </Grid>
      )}
    </Grid>
  );
};

IppDiversityDisplay.defaultProps = {
  showEdit: true,
};
