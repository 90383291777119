import axiosClient from "./axiosClient";

export interface ClientCommitmentStatusType {
  ClientCommitmentStatusTypeID: number;
  ClientID: number;
  CommitmentStatusTypeID: number;
  CommitmentStatusTypeName: string;
  IsRequired: boolean;
}

export interface ClientCommitmentStatusTypesResult {
  clientCommitmentStatusTypes: ClientCommitmentStatusType[];
}

export async function getClientCommitmentStatusTypes(
  accessToken: String
): Promise<ClientCommitmentStatusTypesResult> {
  const url = `/clientCommitmentStatusType`;

  try {
    //console.log("API START")
    const { data } = await axiosClient.get<ClientCommitmentStatusTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //console.log("API END")

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createClientCommitmentStatusType(
  accessToken: String,
  newClientCommitmentStatusType: Partial<ClientCommitmentStatusType>
): Promise<ClientCommitmentStatusType> {
  const url = `/ClientCommitmentStatusType`;

  try {
    const { data } = await axiosClient.post<ClientCommitmentStatusType>(
      url,
      newClientCommitmentStatusType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateClientCommitmentStatusType(
  accessToken: String,
  ClientCommitmentStatusTypeID: number,
  newClientCommitmentStatusType: Partial<ClientCommitmentStatusType>
): Promise<ClientCommitmentStatusType> {
  const url = `/ClientCommitmentStatusType/` + ClientCommitmentStatusTypeID;

  try {
    //console.log("UPDATE START")
    const { data } = await axiosClient.post<ClientCommitmentStatusType>(
      url,
      newClientCommitmentStatusType,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteClientCommitmentStatusType(
  accessToken: String,
  ClientCommitmentStatusTypeID: number
): Promise<string> {
  const url = `/ClientCommitmentStatusType/` + ClientCommitmentStatusTypeID;
  try {
    //console.log("DEL START")
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
