import axiosClient from "./axiosClient";

export interface Category {
  CategoryID: number;
  CategoryName: string;
  CategoryDescription: string;
}

export interface CategoriesResult {
  categories: Category[];
}

export async function getCategories(
  accessToken: String
): Promise<CategoriesResult> {
  const url = `/category`;

  try {
    const { data } = await axiosClient.get<CategoriesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    //console.log("CATEGORY", data)
    return data;
  } catch (err: any) {
    throw err;
  }
}
