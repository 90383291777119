import axiosClient from "./axiosClient";

export interface WorkHourDetail {
  WorkHourDetailID: number;
  PeriodID: number;
  EmployeeID: number;
  RegularHours: number;
  AltHours2: number;
  AltHours3: number;
  CompanyEmployeeID?: string;
  ProjectComponentName?: string;
  ProjectName?: string;
  StartDate?: Date;
  EndDate?: Date;
}

export interface WorkHourDetailsResult {
  workHourDetails: WorkHourDetail[];
}

export async function getWorkHourDetails(
  accessToken: String
): Promise<WorkHourDetailsResult> {
  const url = `/workHourDetail`;

  try {
    const { data } = await axiosClient.get<WorkHourDetailsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getWorkHourDetailsByEmployee(
  accessToken: String,
  employeeID: number
): Promise<WorkHourDetailsResult> {
  const url = `/workHourDetail/employee/${employeeID}`;

  try {
    const { data } = await axiosClient.get<WorkHourDetailsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getWorkHourDetailsByPeriod(
  accessToken: String,
  periodID: number
): Promise<WorkHourDetailsResult> {
  const url = `/workHourDetail/${periodID}`;

  try {
    const { data } = await axiosClient.get<WorkHourDetailsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createWorkHourDetail(
  accessToken: String,
  newWorkHourDetail: Partial<WorkHourDetail>
): Promise<WorkHourDetail> {
  const url = `/workHourDetail`;

  try {
    const { data } = await axiosClient.post<WorkHourDetail>(
      url,
      newWorkHourDetail,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateWorkHourDetail(
  accessToken: String,
  workHourDetailID: number,
  newWorkHourDetail: Partial<WorkHourDetail>
): Promise<WorkHourDetail> {
  const url = `/workHourDetail/` + workHourDetailID;

  try {
    const { data } = await axiosClient.post<WorkHourDetail>(
      url,
      newWorkHourDetail,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteWorkHourDetail(
  accessToken: String,
  workHourDetailID: number
): Promise<string> {
  const url = `/workHourDetail/` + workHourDetailID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function bulkDeleteWorkHourDetailByPeriod(
  accessToken: String,
  workHourPeriodID: number
): Promise<string> {
  const url = `/workHourDetail/bulkDelete/${workHourPeriodID}`;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
