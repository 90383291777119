import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { ColumnMenu, CommaDelimitedCheckboxFilter } from "./columnMenu";
import { ChangeEvent, useEffect, useState } from "react";
import { orderBy, SortDescriptor, filterBy } from "@progress/kendo-data-query";
import { Check, CheckCircle, Clear, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Popover,
  Checkbox,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Info from "@mui/icons-material/Info";
import {
  Grid as KendoGrid,
  GridColumn,
  GridToolbar,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  addCompanyProject,
  delCompanyProject,
  fetchCompanyProjectsByCompany,
  fetchCompanyProjectsByProject,
  updCompanyProject,
} from "features/notifications/companyProjectSlice";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppCheckbox } from "components/IppCheckbox";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { CustomLinkCell } from "./customCells";
import { fetchAllUserRoles } from "features/roles/UserRoleSlice";
import { UserRole } from "api/userRoleAPI";
import { useTypedTranslation } from "utils/customHooks";
import { IppButton } from "components/Buttons/IppButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const PREFIX = "IppCompanyProjectInventory";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export type filterNames = "boolean" | "numeric" | "date" | "text";

export interface column {
  field: string;
  title: string;
  format?: any;
  filter?: string;
}

interface myProps {
  title: string;
  fetchID: number; // will be projectID or companyID depending on which page
  showField: string;
  showFieldDescriptor: string;
  companyName?: string;
  projectName?: string;
  disabled?: boolean;
}

export const IppCompanyProjectInventory = (props: myProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { location } = useSelector((state: RootState) => state.router);

  // get correct location for link

  //IppDebug(location);

  const [editID, setEditID] = useState(1);
  const {
    title,
    fetchID,
    showField,
    showFieldDescriptor,
    companyName,
    projectName,
    disabled,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [showNoActiveUsersWarning, setShowNoActiveUsersWarning] =
    useState(false);

  const [editItem, setEditItem] = useState({
    CompanyProjectID: 0,
    CompanyID: -1,
    ProjectID: -1,
    ActiveForPayment: false,
    ActiveForEmployee: false,
    ActiveUsers: -1,
  });

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [sort, setSort] = useState([
    {
      field: showField,
      dir: "asc",
    } as SortDescriptor,
  ]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { companyProjectList, companyProjectsById, isLoading, error } =
    useSelector((state: RootState) => state.companyProjects);

  const companyProjects = companyProjectList.map(
    (id) => companyProjectsById[id]
  );

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
    error: companyError,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((id) => companiesById[id]);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((id) => projectsById[id]);

  const {
    userRoleList,
    userRolesById,
    isLoading: userRoleIsLoading,
  } = useSelector((state: RootState) => state.userRoles);

  const userRoles = userRoleList.map((id) => userRolesById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (showField === "ProjectName") {
          dispatch(fetchCompanyProjectsByCompany(accessToken, fetchID));
          dispatch(fetchAllUserRoles(accessToken, "company", fetchID));
        } else {
          dispatch(fetchCompanyProjectsByProject(accessToken, fetchID));
          dispatch(fetchCompanies(accessToken, clientId));
          dispatch(fetchAllUserRoles(accessToken, "project", fetchID));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [fetchID, clientId, showField, dispatch, getAccessTokenSilently]);

  // check when detailed data is updated
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (updated) {
          if (showField === "ProjectName") {
            dispatch(fetchCompanyProjectsByCompany(accessToken, fetchID));
            dispatch(fetchAllUserRoles(accessToken, "company", fetchID));
          } else {
            dispatch(fetchCompanyProjectsByProject(accessToken, fetchID));
            dispatch(fetchAllUserRoles(accessToken, "project", fetchID));
          }
          setUpdated(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [fetchID, updated, showField, dispatch, getAccessTokenSilently]);

  const validationSchema = yup.object({
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    ActiveForPayment: yup.boolean().nullable(),
    ActiveForEmployee: yup.boolean().nullable(),
  });

  const onSub = (values: any) => {
    // add case
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addCompanyProject(accessToken, values, fetchID, showField));
        setEditOpen(false);
        setUpdated(true);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    // function to delete current project entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCompanyProject(accessToken, editItem.CompanyProjectID));
        setUpdated(true);
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (editItem.CompanyProjectID > 0) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updCompanyProject(accessToken, editItem.CompanyProjectID, values)
          );
          setEditOpen(false);
          setUpdated(true);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  const handleAdd = () => {
    setEditItem({
      CompanyProjectID: -1,
      CompanyID: showField === "ProjectName" ? fetchID : -1,
      ProjectID: showField === "CompanyName" ? fetchID : -1,
      ActiveForPayment: false,
      ActiveForEmployee: false,
      ActiveUsers: -1,
    });
    setIsAdding(true);
    setEditOpen(true);
  };

  const [filter, setFilter] = useState<any>();
  const filteredData = orderBy(filterBy(companyProjects, filter), sort);

  const newList = filteredData.map((p) => {
    let newItem = Object.assign({}, p) as any;

    return newItem;
  });

  // removes companies that already are active from list for add new
  const filteredCompanies = companies.filter((item) => {
    let exist = newList.find((list) => list.CompanyID === item.CompanyID);
    return !exist;
  });

  //removes project that already are active from list of add new
  const filteredProjects = projects.filter((item) => {
    let exist = newList.find((list) => list.ProjectID === item.ProjectID);
    return !exist;
  });

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const sortChanged = (event: GridSortChangeEvent) => {
    setSort(event.sort);
  };

  const filterChanged = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
    setSkip(0);
    setTake(10);
  };

  const clearFilter = () => {
    setFilter({ filters: [], logic: "and" });
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditID(0);
  };

  const myCheckBox = (props: any) => (
    <CommaDelimitedCheckboxFilter {...props} data={companyProjects} />
  );

  const clear = () => {
    setFilter({ filters: [], logic: "and" });
  };

  // functions for help button
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Edit this Entry">
          <div style={{ cursor: "pointer", color: "blue" }}>
            <IconButton
              size="small"
              color="secondary"
              onClick={(e) => {
                setEditItem(props.dataItem);

                // setCmp(props.dataItem);
                // setCmpID(props.dataItem.ProjectComponentID);
                // setIsAddingComponent(false);
                setEditOpen(true);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        </Tooltip>
      </td>
    );
  };

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip title="Delete this Entry">
          <div style={{ cursor: "pointer", color: "blue" }}>
            <IconButton
              size="small"
              color={"error"}
              onClick={(e) => {
                setEditItem(props.dataItem);
                // setCmp(props.dataItem);
                // setCmpID(props.dataItem.ProjectComponentID);
                setDeleteOpen(true);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        </Tooltip>
      </td>
    );
  };

  const findActiveUsersForCompanyProject = (
    projectID: number,
    companyID: number,
    userRolesList: UserRole[]
  ) => {
    const BENEFITS_COMPANY_USER_ROLE_ID = 2;

    return userRolesList.find(
      (userRole) =>
        userRole.ProjectID === projectID &&
        userRole.CompanyID === companyID &&
        userRole.ModuleRoleID === BENEFITS_COMPANY_USER_ROLE_ID
    );
  };

  const open = Boolean(anchorEl);

  const customCheckboxCell = (props: any) => {
    if (props.dataItem.CompanyProjectID === editID) {
      return (
        <td>
          <Checkbox
            id={props.field}
            name={props.field}
            value={
              props.field === "ActiveForPayment"
                ? editItem.ActiveForPayment
                : editItem.ActiveForEmployee
            }
            checked={
              props.field === "ActiveForPayment"
                ? editItem.ActiveForPayment
                : editItem.ActiveForEmployee
            }
            onChange={(e) => {
              if (e.target.name === "ActiveForEmployee") {
                setEditItem({
                  CompanyProjectID: editItem.CompanyProjectID,
                  CompanyID: editItem.CompanyID,
                  ProjectID: editItem.ProjectID,
                  ActiveForPayment: editItem.ActiveForPayment,
                  ActiveForEmployee: !editItem.ActiveForEmployee,
                  ActiveUsers: editItem.ActiveUsers,
                });
              } else {
                setEditItem({
                  CompanyProjectID: editItem.CompanyProjectID,
                  CompanyID: editItem.CompanyID,
                  ProjectID: editItem.ProjectID,
                  ActiveForPayment: !editItem.ActiveForPayment,
                  ActiveForEmployee: editItem.ActiveForEmployee,
                  ActiveUsers: editItem.ActiveUsers,
                });
              }
            }}
          />
        </td>
      );
    } else {
      return <td>{props.dataItem[props.field] ? <Check /> : <Clear />}</td>;
    }
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  useEffect(() => {
    // check if there are active users for the selected company and project
    // if no active users, set flag to true to show warning to users

    if (formik.values.CompanyID > 0 && formik.values.ProjectID > 0) {
      const foundUsers = findActiveUsersForCompanyProject(
        formik.values.ProjectID,
        formik.values.CompanyID,
        userRoles
      );

      if (foundUsers) {
        setShowNoActiveUsersWarning(false);
      } else {
        setShowNoActiveUsersWarning(true);
      }
    }
  }, [formik.values.CompanyID, formik.values.ProjectID, userRoles]);

  const activeReporting =
    formik.values.ActiveForEmployee || formik.values.ActiveForPayment;

  let ippView = (
    <div>
      <Box display="flex">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h5" component="h3">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item container xs justifyContent="flex-end">
                <IppButton
                  onClick={handleClick}
                  startIcon={<Info />}
                  variant="text"
                >
                  Guide
                </IppButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KendoGrid
                data={newList.slice(skip, (take ? take : 10) + (skip || 0))}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  // type: "input",
                  pageSizes: [5, 10, 25, 50, 100],
                }}
                skip={skip}
                take={take}
                total={filteredData.length}
                onPageChange={pageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e) => sortChanged(e)}
                filter={filter}
                onFilterChange={(e) => filterChanged(e)}
              >
                <GridToolbar>
                  <IppAddButton onClick={handleAdd} />
                  <IppButton
                    variant="text"
                    onClick={clearFilter}
                    color={"secondary"}
                  >
                    Clear Filter
                  </IppButton>
                </GridToolbar>
                <GridColumn
                  field={showField}
                  title={showFieldDescriptor}
                  cell={(props) => (
                    <CustomLinkCell
                      {...props}
                      path={
                        showField === "CompanyName"
                          ? `/benefits/companies/${props.dataItem["CompanyID"]}`
                          : `/admin/projects/${props.dataItem["ProjectID"]}`
                      }
                    />
                  )}
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  field="ActiveForEmployee"
                  title="Employee Reporting"
                  editor="boolean"
                  filter="boolean"
                  columnMenu={myCheckBox}
                  cell={customCheckboxCell}
                  editable={true}
                />
                <GridColumn
                  field="ActiveForPayment"
                  title="Spend Reporting"
                  editor="boolean"
                  filter="boolean"
                  columnMenu={myCheckBox}
                  cell={customCheckboxCell}
                  editable={true}
                />
                <GridColumn
                  cell={editCell}
                  width="40px"
                  field="PaymentID"
                  title=" "
                />
                <GridColumn
                  cell={deleteCell}
                  width="40px"
                  field="PaymentID"
                  title=" "
                />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
        <Popover
          id="helpButton"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography variant="body2">
            You can add/edit a{" "}
            {showField === "CompanyName" ? "Company" : "Project"} and choose to
            activate/deactivate notifications for Employee Reporting and/or
            Spend Reporting.{" "}
          </Typography>
        </Popover>
      </Box>

      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>
          {isAdding ? "Add " : "Edit "}
          {showField === "CompanyName" ? "Company" : "Project"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {showField === "CompanyName" ? (
                  <IppStaticTextfield
                    id="ProjectName"
                    value={projectName || ""}
                    label="Project"
                  />
                ) : (
                  <IppAutocomplete
                    id="ProjectID"
                    options={filteredProjects}
                    value={projects.find((obj) => {
                      return obj.ProjectID === editItem.ProjectID;
                    })}
                    onChangeFunction={(event: ChangeEvent, newValue: any) => {
                      if (newValue) {
                        formik.setFieldValue("ProjectID", newValue.ProjectID);
                      } else {
                        formik.setFieldValue("ProjectID", -1);
                      }
                    }}
                    label="Project*"
                    isEditing={true}
                    touchedFunction={formik.touched.ProjectID}
                    errorFunction={formik.errors.ProjectID}
                    setIsEditing={null}
                    optionLabelFunction={(option: any) => option.ProjectName}
                    textValueFunction={
                      !projectIsLoading && formik.values.ProjectID > 0
                        ? projectsById[formik.values.ProjectID].ProjectName
                        : ""
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {showField === "ProjectName" ? (
                  <IppStaticTextfield
                    id="CompanyName"
                    value={companyName || ""}
                    label="Company"
                  />
                ) : (
                  <IppAutocomplete
                    id="CompanyID"
                    options={filteredCompanies}
                    value={companies.find((obj) => {
                      return obj.CompanyID === editItem.CompanyID;
                    })}
                    onChangeFunction={(event: ChangeEvent, newValue: any) => {
                      if (newValue) {
                        formik.setFieldValue("CompanyID", newValue.CompanyID);
                      } else {
                        formik.setFieldValue("CompanyID", -1);
                      }
                    }}
                    label="Company*"
                    isEditing={true}
                    touchedFunction={formik.touched.CompanyID}
                    errorFunction={formik.errors.CompanyID}
                    setIsEditing={null}
                    optionLabelFunction={(option: any) => option.CompanyName}
                    textValueFunction={
                      !projectIsLoading && formik.values.CompanyID > 0
                        ? companiesById[formik.values.CompanyID].CompanyName
                        : ""
                    }
                    autoPopulate={false}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <IppCheckbox
                  id="ActiveForEmployee"
                  label="Active for Employee Reporting"
                  value={formik.values.ActiveForEmployee}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  disabled={
                    !(
                      formik.values.CompanyID > 0 && formik.values.ProjectID > 0
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <IppCheckbox
                  id="ActiveForPayment"
                  label="Active for Payment Reporting"
                  value={formik.values.ActiveForPayment}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                  disabled={
                    !(
                      formik.values.CompanyID > 0 && formik.values.ProjectID > 0
                    )
                  }
                />
              </Grid>
              {activeReporting && showNoActiveUsersWarning && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error">
                    The selected Company and Project do not have an appropriate
                    User with permissions to perform these tasks. Please make
                    sure to add a user with the appropriate permissions to the
                    Company if you create a contract with these requirements.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => setEditOpen(false)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>
          Deactivate {showField === "CompanyName" ? "Company" : "Project"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            This will remove the{" "}
            {showField === "CompanyName" ? "Company" : "Project"} and deactivate
            Employee/Spend reporting notifications - click confirm to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IppDeleteButton
            buttonText={t("strGen:buttons.confirm")}
            onClick={handleDelete}
            color="secondary"
            startIcon={<CheckCircle />}
          />
          <IppCancelButton onClick={() => setDeleteOpen(false)} />
        </DialogActions>
      </Dialog>
    </div>
  );

  return <Root id="companyproject-inventory-page">{ippView}</Root>;
};
