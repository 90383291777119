import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConvertDateOffset } from "utils/DateFunctions";
import { fetchAllCustomRnDs } from "./CustomRnDSlice";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";
import { IppInventory } from "components/Inventory/IppInventory";
import { IsExtraLargeScreen } from "utils/mediaQueries";

export const CustomRnDInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    customRnDList,
    customRnDsById,
    isLoading,
    error: expenseError,
  } = useSelector((state: RootState) => state.customRnD);

  const customRnDs = customRnDList?.map((id) => customRnDsById[id]);
  const data = customRnDs?.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.ClaimYear !== null) {
      // convert date to year
      n.ClaimYear = ConvertDateOffset(p.ClaimYear)?.toDateString()?.slice(-4);
    }
    return n;
  });

  const customRnDsColumns = [
    {
      field: "RnDProjectName",
      title: t("objBen:objects.customRnD.fields.rndProject"),
    },
    {
      field: "ProjectName",
      title: "Project",
    },
    {
      field: "ClaimYear",
      title: t("objBen:objects.customRnD.fields.claimYear"),
      filter: "date",
      format: "{0:d}",
      columnWidth: IsExtraLargeScreen() ? 200 : 150,
    },
    {
      field: "Expenditure",
      title: t("objBen:objects.customRnD.fields.expenditure"),
      format: "{0:c0}",
      filter: "numeric",
      columnWidth: IsExtraLargeScreen() ? 180 : 150,
    },
    {
      field: "Classification",
      title: t("objBen:objects.customRnD.fields.classification"),
    },

    {
      field: "Prequalification",
      title: t("objBen:objects.customRnD.fields.prequalification"),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAllCustomRnDs(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (expenseError) {
    return <ErrorPage errMsg={expenseError} />;
  }

  let CustomRnDInvView =
    isLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objBen:objects.customRnD.name")}
          tableData={data}
          idField="CustomRnDID"
          columns={customRnDsColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showTemplate={false}
          sortColumn={0}
          sortReverse={true}
          searchByColumn={"RnDProjectName"}
          searchByPlaceholder={"Search by R&D Project"}
        />
      </div>
    );

  return <div id="CustomRnD-inventory-page">{CustomRnDInvView}</div>;
};
