import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useFormikContext } from "formik";

type TypedTFunction = (key: string, options?: any) => string;

export function useTypedTranslation(namespaces: string[]): TypedTFunction {
  const { t } = useTranslation(namespaces);
  return (key: string, options?: any) => {
    const result = t(key, options);
    return typeof result === "string" ? result : "";
  };
}

export type SnackBarConstants = {
  UPDATE_SUCCESS: string;
  ADD_SUCCESS: string;
  FAILED: string;
  FAILED_MERGE_CONTACT: string;
  FAILED_MERGE_GROUP: string;
  DELETE_SUCCESS: string;
  REMOVE_SUCCESS: string;
  REPORT_REFRESH_SUCCESS: string;
  REFRESH_LIMIT_EXCEEDED: string;
  REPORT_CHANGES_WARNING: string;
  DOWNLOAD_REQUESTED: string;
};

export const useSnackBarConstants = (): SnackBarConstants => {
  const t = useTypedTranslation(["strGen"]);
  return {
    UPDATE_SUCCESS: t("strGen:components.snackbar.updatesuccess"),
    ADD_SUCCESS: t("strGen:components.snackbar.addsuccess"),
    FAILED: t("strGen:components.snackbar.failed"),
    FAILED_MERGE_CONTACT: t("strGen:components.snackbar.failedmergecontact"),
    FAILED_MERGE_GROUP: t("strGen:components.snackbar.failedmergegroup"),
    DELETE_SUCCESS: t("strGen:components.snackbar.deletesuccess"),
    REMOVE_SUCCESS: t("strGen:components.snackbar.removesuccess"),
    REPORT_REFRESH_SUCCESS: t("strGen:components.snackbar.refreshsuccess"),
    REFRESH_LIMIT_EXCEEDED: t(
      "strGen:components.snackbar.refreshlimitexceeded"
    ),
    REPORT_CHANGES_WARNING: t(
      "strGen:components.snackbar.reportchangeswarning"
    ),
    DOWNLOAD_REQUESTED: t("strGen:components.snackbar.downloadrequested"),
  };
};

export const useFormikContextWithSubmit = (onSubmit: () => void) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik && formik.isSubmitting) {
      onSubmit();
    }
  }, [formik, formik?.isSubmitting, onSubmit]);
};
