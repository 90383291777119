import axiosClient from "api/axiosClient";

export interface RnDExpense {
  RnDExpenseID: number;
  InitiativeID: number;
  ExpenseAmount: number;
  Reference: string;
  SequenceID: number;
  Classification: string;
  ExpenseDate: Date;
  CompanyID: number;
  ProjectID: number;
  AssetID: number;
  Applied: boolean;
  AppliedDate: Date;
  SourceOfFunds: string;
  AssetName?: string;
  InitiativeName?: string;
}

export interface RnDExpensesResult {
  RnDExpenses: RnDExpense[];
}

export async function getRnDExpenses(
  accessToken: String
): Promise<RnDExpensesResult> {
  const url = "/rndexpense";

  try {
    const { data } = await axiosClient.get<RnDExpensesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getRnDExpensesByInitiative(
  accessToken: String,
  initiativeID: number
): Promise<RnDExpensesResult> {
  const url = `/rndexpense/initiative/${initiativeID}`;

  try {
    const { data } = await axiosClient.get<RnDExpensesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createRnDExpense(
  accessToken: String,
  newRnDExpense: Partial<RnDExpense>
): Promise<RnDExpense> {
  const url = "/rndexpense";

  try {
    const { data } = await axiosClient.post<RnDExpense>(url, newRnDExpense, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateRnDExpense(
  accessToken: String,
  RnDExpenseID: number,
  newRnDExpense: Partial<RnDExpense>
): Promise<RnDExpense> {
  const url = "/rndexpense/" + RnDExpenseID;
  try {
    const { data } = await axiosClient.post<RnDExpense>(url, newRnDExpense, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteRnDExpense(
  accessToken: String,
  RnDExpenseID: number
): Promise<string> {
  const url = "/rndexpense/" + RnDExpenseID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
