import {
  TextField,
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Paper,
  MenuItem,
  MenuList,
} from "@mui/material";
import { useState, useEffect } from "react";
import { ContactChildForm } from "features/platform/contacts/ContactChildForm";
import { Contact } from "../api/stakeholder/contactAPI";
import { useTypedTranslation } from "utils/customHooks";
import { createCustomAutoCompleteFilter } from "utils/customFilters";
import { AutoCompleteDivider } from "utils/renderFunctions";

interface IppContactAutocompleteProps {
  options: Contact[];
  setSelectedContacts: (contacts: Contact[]) => void;
  selectedContacts: Contact[];
  setCanSave?: (canSave: boolean) => void;
  singleItem?: boolean;
}

export interface Token {
  Value: number;
  Text: string;
}

export const IppContactAutoComplete = (props: IppContactAutocompleteProps) => {
  const {
    options,
    setSelectedContacts,
    selectedContacts,
    setCanSave,
    singleItem,
  } = props;
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const [showContact, setShowContact] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [isTypingNewItem, setIsTypingNewItem] = useState(false);

  useEffect(() => {
    if (isAdded) {
      let newValue = options[options.length - 1];
      if (singleItem) {
        setSelectedContacts([newValue]);
      } else {
        setSelectedContacts([...selectedContacts, newValue]);
      }
    }
  }, [options.length]);

  const closeContact = () => {
    try {
      setIsAdded(true);
      setNewItem("");
      setShowContact(false);
    } catch (e) {
      console.error(e);
    }
  };

  function handleClickAdd() {
    try {
      let sanitizedName = checkDuplicate(newItem);

      setNewItem(sanitizedName?.trim() ?? "");
      setIsTypingNewItem(false);
      setShowContact(true);
    } catch (e) {
      console.error(e);
    }
  }

  function checkDuplicate(value: any) {
    try {
      let splitName = value.split(" ");

      let firstName = splitName[0].toLowerCase();
      let surName = splitName[1]
        ? splitName.slice(1).join(" ").toLowerCase()
        : "";

      let checkDupe = options.filter(
        (contact: Contact) =>
          contact.FirstName?.toLowerCase() === firstName &&
          contact.Surname?.toLowerCase() === surName
      ).length;

      let i = 1;

      if (checkDupe > 0) {
        while (checkDupe > 0) {
          let newString = value;
          newString = newString + `(${i})`;

          let splitName = newString.split(" ");

          let firstName = splitName[0].toLowerCase();
          let surName = splitName[1] ? splitName[1].toLowerCase() : "";

          checkDupe = options.filter(
            (contact: Contact) =>
              contact.FirstName?.toLowerCase() === firstName &&
              contact.Surname?.toLowerCase() === surName
          ).length;

          if (checkDupe === 0) {
            return newString;
          }

          i++;
        }
      } else {
        return value;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleInputChange = (event: any, value: any, reason: any) => {
    if (reason === "input") {
      if (value?.trim() !== "") {
        if (setCanSave) {
          setCanSave(true);
        }
        setNewItem(value);
        setIsTypingNewItem(true);
      } else {
        if (setCanSave) {
          setCanSave(false);
        }
        setNewItem("");
        setIsTypingNewItem(false);
      }
    } else if (reason === "reset") {
    } else {
      if (setCanSave) {
        setCanSave(false);
      }
      setIsTypingNewItem(false);
    }
  };

  const handleChange = (event: any, value: any, reason: any) => {
    const newValue = value[value.length - 1];

    if (reason === "selectOption") {
      if (singleItem) {
        setSelectedContacts([newValue]);
      } else {
        setSelectedContacts([...selectedContacts, newValue]);
      }

      setNewItem("");
      setIsTypingNewItem(false);
    } else if (reason === "removeOption") {
      const removedContact = selectedContacts.find(
        (contact: any) => !value.includes(contact)
      );

      if (removedContact) {
        setSelectedContacts(
          selectedContacts.filter(
            (contact: any) => contact.ContactID !== removedContact.ContactID
          )
        );
      }
    } else if (reason === "createOption") {
      const sanitizedName = checkDuplicate(newValue);

      setNewItem(sanitizedName?.trim() ?? "");

      setIsTypingNewItem(false);
      setShowContact(true);
    }
  };

  // Custom filter logic - normalize input and compare to options
  const customFilter = createCustomAutoCompleteFilter(
    (option: any) => `${option.ContactName} " - " ${option.Email}`
  );

  return (
    <div>
      {isTypingNewItem && (
        <Typography color="primary" variant="caption">
          {t("strGen:components.contactautocomplete.newitem", {
            variable: newItem,
          })}
        </Typography>
      )}
      <Autocomplete
        multiple
        id="ContactList"
        options={options}
        value={selectedContacts}
        freeSolo
        inputValue={newItem}
        getOptionLabel={(option: any) => {
          return option.ContactName + " - " + option.Email;
        }}
        isOptionEqualToValue={(option: any, value: any) => {
          let compare = selectedContacts.filter(
            (contact: Contact) => contact.ContactID === option.ContactID
          );
          if (compare.length > 0) {
            return true;
          } else {
            return false;
          }
        }}
        handleHomeEndKeys={true}
        onChange={handleChange}
        onInputChange={handleInputChange}
        filterOptions={customFilter}
        filterSelectedOptions={true}
        PaperComponent={({ children }) => (
          <Paper>
            {children}
            {isTypingNewItem && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <MenuList>
                  <MenuItem
                    onMouseDown={(event) => {
                      event.preventDefault();
                      handleClickAdd();
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      alignContent={"center"}
                    >
                      {t(
                        "strGen:components.contactautocomplete.contactnewitem",
                        {
                          fieldname: t("objPlt:objects.contact.name"),
                          variable: newItem,
                        }
                      )}
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Box>
            )}
          </Paper>
        )}
        renderOption={(props: any, option: Contact) => {
          const contactName = option.FirstName + " " + option.Surname;
          let text = [
            option.FirstGroupName,
            option.ContactTitle,
            option.Email,
            option.PrimaryPhone,
          ]
            .filter(Boolean)
            .slice(0, 2)
            .join(", ");
          return (
            <>
              <Box {...props}>
                <Typography>
                  {contactName !== " "
                    ? contactName
                    : `* ${t("objPlt:objects.contact.missingname")} *`}
                </Typography>
                <Typography mx={1} variant={"subtitle2"} color="primary">
                  [{text}]
                </Typography>
              </Box>
              <AutoCompleteDivider />
            </>
          );
        }}
        renderTags={(value: readonly Contact[], getTagProps) =>
          value.map((option: Contact, index: number) => {
            const contactName = option.FirstName + " " + option.Surname;
            return (
              <Chip
                variant="outlined"
                label={
                  contactName !== " "
                    ? contactName
                    : `* ${t("objPlt:objects.contact.missingname")} *`
                }
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("objPlt:objects.contact.relatedcontacts", {
              count: singleItem ? 1 : 2,
            })}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
      <>
        <Dialog open={showContact} onClose={closeContact}>
          <DialogContent>
            <div>
              <ContactChildForm
                setAddingItem={closeContact}
                fullName={newItem?.trim()}
                onClose={closeContact}
                isTokenCreate={true}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
};

IppContactAutoComplete.defaultProps = {
  singleItem: false,
};
