import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCommunityInvestments } from "./CommunityInvestmentSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchCommunityInvestmentTypes } from "features/datalists/CommunityInvestmentTypeSlice";
import { fetchIndigenousGroups } from "features/datalists/IndigenousGroupSlice";

export const CommunityInvestmentInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    communityInvestmentList,
    isLoading,
    error: communityInvestmentsError,
    communityInvestmentsById,
  } = useSelector((state: RootState) => state.communityInvestments);

  const communityInvestments = communityInvestmentList.map(
    (id) => communityInvestmentsById[id]
  );

  const {
    communityInvestmentTypesById,
    isLoading: typeIsLoading,
    error: typeError,
  } = useSelector((state: RootState) => state.communityInvestmentTypes);

  const {
    indigenousGroupsById,
    indigenousGroupList,

    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const data = communityInvestments.map((p) => {
    let newItem = Object.assign({}, p) as any;
    newItem.InvestmentDate = new Date(p.InvestmentDate);
    newItem.GroupName =
      !indigenousGroupIsLoading &&
      indigenousGroupsById[newItem.IndigenousGroupID]
        ? indigenousGroupsById[newItem.IndigenousGroupID].IndigenousGroupName
        : "";
    newItem.CommunityInvestmentTypeName = typeIsLoading
      ? ""
      : communityInvestmentTypesById[newItem.CommunityInvestmentTypeID]
          .CommunityInvestmentTypeName;
    return newItem;
  });

  const dataColumns = [
    { field: "OrganizationName", title: "Organization" },
    { field: "TownCity", title: "Community" },
    { field: "GroupName", title: "Affiliation" },
    {
      field: "InvestmentDate",
      title: "Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    { field: "ProjectName", title: "Project" },
    {
      field: "SpendAmount",
      title: "Amount",
      format: "{0:c}",
      filter: "numeric",
      columnWidth: 125,
    },
    { field: "CommunityInvestmentTypeName", title: "Investment Type" },
    // { field: "InvestmentNotes", title: "Notes", filter: "text" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommunityInvestments(accessToken));
        dispatch(fetchCommunityInvestmentTypes(accessToken));
        if (indigenousGroupList.length === 0) {
          dispatch(fetchIndigenousGroups(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (communityInvestmentsError) {
    return <ErrorPage errMsg={communityInvestmentsError} />;
  }

  let communityInvestmentInvView =
    isLoading &&
    typeIsLoading &&
    clientIsLoading &&
    indigenousGroupIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="Community Investment"
          tableData={data}
          idField="CommunityInvestmentID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showUpload={false}
          showTemplate={false}
          sortColumn={3}
          sortReverse={true}
          searchByColumn={"OrganizationName"}
          searchByPlaceholder={"Search for Organization"}
        />
      </div>
    );

  return (
    <div id="communityInvestment-inventory-page">
      {communityInvestmentInvView}
    </div>
  );
};
