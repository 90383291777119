//-------Utility Functions for handling Position Changes-------//

import { Employee } from "api/employeeAPI";

export type PositionData = {
  JobPositionID?: number | null;
  ApprenticeStatus?: string | null;
  ApprenticeYear?: number | null;
  TempEmployee?: boolean | null;
  ValidFrom?: Date | string | null;
};

export const createNewPositionValuesObject = (
  newValue: any,
  currentPositionID: number | null,
  fieldName: string,
  formikValues: PositionData
): PositionData => {
  // Initialize with default values
  let newPositionValues: PositionData = {
    JobPositionID: formikValues.JobPositionID,
    ApprenticeStatus: formikValues.ApprenticeStatus,
    ApprenticeYear: formikValues.ApprenticeYear,
    TempEmployee: formikValues.TempEmployee,
  };

  // Update based on the field name
  switch (fieldName) {
    case "JobPositionID":
      if (newValue?.JobPositionID ?? null !== currentPositionID) {
        newPositionValues = {
          JobPositionID: newValue?.JobPositionID ?? null,
          ApprenticeStatus: null,
          ApprenticeYear: null,
          TempEmployee: formikValues.TempEmployee ?? false,
        };
      }
      break;
    case "ApprenticeStatus":
      newPositionValues = {
        ...newPositionValues,
        ApprenticeStatus: newValue ?? null,
        ApprenticeYear:
          newValue === "Apprentice" ? formikValues.ApprenticeYear : null,
      };
      break;
    case "ApprenticeYear":
      newPositionValues = {
        ...newPositionValues,
        ApprenticeYear: newValue ?? null,
      };
      break;
    case "TempEmployee":
      newPositionValues = {
        ...newPositionValues,
        TempEmployee: newValue ?? false,
      };
      break;
  }

  return newPositionValues;
};

const hasChanged = <T extends string | number | boolean | null | undefined>(
  newValue: T,
  oldValue: T,
  allowNewValueNull: boolean = false
): boolean => {
  if (allowNewValueNull) return !!(oldValue && newValue !== oldValue);
  return !!(
    (newValue || newValue === false) &&
    newValue !== (oldValue ?? null)
  );
};

const isNewValuePresent = <T extends string | number | null | undefined>(
  oldValue: T,
  newValue: T
): boolean => !!(!oldValue && newValue);

export const handlePositionChange = (
  newValues: PositionData,
  employeeData: Employee
): boolean => {
  const hasPositionChanged = hasChanged(
    newValues.JobPositionID,
    employeeData.JobPositionID,
    true
  );

  const hasTempStatusChanged = hasChanged(
    newValues.TempEmployee,
    employeeData.TempEmployee
  );

  if (hasPositionChanged) return true;

  if (employeeData.JobPositionID && hasTempStatusChanged) return true;

  const isFillingMissingData =
    isNewValuePresent(
      employeeData.ApprenticeStatus,
      newValues.ApprenticeStatus?.trim()
    ) ||
    (employeeData.ApprenticeStatus === "Apprentice" &&
      isNewValuePresent(employeeData.ApprenticeYear, newValues.ApprenticeYear));

  if (isFillingMissingData) return false;

  const hasApprenticeStatusChanged = hasChanged(
    newValues.ApprenticeStatus?.trim(),
    employeeData.ApprenticeStatus
  );
  const hasApprenticeYearChanged = hasChanged(
    newValues.ApprenticeYear,
    employeeData.ApprenticeYear
  );

  return hasApprenticeStatusChanged || hasApprenticeYearChanged;
};
