import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";
import * as RegexConstants from "../../../validation/regexConstants";

export const InitiativeValidation = (isClient: boolean) => {
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objBen"]);

  return yup.object({
    InitiativeName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.fields.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.name"),
          count: 250,
        })
      ),
    Category: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.fields.category"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.category"),
          count: 50,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.fields.category"),
        })
      ),
    QualifiedValue: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.fields.qualifiedvalue"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.fields.qualifiedvalue"),
        })
      ),
    StatusID: yup
      .number()
      .when([], {
        is: () => isClient === true,
        then: (schema) => {
          return schema
            .positive(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.initiative.fields.submitstatusid"),
              })
            )
            .required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.initiative.fields.submitstatusid"),
              })
            );
        },
        otherwise: (schema) => schema.nullable(),
      })
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.fields.submitstatusid"),
        })
      ),
    Owner: yup
      .string()
      .trim()
      .when([], {
        is: () => isClient === true,
        then: (schema) => {
          return schema.required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.initiative.fields.owner"),
            })
          );
        },
        otherwise: (schema) => schema.nullable(),
      })
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.owner"),
          count: 100,
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.fields.owner"),
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    AssetID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.asset.name"),
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.asset.name"),
        })
      ),
    Approved: yup
      .boolean()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.fields.approved"),
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    Details: yup
      .string()
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InitiativeReferences: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.initiativereference"),
          count: 50,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    TDI: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.initiativereference"),
          count: 50,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    InitiativeGroup: yup
      .string()
      .trim()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.initiativegroup"),
          count: 100,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    WBS: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.wbs"),
          count: 50,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    PO: yup
      .string()
      .trim()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.initiative.fields.po"),
          count: 50,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Updated: yup
      .boolean()
      .required(t("strGen:validation.requiredgeneric"))
      .typeError(t("strGen:validation.invalidformat")),
  });
};
