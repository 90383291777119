import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { fetchWorkHourCharts } from "./WorkHourChartSlice";

import "hammerjs";
import { IppSkeleton } from "components/IppSkeleton";

export const WorkHourChart = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { workHourChartList, workHourChartsById, error, isLoading } =
    useSelector((state: RootState) => state.workHourCharts);

  const workHourData = workHourChartList.map((id) => workHourChartsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchWorkHourCharts(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const categories = workHourData.map((item) => {
    let newItem = item.MonthName;
    return newItem;
  });

  let workHourChartForm = isLoading ? (
    <IppSkeleton height={300} />
  ) : (
    <div>
      <Chart style={{ height: 300 }}>
        {/* <ChartTitle text="Active Employees by Affiliation" /> */}

        <ChartLegend position="bottom" />

        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: "Employees" }} />
        </ChartValueAxis>

        <ChartSeries>
          <ChartSeriesItem
            data={workHourData}
            type="line"
            style="smooth"
            field="Indigenous"
            name="Indigenous"
            tooltip={{ visible: true }}
          />
          <ChartSeriesItem
            data={workHourData}
            type="line"
            style="smooth"
            field="NonIndigenous"
            name="Non-Indigenous"
            tooltip={{ visible: true }}
          />
        </ChartSeries>
        <ChartLegend position="bottom" />
      </Chart>
    </div>
  );

  return <div id="work-hour-chart-form">{workHourChartForm}</div>;
};
