import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchInteractionActions } from "./InteractionActionSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const InteractionActionInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    interactionActionList,
    interactionActionsById,
    isLoading: interactionActionIsLoading,
    error: interactionActionError,
  } = useSelector((state: RootState) => state.interactionActions);

  const interactionActions = interactionActionList.map(
    (id) => interactionActionsById[id]
  );

  const data = interactionActions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.RequiredDate !== null) {
      n.RequiredDate = ConvertDateOffset(p.RequiredDate);
    } else {
      n.RequiredDate = null;
    }

    if (p.CompletionDate !== null) {
      n.CompletionDate = ConvertDateOffset(p.CompletionDate);
    } else {
      n.CompletionDate = null;
    }

    if (p.IsOverdue) {
      n.StatusTypeName = t(
        "objStk:objects.interactionaction.statustype.overdue"
      );
    }
    return n;
  });

  const dataColumns = [
    {
      field: "ActionDescription",
      title: t("objStk:objects.interactionaction.name"),
    },
    {
      field: "ActionTypeName",
      title: t("objStk:objects.interactionaction.fields.type"),
      columnWidth: 110,
    },
    {
      field: "RequiredDate",
      title: t("objStk:objects.interactionaction.fields.daterequired"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "CompletionDate",
      title: t("objStk:objects.interactionaction.fields.datecompleted"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interactionaction.fields.relatedcommunication"),
      commaDelimited: true,
    },
    {
      field: "ProjectName",
      title: t("objStk:objects.interaction.fields.project"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.interactionaction.fields.personresponsible"),
      columnWidth: 175,
    },
    {
      field: "ActionStatusName",
      title: t("objStk:objects.interactionaction.fields.status"),
      chips: true,
      columnWidth: 150,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchInteractionActions(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (interactionActionError) {
    return <ErrorPage errMsg={interactionActionError} />;
  }

  let interactionActionInvView = interactionActionIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objStk:objects.interactionaction.namefull_plural")}
        tableData={data}
        idField="InteractionActionID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        showAdd={false}
        showUpload={false}
        showTemplate={false}
        sortColumn={1}
        // sortReverse={true}
        // linkColumn={1}
        searchByColumn={"ActionDescription"}
        searchByPlaceholder={t("strGen:filters.searchobj", {
          objectname: t("objStk:objects.interactionaction.name", { count: 2 }),
        })}
      />
    </div>
  );

  return (
    <div id="interactionAction-inventory-page">{interactionActionInvView}</div>
  );
};
