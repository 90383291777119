import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  UserRole,
  UserRolesResult,
  getAllUserRoles,
  getUserRolesByUser,
  createUserRole,
  updateUserRole,
  deleteUserRole,
} from "api/userRoleAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";

interface UserRoleState {
  userRolesById: Record<number, UserRole>;
  userRoleList: number[];
  isLoading: boolean;
  error: string | null;
}

const UserRoleInitialState: UserRoleState = {
  userRolesById: {},
  userRoleList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: UserRoleState) {
  state.isLoading = true;
}

function loadingFailed(state: UserRoleState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const userRoles = createSlice({
  name: "userroles",
  initialState: UserRoleInitialState,
  reducers: {
    getUserRolesStart: startLoading,
    getUserRolesSuccess(state, { payload }: PayloadAction<UserRolesResult>) {
      const { userRoles } = payload;

      state.isLoading = false;
      state.error = null;

      userRoles.forEach((userRole) => {
        state.userRolesById[userRole.UserRoleID] = userRole;
      });

      state.userRoleList = userRoles.map((userRole) => userRole.UserRoleID);
    },
    getUserRolesFailure: loadingFailed,
    createUserRoleStart: startLoading,
    createUserRoleSuccess(state, { payload }: PayloadAction<UserRole>) {
      const { UserRoleID } = payload;
      state.userRolesById[UserRoleID] = payload;
      state.userRoleList.push(UserRoleID);

      state.isLoading = false;
      state.error = null;
    },
    updateUserRoleSuccess(state, { payload }: PayloadAction<UserRole>) {
      const { UserRoleID } = payload;
      state.userRolesById[UserRoleID] = payload;
      state.userRoleList.push(UserRoleID);

      state.isLoading = false;
      state.error = null;
    },
    deleteUserRoleSuccess(state, { payload }: PayloadAction<number>) {
      const UserRoleID = payload;
      delete state.userRolesById[UserRoleID];
      state.userRoleList = state.userRoleList.filter(
        (item) => item !== UserRoleID
      );

      state.isLoading = false;
      state.error = null;
    },
    createUserRoleFailure: loadingFailed,
  },
});

export const {
  getUserRolesStart,
  getUserRolesSuccess,
  getUserRolesFailure,
  createUserRoleStart,
  createUserRoleSuccess,
  updateUserRoleSuccess,
  deleteUserRoleSuccess,
  createUserRoleFailure,
} = userRoles.actions;

export default userRoles.reducer;

export const fetchUserRoles =
  (accessToken: String, userID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUserRolesStart());
      const userRoles = await getUserRolesByUser(accessToken, userID);

      dispatch(getUserRolesSuccess(userRoles));
    } catch (err: any) {
      dispatch(getUserRolesFailure(err.toString()));
    }
  };

export const fetchAllUserRoles =
  (accessToken: String, filterBy?: string, filterID?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUserRolesStart());
      const userRoles = await getAllUserRoles(accessToken, filterBy, filterID);

      dispatch(getUserRolesSuccess(userRoles));
    } catch (err: any) {
      dispatch(getUserRolesFailure(err.toString()));
    }
  };

export const addUserRole =
  (
    accessToken: String,
    newUserRole: Partial<UserRole>,
    isBulk?: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserRoleStart());
      const user = await createUserRole(accessToken, newUserRole, isBulk);

      dispatch(createUserRoleSuccess(user));
      if (isBulk && newUserRole.UserAccountID)
        dispatch(fetchUserRoles(accessToken, newUserRole.UserAccountID));

      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      //dispatch(push("/admin/users/:userID"));
    } catch (err: any) {
      dispatch(createUserRoleFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updUserRole =
  (
    accessToken: String,
    userRoleID: number,
    newUserRole: Partial<UserRole>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserRoleStart());
      const user = await updateUserRole(accessToken, userRoleID, newUserRole);
      dispatch(updateUserRoleSuccess(user));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`/admin/users/${newUserRole.UserAccountID}`));
    } catch (err: any) {
      dispatch(createUserRoleFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delUserRole =
  (accessToken: String, userRoleID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserRoleStart());
      const result = await deleteUserRole(accessToken, userRoleID);
      dispatch(deleteUserRoleSuccess(userRoleID));
    } catch (err: any) {
      dispatch(createUserRoleFailure(err.toString()));
    }
  };
