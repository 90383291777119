import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  VendorThirdPartySpend,
  VendorThirdPartySpendResult,
  getVendorThirdPartySpend,
} from "api/benefits/vendorInsights/vendorThirdPartySpendAPI";
import { AppThunk } from "app/store";

interface VendorThirdPartySpendState {
  vendorThirdPartySpendsById: Record<number, VendorThirdPartySpend>;
  vendorThirdPartySpendList: number[];
  isLoading: boolean;
  error: string | null;
}

const VendorThirdPartySpendInitialState: VendorThirdPartySpendState = {
  vendorThirdPartySpendsById: {},
  vendorThirdPartySpendList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: VendorThirdPartySpendState) {
  state.isLoading = true;
}

function loadingFailed(
  state: VendorThirdPartySpendState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const vendorThirdPartySpends = createSlice({
  name: "vendorThirdPartySpend",
  initialState: VendorThirdPartySpendInitialState,
  reducers: {
    getVendorThirdPartySpendsStart: startLoading,
    getVendorThirdPartySpendsSuccess(
      state,
      { payload }: PayloadAction<VendorThirdPartySpendResult>
    ) {
      const { vendorThirdPartySpend } = payload;
      state.isLoading = false;
      state.error = null;

      vendorThirdPartySpend.forEach((vendorThirdPartySpend) => {
        state.vendorThirdPartySpendsById[vendorThirdPartySpend.CompanyID] =
          vendorThirdPartySpend;
      });

      state.vendorThirdPartySpendList = vendorThirdPartySpend.map(
        (vendorThirdPartySpend) => vendorThirdPartySpend.CompanyID
      );
    },
    getVendorThirdPartySpendsFailure: loadingFailed,
  },
});

export const {
  getVendorThirdPartySpendsStart,
  getVendorThirdPartySpendsSuccess,
  getVendorThirdPartySpendsFailure,
} = vendorThirdPartySpends.actions;

export default vendorThirdPartySpends.reducer;

export const fetchVendorThirdPartySpends =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorThirdPartySpendsStart());
      const vendorThirdPartySpends = await getVendorThirdPartySpend(
        accessToken,
        startDate,
        endDate,
        regionID
      );
      dispatch(getVendorThirdPartySpendsSuccess(vendorThirdPartySpends));
    } catch (err: any) {
      dispatch(getVendorThirdPartySpendsFailure(err.toString()));
    }
  };
