import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchInteractionComments } from "./InteractionCommentSlice";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

export const InteractionCommentInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    interactionCommentList,
    interactionCommentsById,
    isLoading: interactionCommentIsLoading,
    error: interactionCommentError,
  } = useSelector((state: RootState) => state.interactionComments);

  const interactionComments = interactionCommentList.map(
    (id) => interactionCommentsById[id]
  );

  const data = interactionComments.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.InteractionDate !== null) {
      n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    } else {
      n.InteractionDate = null;
    }
    //n.CommentIDDetails = `CC-${p.InteractionCommentID}  ${p.CommentDetails}`;
    n.InteractionIDTitle = "C-" + p.CommunicationID + "  " + p.InteractionTitle;

    return n;
  });

  const dataColumns = [
    {
      field: "SequenceID",
      title: t("objStk:objects.interactioncomment.sequenceid"),
      format: "CC-{0:0}",
      filter: "numeric",
      columnWidth: 100,
    },
    {
      field: "InteractionIDTitle",
      title: t("objStk:objects.interaction.name"),
    },
    {
      field: "CommentDetails",
      title: t("objStk:objects.interactioncomment.name"),
      className: "impact-nowrap",
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "SentimentLevel",
      title: t("objStk:objects.interactioncomment.fields.sentiment"),
      icons: true,
      columnWidth: 100,
    },
    {
      field: "ContactName",
      title: t("objStk:objects.interactioncomment.fields.contact", {
        count: 2,
      }),
    },
    {
      field: "GroupName",
      title: t("objStk:objects.interactioncomment.fields.group", {
        count: 2,
      }),
    },
    {
      field: "Issues",
      title: t("objStk:objects.interactioncomment.fields.issues"),
      commaDelimited: true,
    },
    {
      field: "Response",
      title: t("objStk:objects.interactioncomment.fields.response"),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchInteractionComments(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (interactionCommentError) {
    return <ErrorPage errMsg={interactionCommentError} />;
  }

  let interactionCommentInvView = interactionCommentIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objStk:objects.interactioncomment.name", { count: 2 })}
        tableData={data}
        idField="InteractionID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        showAdd={false}
        showUpload={false}
        showTemplate={false}
        sortColumn={0}
        sortReverse={true}
        linkColumn={0}
        searchByColumn={"CommentDetails"}
        searchByPlaceholder={t("strGen:filters.searchobj", {
          objectname: t("objStk:objects.interaction.fields.title", {
            count: 2,
          }),
        })}
      />
    </div>
  );

  return (
    <div id="interactionComment-inventory-page">
      {interactionCommentInvView}
    </div>
  );
};
