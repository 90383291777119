import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchStakeholderGroups } from "./StakeholderGroupSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchOrganizationTypes } from "features/datalists/OrganizationTypeSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { getCurrentModule } from "utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";

export const StakeholderGroupInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "objStk", "strGen"]);

  const currentModule = getCurrentModule();
  console.log(currentModule);

  const {
    isLoading: clientIsLoading,
    ft_eng_Importance,
    ft_eng_lvlOfEngagement,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile, isLoading: profileLoading } = useSelector(
    (state: RootState) => state.profile
  );

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
    error: groupError,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const data = stakeholdergroups.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.LatestInteraction !== null) {
      n.LatestInteraction = ConvertDateOffset(p.LatestInteraction);
    } else {
      n.LatestInteraction = null;
    }

    return n;
  });

  const feature = ft_eng_lvlOfEngagement
    ? [
        {
          field: "LevelOfInfluence",
          title: t("objPlt:objects.group.fields.levelofinfluence"),
          filter: "rating",
        },
        {
          field: "LevelOfInterest",
          title: t("objPlt:objects.group.fields.levelofinterest"),
          filter: "rating",
        },
      ]
    : ft_eng_Importance
    ? [
        {
          field: "LevelOfImportanceName",
          title: t("objPlt:objects.group.fields.importance"),
        },
      ]
    : [];

  const dataColumns = [
    { field: "GroupName", title: t("objPlt:objects.group.fields.name") },
    {
      field: "Contacts",
      title: t("objPlt:objects.group.fields.contact", { count: 2 }),
      commaDelimited: true,
    },
    {
      field: "OrganizationTypeName",
      title: t("objPlt:objects.group.fields.organizationtype"),
    },
    {
      field: "WebsiteAddress",
      title: t("objPlt:objects.group.fields.website"),
      externalLink: true,
    },
    ...feature,
    ...(currentModule === "engagement"
      ? [
          {
            field: "LatestInteraction",
            title: t("objPlt:objects.group.latestinteraction"),
            filter: "date",
            format: "{0:d}",
          },
        ]
      : []),
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchStakeholderGroups(accessToken));
        dispatch(fetchOrganizationTypes(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (groupError) {
    return <ErrorPage errMsg={groupError} />;
  }

  let stakeholderGroupInvView =
    groupIsLoading || clientIsLoading || profileLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.group.name", { count: 2 })}
          tableData={data}
          idField="GroupID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showUpload={currentProfile.IsClientAdmin ? true : false}
          showTemplate={currentProfile.IsClientAdmin ? true : false}
          searchByColumn={"GroupName"}
          searchByPlaceholder={t("strGen:filters.searchby", {
            fieldname: t("objPlt:objects.group.fields.groupname"),
          })}
        />
      </div>
    );

  return (
    <div id="stakeholderGroup-inventory-page">{stakeholderGroupInvView}</div>
  );
};
