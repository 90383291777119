import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { useTypedTranslation } from "utils/customHooks";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { IppTextField } from "components/IppTextField";
import { addEnTExpense, updEnTExpense, delEnTExpense } from "./EnTExpenseSlice";
import { IppDatePicker } from "components/IppDatePicker";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
  GetJSYear,
} from "utils/DateFunctions";
import { IppCurrencyField } from "components/IppCurrencyField";
import { fetchInitiatives } from "../initiatives/InitiativeSlice";
import { EnTExpenseValidation } from "./EnTExpenseValidation";
import { fetchAssets } from "../assets/AssetSlice";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { IppFormDivider } from "components/IppFormDivider";
import { IppRnDClassifications } from "components/IppRnDClassifications";
import { classifications } from "features/datalists/Classification";
import { Asset } from "api/benefits/assetAPI";
import { Initiative } from "api/benefits/initiativeAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { fetchEnTExclusionList } from "../entExclusionList/EnTExclusionListSlice";
import Fuse from "fuse.js";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

const PREFIX = "EnTExpenseForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const EnTExpenseForm = (props: any) => {
  // standard import definitions
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);
  const customBP = IsMedSmall();

  // state variables
  let entExpenseData = props.entExpense || {};

  const [isEditing, setIsEditing] = useState(
    entExpenseData.EnTExpenseID ? false : true
  );

  const [isAdding, setIsAdding] = useState(
    entExpenseData.EnTExpenseID ? false : true
  );

  const [activeTab, setActiveTab] = useState(0);

  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  const showEdit = UserWriteAccess("Benefits");

  const [initiativeData, setInitiativeData] = useState<Initiative | null>(null);

  // use selectors and mappings

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_ben_AllocatedDate,
    ft_ben_Asset,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);
  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );
  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { assetsById, assetList } = useSelector(
    (state: RootState) => state.assets
  );
  const assets = assetList.map((id) => assetsById[id]);

  const { exclusionList, exclusionListById } = useSelector(
    (state: RootState) => state.entExclusionList
  );
  const exclusionListData = exclusionList.map((id) => exclusionListById[id]);

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((id) => companiesById[id]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const {
    initiativeList,
    initiativesById,
    isLoading: initiativeIsLoading,
  } = useSelector((state: RootState) => state.initiatives);
  const initiatives = initiativeList
    .map((id) => initiativesById[id])
    .filter((item) => item.Category !== "Research & Development");

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanies(accessToken, clientId));
        if (ft_ben_Asset) {
          dispatch(fetchAssets(accessToken));
        }
        dispatch(fetchEnTExclusionList(accessToken));
        dispatch(fetchInitiatives(accessToken));
        if (entExpenseData.EnTExpenseID) {
          const count = await countAttachments(
            accessToken,
            "EnTExpenses",
            entExpenseData.EnTExpenseID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, entExpenseData.EnTExpenseID]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && !isAdding) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === entExpenseData.EnTExpenseID &&
              item.RecordType === "EnTExpenses"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addEnTExpense(accessToken, values, false));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    // function to delete current EnTexpense entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delEnTExpense(accessToken, entExpenseData.EnTExpenseID, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updEnTExpense(accessToken, values.EnTExpenseID, values, true)
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    entExpenseData.InitiativeID = null;
    entExpenseData.Date = new Date().toISOString().slice(0, 10);
    entExpenseData.Provider = "";
    entExpenseData.CourseName = "";
    entExpenseData.Classification = null;
    entExpenseData.ExpenseAmount = 0;
    entExpenseData.Location = null;
    entExpenseData.Description = null;
    entExpenseData.CompanyID = currentProfile.CompanyID;
    entExpenseData.AssetID = null;
    entExpenseData.Applied = false;
    entExpenseData.AppliedDate = null;
    entExpenseData.EnTExpenses = [];
    entExpenseData.SourceOfFunds = null;
    entExpenseData.ExpenseType = null;
  }

  const formik = useFormik({
    initialValues: entExpenseData,
    validationSchema: EnTExpenseValidation(isAdding),
    onSubmit: submitFunc,
  });

  useEffect(() => {
    if (!formik.values.Applied) {
      if (formik.values.AppliedDate) formik.setFieldValue("AppliedDate", null);
    }
  }, [formik.values.Applied]);

  useEffect(() => {
    if (formik.values.InitiativeID > 0 && initiativeData) {
      setInitiativeData(initiativesById[formik.values.InitiativeID]);
      // set AssetID, CompanyID and SourceOfFunds based on Initiative Data if initiative value exist and is not null
      if (initiativeData?.AssetID)
        formik.setFieldValue("AssetID", initiativeData.AssetID);
      if (initiativeData?.CompanyID) {
        formik.setFieldValue(
          "CompanyID",
          initiativesById[formik.values.InitiativeID].CompanyID
        );
      }
      if (initiativeData?.Owner)
        formik.setFieldValue(
          "SourceOfFunds",
          initiativesById[formik.values.InitiativeID].Owner
        );

      // show snackbar message that values changed
      isEditing &&
        dispatch(
          openSnackBar(
            "Some values have been updated based on the selected initiative",
            "info"
          )
        );
    } else {
      setInitiativeData(null);
    }
  }, [initiativeData, formik.values.InitiativeID]);

  // Expense Type Options, todo: get from BE in the future if neccessary
  const expenseTypeOptions = [
    { id: 0, value: t("objBen:objects.entExpense.education") },
    { id: 1, value: t("objBen:objects.entExpense.training") },
  ];

  // -------------- Exclusion list related items -------------------------

  /* this function is used to check if the current item is likely a part of the exclusion list. returns true if it is

   */

  // Fuse.js options setup for fuzzy searching
  const options = {
    keys: [
      //"ExclusionAcronym",
      { name: "ExclusionName", weight: 1 },
      //"ExclusionDetail"
    ],
    threshold: 0.1, // lower number is more exact matching
    isCaseSensitive: false,
    includeScore: true,
  };

  // will be used in a future fuse optimization
  const exclusionQuery = {
    ExclusionAcronym: formik.values.CourseName ?? "",
    ExclusionDetail: formik.values.Description ?? "",
    ExclusionName: formik.values.CourseName ?? "",
  };

  // Function to perform search, accepts a string query
  function searchExclusions(query: string) {
    const fuse = new Fuse(exclusionListData, options);
    const results = fuse.search(query);

    // Return only items from the results
    return results.map((result) => ({
      data: result.item,
      score: result.score, // Include score for debugging or further logic
    }));
  }

  const searchResults = searchExclusions(formik.values.CourseName);

  // -------------- Exclusion list related items -------------------------

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container spacing={1} className={classes.editForm}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objBen:objects.entExpense.entexpense")}
                isEditing={isEditing}
                isAdding={isAdding}
                //returnPath="/benefits/entExpenses"
                showReturn={true}
                showReturnToInv={true}
              />
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.coursename")}
                  value={formik.values.CourseName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objBen:objects.entExpense.fields.initiativereference"
                  )}
                  value={
                    initiativesById[formik.values.InitiativeID]
                      ? initiativesById[formik.values.InitiativeID]
                          .InitiativeName
                      : ""
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={formik.values.InitiativeName ? true : false}
                  route={`${window.location.href.substring(
                    0,
                    window.location.href.lastIndexOf("entexpenses")
                  )}initiatives/${formik.values.InitiativeID}`}
                  openInNewTab={false}
                  tooltip="Click to view Initiative"
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.description")}
                  value={formik.values.Description}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.classification")}
                  value={formik.values.Classification}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.expensedate")}
                  value={ConvertToJSDate(formik.values.Date)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  label={t("objBen:objects.entExpense.fields.expenseamount")}
                  value={formik.values.ExpenseAmount}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                  showCurrency
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.provider")}
                  value={formik.values.Provider}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.location")}
                  value={formik.values.Location}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.entExpense.fields.expenseType")}
                  value={formik.values.ExpenseType}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.company.name")}
                  value={
                    companyList.length > 0
                      ? companiesById[formik.values.CompanyID]?.CompanyName
                      : ""
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {currentProfile.IsClient && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.rndExpense.fields.sourceoffunds")}
                    value={formik.values.SourceOfFunds}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              {ft_ben_Asset && (
                <>
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.asset.name")}
                      value={assetsById[formik.values.AssetID]?.AssetName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t("objBen:objects.entExpense.fields.applied")}
                      value={formik.values.Applied ? "Yes" : "No"}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={showEdit}
                    />
                  </Grid>
                  {formik.values.Applied && (
                    <Grid item xs={6}>
                      <IppDisplayField
                        label={t(
                          "objBen:objects.entExpense.fields.applieddate"
                        )}
                        value={
                          ft_ben_AllocatedDate
                            ? formik.values.AppliedDate?.slice(0, 4)
                            : formik.values.AppliedDate?.slice(0, 10)
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={showEdit}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={handleDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm =
    clientIsLoading || initiativeIsLoading || clientCurrencyIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form noValidate onSubmit={formik.handleSubmit}>
        {!formik.isSubmitting && (
          <Prompt
            when={formik.dirty}
            message={t("strGen:prompts.unsavedchanges")}
          />
        )}
        <Grid container className={classes.editForm} spacing={1}>
          <IppFormHeader
            title={t("objBen:objects.entExpense.entexpense")}
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/entExpenses"
          />
          <Grid item xs={6}>
            <IppTextField
              id="CourseName"
              required
              label={t("objBen:objects.entExpense.fields.coursename")}
              value={formik.values.CourseName}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.CourseName}
              touchedExpression={formik.touched.CourseName}
              toolTip={t("objBen:objects.entExpense.tooltips.coursename")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="InitiativeID"
              options={initiatives}
              value={initiatives.find((obj) => {
                return obj.InitiativeID === formik.values.InitiativeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("InitiativeID", newValue.InitiativeID);
                  formik.setFieldValue(
                    "CourseName",
                    initiativesById[newValue.InitiativeID].InitiativeName
                  );
                  setInitiativeData(initiativesById[newValue.InitiativeID]);
                } else {
                  formik.setFieldValue("InitiativeID", null);
                }
              }}
              label={t("objBen:objects.entExpense.fields.initiativereference")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.InitiativeName}
              errorFunction={formik.errors.InitiativeID}
              touchedFunction={formik.touched.InitiativeID}
              textValueFunction={
                formik.values.InitiativeID > 0 &&
                initiativesById[formik.values.InitiativeID]
                  ? initiativesById[formik.values.InitiativeID].InitiativeName
                  : ""
              }
              toolTip={t(
                "objBen:objects.initiative.tooltips.expenseinitiative"
              )}
            />
          </Grid>
          {/* {formik.values.CourseName && searchResults.length > 0 && (
            <>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "20px",
                  padding: "10px",
                  backgroundColor: "#ffcccc",
                  border: "1px solid #ff0000",
                }}
              >
                <Typography variant="h6" style={{ color: "#ff0000" }}>
                  <Warning style={{ color: "#ff0000" }} />
                </Typography>
                <Typography>
                  The course name you are entering may not be valid. Please
                  ensure it is not one of the listed items. You may proceed
                  otherwise.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {searchResults.map((item, index) => (
                  <Grid
                    container
                    key={index}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      marginBottom: "10px",
                      padding: "8px",
                      backgroundColor: "#fff0f0",
                      borderLeft: "5px solid #ff0000",
                    }}
                  >
                    <Typography>
                      Matched Record {index + 1}:{" "}
                      <strong>{item.data.ExclusionName}</strong> -{" "}
                      {item.data.ExclusionAcronym} - {item.data.ExclusionDetail}
                      : {item.score}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )} */}

          {!isAdding && (
            <>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="Classification"
                  options={classifications}
                  label={t("objBen:objects.entExpense.fields.classification")}
                  value={formik.values.Classification}
                  required={true}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("Classification", newValue);
                    } else {
                      formik.setFieldValue("Classification", null);
                    }
                  }}
                  errorFunction={formik.errors.Classification}
                  touchedFunction={formik.touched.Classification}
                  optionLabelFunction={(option: any) => option}
                  toolTip={t(
                    "objBen:objects.rndExpense.tooltips.expenseclassification"
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyField
                  id="ExpenseAmount"
                  label={t("objBen:objects.entExpense.fields.expenseamount")}
                  value={formik.values.ExpenseAmount}
                  isEditing={isEditing}
                  onChangeFunction={(newValue) =>
                    formik.setFieldValue("ExpenseAmount", newValue)
                  }
                  errorsExpression={formik.errors.ExpenseAmount}
                  touchedExpression={formik.touched.ExpenseAmount}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <IppDatePicker
              id="Date"
              label={t("objBen:objects.entExpense.fields.expensedate")}
              required={true}
              value={ConvertDateOffset(formik.values.Date)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue("Date", GetJSDate(newValue), true);
                formik.setFieldTouched("Date", true, false);
              }}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              errorsExpression={formik.errors.Date}
              touchedExpression={formik.touched.Date}
              disableFuture={true}
              monthOnly={false}
              toolTip="The date when the Expense was created or paid for."
            />
          </Grid>

          <Grid item xs={6}>
            <IppTextField
              id="Provider"
              label={t("objBen:objects.entExpense.fields.provider")}
              value={formik.values.Provider}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Provider}
              touchedExpression={formik.touched.Provider}
              toolTip={t("objBen:objects.entExpense.tooltips.expenseprovider")}
            />
          </Grid>

          <Grid item xs={6}>
            <IppTextField
              id="Location"
              label={t("objBen:objects.entExpense.fields.location")}
              value={formik.values.Location}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Location}
              touchedExpression={formik.touched.Location}
              toolTip={t("objBen:objects.entExpense.tooltips.expenselocation")}
            />
          </Grid>
          {/* expense type */}
          <Grid item xs={6}>
            <IppAutocomplete
              id="ExpenseType"
              options={expenseTypeOptions}
              value={expenseTypeOptions.find((obj) => {
                return obj.value === formik.values.ExpenseType;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ExpenseType", newValue.value);
                } else {
                  formik.setFieldValue("ExpenseType", null);
                }
              }}
              label={t("objBen:objects.entExpense.fields.expenseType")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.value}
              errorFunction={formik.errors.ExpenseType}
              touchedFunction={formik.touched.ExpenseType}
              textValueFunction={formik.values.ExpenseType?.value}
            />
          </Grid>

          <Grid item xs={12}>
            <IppTextField
              id="Description"
              label={t("objBen:objects.entExpense.fields.description")}
              value={formik.values.Description}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Description}
              touchedExpression={formik.touched.Description}
            />
          </Grid>

          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              required
              options={confirmedCompanies.sort((a, b) =>
                a.ShowAsActive.localeCompare(b.ShowAsActive)
              )}
              groupBy={(option: any) => option.ShowAsActive}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", null);
                }
              }}
              label={t("objBen:objects.company.name")}
              isEditing={currentProfile.IsClient && isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              placeholder="Paid by"
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
              textValueFunction={
                !companyIsLoading &&
                formik.values.CompanyID > 0 &&
                companiesById[formik.values.CompanyID]
                  ? companiesById[formik.values.CompanyID].CompanyName
                  : ""
              }
            />
          </Grid>
          {currentProfile.IsClient && (
            <Grid item xs={6}>
              <IppTextField
                id="SourceOfFunds"
                label={t("objBen:objects.entExpense.fields.sourceoffunds")}
                value={formik.values.SourceOfFunds}
                onChangeFunction={formik.handleChange}
                //disabled={initiativeData?.Owner ? true : false}
                touchedExpression={formik.touched.SourceOfFunds}
                errorsExpression={formik.errors.SourceOfFunds}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}
          {ft_ben_Asset && (
            <>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="AssetID"
                  options={assets}
                  value={assets.find((obj: Asset) => {
                    return obj.AssetID === formik.values.AssetID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("AssetID", newValue.AssetID);
                    } else {
                      formik.setFieldValue("AssetID", null);
                      formik.setFieldValue("Applied", false);
                    }
                  }}
                  label={t("objBen:objects.asset.name")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) => option.AssetName}
                  errorFunction={formik.errors.AssetID}
                  touchedFunction={formik.touched.AssetID}
                  textValueFunction={formik.values.AssetName}
                  placeholder="What asset was this expense applied to?"
                />
              </Grid>
              <Grid item xs={6}>
                <IppCheckbox
                  id="Applied"
                  label={t("objBen:objects.entExpense.fields.applied")}
                  value={formik.values.Applied}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                  disabled={
                    !(formik.values.CompanyID > 0 && formik.values.AssetID > 0)
                  }
                />
              </Grid>
              {formik.values.Applied && (
                <Grid item xs={6}>
                  <IppDatePicker
                    id="AppliedDate"
                    label={t("objBen:objects.entExpense.fields.applieddate")}
                    required={true}
                    value={ConvertDateOffset(formik.values.AppliedDate)}
                    onChangeFunction={(newValue: any) => {
                      formik.setFieldValue(
                        "AppliedDate",
                        ft_ben_AllocatedDate
                          ? GetJSYear(newValue, 1, 2)
                          : GetJSDate(newValue),
                        true
                      );
                      formik.setFieldTouched("AppliedDate", true, false);
                    }}
                    errorsExpression={formik.errors.AppliedDate}
                    touchedExpression={formik.touched.AppliedDate}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    disableFuture={true}
                    yearOnly={ft_ben_AllocatedDate}
                    disabled={!formik.values.Applied}
                  />
                </Grid>
              )}
            </>
          )}
          {/* dynamic classification */}
          {isAdding && (
            <Grid xs={12} item>
              <IppFormDivider title="Detailed Expense Classifications" />
              <IppRnDClassifications
                id="EnTExpenses"
                //options={RnDClassifications}
                value={formik.values.EnTExpenses}
                onChangeFunction={(item: any) => {
                  formik.setFieldValue("EnTExpenses", item);
                }}
                touchedFunction={formik.touched.EnTExpenses}
                label={t("objBen:objects.entExpense.tooltips.expenselist")}
                required={true}
                isEditing={isAdding}
                setIsEditing={setIsEditing}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              isAdding={isAdding}
              resetFunction={() => formik.resetForm()}
              showDelete={true}
              deleteFunction={handleDelete}
            />
          </Grid>
        </Grid>
      </form>
    );

  let pageForm =
    clientIsLoading || initiativeIsLoading || clientCurrencyIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`Attachments (${attachCount.Count})`}
                      />
                    </Tabs>
                  </Box>

                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppAttachmentInventory
                        recordType="EnTExpenses"
                        itemID={entExpenseData.EnTExpenseID}
                        companyID={0}
                        projectID={0}
                        moduleID={1}
                        //disabled={handleIsBtnDisabled()}
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
