import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { IppCheckbox } from "./IppCheckbox";
import { Edit } from "@mui/icons-material";

type ButtonProps = {
  id: string;
  label: string;
  value: boolean;
  onChangeFunction: (e: React.ChangeEvent<any>) => void;
  error?: any; // To handle Formik error
  touched?: any; // To handle Formik touched
};
interface ippFormDividerProps {
  title: string;
  titleColor?: string;
  isBold?: boolean;
  button?: ButtonProps;
  conditionalText?: string;
  isEditing?: boolean;
  setIsEditing?: any;
  showEdit?: boolean;
}

export const IppFormDivider = (props: ippFormDividerProps) => {
  const {
    title,
    titleColor,
    isBold,
    button,
    conditionalText,
    isEditing,
    setIsEditing,
    showEdit,
  } = props;

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography
          component="h4"
          color={titleColor || "primary"}
          fontWeight={isBold ? "bold" : ""}
        >
          {title}
        </Typography>
        {button && isEditing && (
          <IppCheckbox
            id={button.id}
            label={button.label}
            value={button.value}
            onChangeFunction={button.onChangeFunction}
            isEditing={isEditing}
            error={button.error}
            touched={button.touched}
          />
        )}
        {conditionalText && (
          <Typography
            component="h4"
            sx={{
              color: "#9e9e9e",
              fontWeight: isBold ? "bold" : "",
            }}
          >
            {conditionalText}
          </Typography>
        )}
        {showEdit && (
          <IconButton onClick={editChange} size="small">
            <Edit fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      <Divider />
    </Grid>
  );
};
