import axiosClient from "./axiosClient";

export interface Company {
  CompanyID: number;
  CompanyName: string;
  VendorID: string;
  Address: string;
  TownCity: string;
  ProvinceState: string;
  CountryName: string;
  PostalCode: string;
  CategoryID: number;
  IndigenousGroupID: number;
  IndigenousGroupRaw: string;
  WomanOwned: boolean;
  VeteranOwned: boolean;
  Disability: boolean;
  VisibleMinority: boolean;
  Local: boolean;
  CompanySourceID: number;
  ClientID: number;
  CompanyStructureID: number;
  Indigenous: boolean;
  NativeAmerican: boolean;
  EmployeeCount: number;
  LastPaymentDate: Date;
  ProjectCount: number;
  ListingConfirmed: boolean;
  IsActive: boolean;
  ShowAsActive: any;
  IsClient?: boolean;
  TierID?: number;
  TierName?: string;
  RejectReason?: string;
  CompanyNotes: string;
  Reference?: string;
  RegionID?: number;
  RegionName?: string;
  ActivityLocationID?: number;
  ActivityLocationName?: string;
  LGBTQ: boolean;
  LastHoursDate: Date;
  ActiveUsersCount?: number;
}

export interface CurrentCompany extends Company {
  IsClient: boolean;
}

export interface CompaniesResult {
  companies: Company[];
}

export async function getCurrentCompany(
  accessToken: String,
  clientId: number
): Promise<CurrentCompany> {
  const url = `/company/current`;

  try {
    const { data } = await axiosClient.get<CurrentCompany>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    data.ShowAsActive = data.ShowAsActive == 1 ? "Active" : "Inactive";

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCompanies(
  accessToken: String,
  clientId: number
): Promise<CompaniesResult> {
  const url = `/company`;

  try {
    const { data } = await axiosClient.get<CompaniesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    data.companies.forEach((item) => {
      item.ShowAsActive = item.ShowAsActive == 1 ? "Active" : "Inactive";
    });

    //console.log("COMPANY", data)
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCompaniesForReview(
  accessToken: String,
  clientId: number
): Promise<CompaniesResult> {
  const url = `/company/review`;

  try {
    const { data } = await axiosClient.get<CompaniesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    data.companies.forEach((item) => {
      item.ShowAsActive = item.ShowAsActive == 1 ? "Active" : "Inactive";
    });

    //console.log("COMPANY", data)
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getUnconfirmedCompanies(
  accessToken: String,
  clientId: number
): Promise<CompaniesResult> {
  const url = `/company/unconfirmed`;

  try {
    const { data } = await axiosClient.get<CompaniesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    data.companies.forEach((item) => {
      item.ShowAsActive = item.ShowAsActive == 1 ? "Active" : "Inactive";
    });

    //console.log("COMPANY", data)
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCompany(
  accessToken: String,
  clientId: number,
  newCompany: Partial<Company>
): Promise<Company> {
  const url = `/company`;

  try {
    const { data } = await axiosClient.post<Company>(url, newCompany, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //console.log("COMPANY", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCompany(
  accessToken: String,
  clientId: number,
  companyID: number,
  newCompany: Partial<Company>
): Promise<Company> {
  const url = `/company/` + companyID;

  try {
    const { data } = await axiosClient.post<Company>(url, newCompany, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function rejectCompany(
  accessToken: String,
  companyID: number,
  reason: String,
  duplicateID?: number
): Promise<Company> {
  const url = `/company/reject/` + companyID;

  try {
    const { data } = await axiosClient.post<Company>(
      url,
      { reason: reason, duplicateID: duplicateID },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function mergeCompany(
  accessToken: String,
  companyID: number,
  newCompany: Partial<Company>,
  duplicateID: number
): Promise<Company> {
  const url = `/company/merge/` + companyID;

  try {
    const { data } = await axiosClient.post<Company>(
      url,
      { itemData: newCompany, duplicateID: duplicateID },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err) {
    throw err;
  }
}

export async function deleteCompany(
  accessToken: String,
  companyID: number
): Promise<string> {
  const url = `/company/` + companyID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
