import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Prompt } from "react-router";
import { Grid, Typography, Paper, Box, Divider } from "@mui/material";
import { IppFormDivider } from "components/IppFormDivider";
import { changeClientFeatures } from "features/client/ClientSlice";
import { ChangeEvent, useState } from "react";
import { IppSwitch } from "components/IppSwitch";
import { IppSkeleton } from "components/IppSkeleton";
import { useTypedTranslation } from "utils/customHooks";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { AllFeatureToggles, EditableFeatureToggles } from "api/clientAPI";
import { getChangedValues } from "utils/functions";

const PREFIX = "FeaturesForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1500,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const FeaturesForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);
  const [isEditing, setIsEditing] = useState(true);

  const {
    isLoading,
    ft_dev,
    ft_eng_lvlOfEngagement,
    ft_eng_Comment,
    ft_eng_RelCommunication,
    ft_ben_Contract,
    ft_eng_LegalSubDiv,
    ft_all_ExternalPersonRes,
    ft_eng_Sentiment,
    ft_eng_InternalNotes,
    ft_comeng_CommitmentInteraction,
    ft_eng_Grievance,
    ft_com_Phase,
    ft_eng_Phase,
    ft_all_Tag,
    ft_all_ProjectTypes,
    ft_eng_BCER,
    ft_ben_AltWorkHours2,
    ft_ben_AltWorkHours3,
    ft_ben_TempEmployee,
    ft_ben_DiversityDeclined,
    ft_ben_JobPosition,
    ft_ben_JobHistory,
    ft_ben_RND,
    ft_ben_CommunityInvestment,
    ft_ben_AllocatedDate,
    ft_ben_Region,
    ft_ben_BusinessCategory,
    ft_all_ProjectLead,
    ft_com_Priority,
    ft_com_Associated,
    ft_com_DateApplicable,
    ft_com_InternalNotes,
    ft_ben_Spend,
    ft_ben_SpendReport,
    ft_ben_CustomJobPosition,
    ft_eng_IntenalCommunication,
    ft_ben_WorkForce,
    ft_ben_WorkHourSubContractor,
    ft_ben_Ethnicity,
    ft_eng_PreferredCommunicationMethod,
    ft_ben_Asset,
    ft_eng_Importance,
    ft_eng_RelOwner,
    ft_eng_GroupIssue,
    ft_eng_ContactIssue,
    ft_ben_Tier,
    ft_eng_PILSD,
    ft_eng_Thread,
    ft_eng_ThreadQuickAdd,
    ft_ben_ActivityLocation,
    ft_ben_BenefitsMenu,
    ft_ben_CustomRnD,
    ft_ben_EmployeePostalCode,
    ft_ben_CustomEmpCategory,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );
  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  const moduleName = (ID: number) => {
    let result = clientModules.find((item) => item.ModuleID === ID);
    return result?.ModuleName || "No Access";
  };

  const onSub = async (values: EditableFeatureToggles) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      const updatedValues = getChangedValues(values, formik.initialValues);

      if (Object.keys(updatedValues).length === 0) return;

      dispatch(
        changeClientFeatures(
          accessToken,
          updatedValues,
          currentProfile.SystemUser || ft_dev,
          true
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

  const formData: AllFeatureToggles = {
    FT_ENG_LvlOfEngagement: ft_eng_lvlOfEngagement ?? false,
    FT_ENG_Comment: ft_eng_Comment ?? false,
    FT_ENG_RelCommunication: ft_eng_RelCommunication ?? false,
    FT_BEN_Contract: ft_ben_Contract ?? false,
    FT_ENG_LegalSubDiv: ft_eng_LegalSubDiv ?? false,
    FT_ALL_ExternalPersonRes: ft_all_ExternalPersonRes ?? false,
    FT_ENG_Sentiment: ft_eng_Sentiment ?? false,
    FT_ENG_InternalNotes: ft_eng_InternalNotes ?? false,
    FT_COMENG_CommitmentInteraction: ft_comeng_CommitmentInteraction ?? false,
    FT_ENG_Grievance: ft_eng_Grievance ?? false,
    FT_COM_Phase: ft_com_Phase ?? false,
    FT_ENG_Phase: ft_eng_Phase ?? false,
    FT_ALL_Tag: ft_all_Tag ?? false,
    FT_ALL_ProjectTypes: ft_all_ProjectTypes ?? false,
    FT_ENG_BCER: ft_eng_BCER ?? false,
    FT_BEN_AltWorkHours2: ft_ben_AltWorkHours2 ?? false,
    FT_BEN_AltWorkHours3: ft_ben_AltWorkHours3 ?? false,
    FT_BEN_TempEmployee: ft_ben_TempEmployee ?? false,
    FT_BEN_DiversityDeclined: ft_ben_DiversityDeclined ?? false,
    FT_BEN_JobPosition: ft_ben_JobPosition ?? false,
    FT_BEN_JobHistory: ft_ben_JobHistory ?? false,
    FT_BEN_RND: ft_ben_RND ?? false,
    FT_BEN_CommunityInvestment: ft_ben_CommunityInvestment ?? false,
    FT_BEN_AllocatedDate: ft_ben_AllocatedDate ?? false,
    FT_BEN_Region: ft_ben_Region ?? false,
    FT_BEN_BusinessCategory: ft_ben_BusinessCategory ?? false,
    FT_ALL_ProjectLead: ft_all_ProjectLead ?? false,
    FT_COM_Priority: ft_com_Priority ?? false,
    FT_COM_Associated: ft_com_Associated ?? false,
    FT_COM_DateApplicable: ft_com_DateApplicable ?? false,
    FT_COM_InternalNotes: ft_com_InternalNotes ?? false,
    FT_BEN_Spend: ft_ben_Spend ?? false,
    FT_BEN_SpendReport: ft_ben_SpendReport ?? false,
    FT_BEN_CustomJobPosition: ft_ben_CustomJobPosition ?? false,
    FT_ENG_IntenalCommunication: ft_eng_IntenalCommunication ?? false,
    FT_BEN_WorkForce: ft_ben_WorkForce ?? false,
    FT_BEN_Asset: ft_ben_Asset ?? false,
    FT_BEN_Ethnicity: ft_ben_Ethnicity ?? false,
    FT_ENG_PreferredCommunicationMethod:
      ft_eng_PreferredCommunicationMethod ?? false,
    FT_BEN_CustomEmpCategory: ft_ben_CustomEmpCategory ?? false,
    FT_BEN_EmployeePostalCode: ft_ben_EmployeePostalCode ?? false,

    ///------------------System User Toggles-----------------///
    FT_ENG_Importance: ft_eng_Importance ?? false,
    FT_ENG_RelOwner: ft_eng_RelOwner ?? false,
    FT_ENG_GroupIssue: ft_eng_GroupIssue ?? false,
    FT_ENG_ContactIssue: ft_eng_ContactIssue ?? false,
    FT_BEN_Tier: ft_ben_Tier ?? false,
    FT_ENG_PILSD: ft_eng_PILSD ?? false,
    FT_ENG_Thread: ft_eng_Thread ?? false,
    FT_ENG_ThreadQuickAdd: ft_eng_ThreadQuickAdd ?? false,
    FT_BEN_ActivityLocation: ft_ben_ActivityLocation ?? false,
    FT_BEN_BenefitsMenu: ft_ben_BenefitsMenu ?? false,
    FT_BEN_CustomRnD: ft_ben_CustomRnD ?? false,
    FT_BEN_WorkHourSubContractor: ft_ben_WorkHourSubContractor ?? false,
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: onSub,
  });

  const editForm = (
    <div>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message="You have unsaved changes; are you sure you want to leave this page?"
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Feature Toggles
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              This form is used to enable/disable specific features on the
              platform which you do not intend to use or newly find yourself
              needing. Turning off a feature will retain existing data
              internally but will no longer be accessible to anyone.
              Consequently enabling a feature previously unused will imply blank
              fields on already existing data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IppSubmitButton
              buttonText={t("strGen:buttons.saveobjchanges", {
                objectname: t("strGen:pages.features.name"),
              })}
              disabled={!formik.dirty}
            />
          </Grid>

          <Box padding={1} sx={{ flexGrow: 1, borderRadius: 1 }}>
            <Grid
              container
              direction={"row"}
              display="flex"
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="space-around"
            >
              {
                //----------PLATFORM --------------//
              }
              {(clientModules.find((item) => item.ModuleID === 2) ||
                clientModules.find((item) => item.ModuleID === 3)) && (
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider title="Platform" />

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ALL_ExternalPersonRes"
                          label="Additional Person Responsible"
                          value={formik.values.FT_ALL_ExternalPersonRes}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue(
                              "FT_ALL_ExternalPersonRes",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature allows you to track Additional Contacts.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      {clientModules.find((item) => item.ModuleID === 2) &&
                        clientModules.find((item) => item.ModuleID === 3) && (
                          <Grid item xs>
                            <IppSwitch
                              id="FT_COMENG_CommitmentInteraction"
                              label="Commitment Communications"
                              value={
                                formik.values.FT_COMENG_CommitmentInteraction
                              }
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.setFieldValue(
                                  "FT_COMENG_CommitmentInteraction",
                                  newValue
                                );
                              }}
                              isEditing={isEditing}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              Used to connect Commitments and Engagements. This
                              feature allows you to track connections between
                              specific Commitments and Communications.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>
                        )}

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_LegalSubDiv"
                          label={t(
                            "objPlt:platformwide.fields.legalsubdivision",
                            { count: 2 }
                          )}
                          value={formik.values.FT_ENG_LegalSubDiv}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue(
                              "FT_ENG_LegalSubDiv",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          Used in connection with Locations. This feature tracks
                          the locations not covered by postal code regions and
                          are Legal/Government identified. This field will be
                          available on Group, Contact and Communication records.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ALL_ProjectTypes"
                          label={t("objPlt:objects.project.projecttype.name", {
                            count: 2,
                          })}
                          value={formik.values.FT_ALL_ProjectTypes}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue(
                              "FT_ALL_ProjectTypes",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          Used in connection with Projects. This feature allows
                          you to classify your Projects with different Types.
                          The Project Types list is customizable in the Admin
                          Menu under Lookup Lists.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ALL_Tag"
                          label="Tags"
                          value={formik.values.FT_ALL_Tag}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue("FT_ALL_Tag", newValue);
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          Used to create specific tags. These Tags can be used
                          as references for Contacts or Commitments. It is best
                          to keep this list short and essential.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ALL_ProjectLead"
                          label="Project Lead"
                          value={formik.values.FT_ALL_ProjectLead}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue(
                              "FT_ALL_ProjectLead",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature tracks the Project Lead on the Project
                          form.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {
                //----------ENGAGEMENTS --------------//
              }
              {clientModules.find((item) => item.ModuleID === 3) && (
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <IppFormDivider title={moduleName(3)} />

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_Comment"
                        label="Comments"
                        value={formik.values.FT_ENG_Comment}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_ENG_Comment", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Groups. This feature tracks the
                        comments made during communications.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_Phase"
                        label="Communication Phases"
                        value={formik.values.FT_ENG_Phase}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_ENG_Phase", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Communications. This feature
                        tracks the phases the communication is in relation to.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_Grievance"
                        label={t("objStk:objects.grievance.name_other")}
                        value={formik.values.FT_ENG_Grievance}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_ENG_Grievance", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        This feature is used to track concerns made by the
                        Contacts and Groups associated with your Project.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_IntenalCommunication"
                        label={t(
                          "objStk:objects.interaction.fields.internalcommunication",
                          { count: 2 }
                        )}
                        value={formik.values.FT_ENG_IntenalCommunication}
                        onChangeFunction={(_: ChangeEvent, newValue: any) => {
                          formik.setFieldValue(
                            "FT_ENG_IntenalCommunication",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        {t(
                          "strGen:pages.features.toggles.internalcommunications"
                        )}
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_InternalNotes"
                        label="Internal Notes"
                        value={formik.values.FT_ENG_InternalNotes}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue(
                            "FT_ENG_InternalNotes",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Communications. This feature can
                        be used to record confidential notes or comments meant
                        for internal reference only.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_LvlOfEngagement"
                        label="Levels of Engagement"
                        value={formik.values.FT_ENG_LvlOfEngagement}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue(
                            "FT_ENG_LvlOfEngagement",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Groups. This feature tracks the
                        impact and interest of that group in relation to the
                        project
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    {!ft_eng_Thread && (
                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_RelCommunication"
                          label="Related Communications"
                          value={formik.values.FT_ENG_RelCommunication}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.setFieldValue(
                              "FT_ENG_RelCommunication",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          Used in connection with Groups. This feature tracks
                          when an initial communication results in another
                          communication in form of a thread.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    )}

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_Sentiment"
                        label="Sentiments"
                        value={formik.values.FT_ENG_Sentiment}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_ENG_Sentiment", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Sentiments. This feature gauges
                        the mood or emotions during an interaction.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_BCER"
                        label="BC Energy Regulator"
                        value={formik.values.FT_ENG_BCER}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_ENG_BCER", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Concerns to track Proponent
                        Accomodations, Interests Addressed and Residual
                        Concerns.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_ENG_PreferredCommunicationMethod"
                        label="Preferred Communication Method"
                        value={
                          formik.values.FT_ENG_PreferredCommunicationMethod
                        }
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue(
                            "FT_ENG_PreferredCommunicationMethod",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Contacts to save the Preferred
                        Communication Method for a Contact.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {
                //----------COMMITMENTS --------------//
              }
              {clientModules.find((item) => item.ModuleID === 2) && (
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <IppFormDivider title={moduleName(2)} />
                    <Grid item xs>
                      <IppSwitch
                        id="FT_COM_Phase"
                        label="Commitment Phases"
                        value={formik.values.FT_COM_Phase}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_COM_Phase", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        Used in connection with Commitments. This feature tracks
                        the phases the commitment is in relation to.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_COM_Priority"
                        label={t("objCom:objects.commitment.fields.priority")}
                        value={formik.values.FT_COM_Priority}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_COM_Priority", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        This feature tracks the priority of the Commitment.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_COM_Associated"
                        label={t(
                          "objCom:objects.commitment.fields.associatedgroupscontacts"
                        )}
                        value={formik.values.FT_COM_Associated}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue("FT_COM_Associated", newValue);
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        This feature tracks the Associated Groups and Contacts
                        in Commitments.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_COM_DateApplicable"
                        label={t(
                          "objCom:objects.commitment.fields.dateapplicable"
                        )}
                        value={formik.values.FT_COM_DateApplicable}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue(
                            "FT_COM_DateApplicable",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        This feature tracks the Applicable Date field on a
                        Commitment.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>

                    <Grid item xs>
                      <IppSwitch
                        id="FT_COM_InternalNotes"
                        label={t(
                          "objCom:objects.commitment.fields.internalnotes"
                        )}
                        value={formik.values.FT_COM_InternalNotes}
                        onChangeFunction={(
                          event: ChangeEvent,
                          newValue: any
                        ) => {
                          formik.setFieldValue(
                            "FT_COM_InternalNotes",
                            newValue
                          );
                        }}
                        isEditing={isEditing}
                      />
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ paddingLeft: "45px" }}
                      >
                        This feature tracks the Internal Notes field on a
                        Commitment.
                      </Typography>
                      <Divider variant="middle" />
                    </Grid>
                  </Paper>
                </Grid>
              )}

              {
                //----------BENEFITS --------------//
              }
              {ft_ben_BenefitsMenu &&
                clientModules.find((item) => item.ModuleID === 1) && (
                  <Grid container item xs direction={"column"}>
                    <Paper elevation={6} className={classes.boxSpace}>
                      <IppFormDivider title={moduleName(1)} />

                      {/* Can't have both WorkHourSubContractor and Contract enabled at the same time*/}
                      {!ft_ben_WorkHourSubContractor && (
                        <Grid item xs>
                          <IppSwitch
                            id="FT_BEN_Contract"
                            label="Contract"
                            value={formik.values.FT_BEN_Contract}
                            onChangeFunction={(
                              event: ChangeEvent,
                              newValue: any
                            ) => {
                              formik.handleChange(event);
                              formik.setFieldValue("FT_BEN_Contract", newValue);

                              if (
                                newValue &&
                                formik.values.FT_BEN_WorkHourSubContractor
                              ) {
                                formik.setFieldValue(
                                  "FT_BEN_WorkHourSubContractor",
                                  false
                                );
                              }
                            }}
                            isEditing={isEditing}
                          />
                          <Typography
                            variant="caption"
                            paragraph
                            sx={{ paddingLeft: "45px" }}
                          >
                            This feature tracks the impact of a contract and how
                            readily they are being fulfilled.
                          </Typography>
                          <Divider variant="middle" />
                        </Grid>
                      )}

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_DiversityDeclined"
                          label="Diversity Declined"
                          value={formik.values.FT_BEN_DiversityDeclined}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_BEN_DiversityDeclined",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used in connection with an employee's
                          diversity profile. You can choose to show or hide the
                          'Declined?' checkbox when editing an Employee form.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      {!ft_ben_CustomRnD && (
                        <>
                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_RND"
                              label={t("objBen:objects.initiative.rndtoggle")}
                              value={formik.values.FT_BEN_RND}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue("FT_BEN_RND", newValue);

                                if (!newValue) {
                                  formik.setFieldValue("FT_BEN_Asset", false);
                                  formik.setFieldValue(
                                    "FT_BEN_AllocatedDate",
                                    false
                                  );
                                }
                              }}
                              isEditing={isEditing}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature is used in connection with{" "}
                              {t("objBen:objects.initiative.rndtoggle")} to
                              track the Assets that an Expense is applied to.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_Asset"
                              label={t("objBen:objects.asset.name")}
                              value={formik.values.FT_BEN_Asset}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue("FT_BEN_Asset", newValue);

                                if (!newValue) {
                                  formik.setFieldValue(
                                    "FT_BEN_AllocatedDate",
                                    false
                                  );
                                }
                              }}
                              isEditing={isEditing}
                              disabled={!formik.values.FT_BEN_RND}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature is used in connection with
                              Initiatives, Research & Development and Education
                              & Training.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_AllocatedDate"
                              label={t(
                                "objBen:objects.rndExpense.fields.applieddate"
                              )}
                              value={formik.values.FT_BEN_AllocatedDate}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue(
                                  "FT_BEN_AllocatedDate",
                                  newValue
                                );
                              }}
                              isEditing={isEditing}
                              disabled={!formik.values.FT_BEN_Asset}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature controls if Allocated Date is year
                              only on both the Research & Development and
                              Education & Training Expense forms.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>
                        </>
                      )}

                      {/* <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_CustomRnD"
                          label="R&D and E&T Expenditures"
                          value={formik.values.FT_BEN_CustomRnD}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_CustomRnD", newValue);
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used in connection with Research &
                          Development , Education and Training Expenditures.
                        </Typography>
                      </Grid> */}

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_CommunityInvestment"
                          label={t("objBen:objects.communityinvestment.name")}
                          value={formik.values.FT_BEN_CommunityInvestment}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_BEN_CommunityInvestment",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used in connection with Community
                          Investment.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_Region"
                          label={t(
                            "objBen:objects.company.fields.region.name_other"
                          )}
                          value={formik.values.FT_BEN_Region}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_Region", newValue);
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature can be used to track the Region for
                          Companies, Employees and/or Projects.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_BusinessCategory"
                          label={t(
                            "objBen:objects.company.fields.businesscategory"
                          )}
                          value={formik.values.FT_BEN_BusinessCategory}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_BEN_BusinessCategory",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature tracks Category of Business on the
                          Company form.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_WorkForce"
                          label={t("strGen:pages.features.workforcelabel")}
                          value={formik.values.FT_BEN_WorkForce}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_WorkForce", newValue);
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          {t("strGen:pages.features.toggles.workforcetoggle")}
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      {formik.values.FT_BEN_WorkForce && (
                        <>
                          <Grid item xs sx={{ paddingTop: "15px" }}>
                            <IppFormDivider title="Work Hours" />
                            <Typography variant="caption" paragraph>
                              These features are used to customize Work Hours:
                            </Typography>
                            <Grid
                              container
                              item
                              xs
                              direction={"column"}
                              sx={{ paddingLeft: "15px" }}
                            >
                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_AltWorkHours2"
                                  label={t(
                                    "objBen:objects.workhour.detail.fields.althours1full"
                                  )}
                                  value={formik.values.FT_BEN_AltWorkHours2}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_AltWorkHours2",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  {t(
                                    "strGen:pages.features.toggles.althourstoggle",
                                    {
                                      fieldname: t(
                                        "objBen:objects.workhour.detail.fields.althours1full"
                                      ),
                                    }
                                  )}
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_AltWorkHours3"
                                  label={t(
                                    "objBen:objects.workhour.detail.fields.althours2full"
                                  )}
                                  value={formik.values.FT_BEN_AltWorkHours3}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_AltWorkHours3",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  {t(
                                    "strGen:pages.features.toggles.althourstoggle",
                                    {
                                      fieldname: t(
                                        "objBen:objects.workhour.detail.fields.althours2full"
                                      ),
                                    }
                                  )}
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs sx={{ paddingTop: "15px" }}>
                            <IppFormDivider title="Employee" />
                            <Typography variant="caption" paragraph>
                              These features are used to customize Employee
                              Records:
                            </Typography>

                            <Grid
                              container
                              item
                              xs
                              direction={"column"}
                              sx={{ paddingLeft: "15px" }}
                            >
                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_JobPosition"
                                  label={t(
                                    "objBen:objects.employee.fields.jobposition"
                                  )}
                                  value={formik.values.FT_BEN_JobPosition}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_JobPosition",
                                      newValue
                                    );

                                    if (!newValue) {
                                      formik.setFieldValue(
                                        "FT_BEN_JobHistory",
                                        false
                                      );
                                    }
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track the Job Position and Apprentice Status
                                  of Employees. Disabling this feature will also
                                  disable Job History.
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_JobHistory"
                                  label={t(
                                    "objBen:objects.employee.jobhistory.name"
                                  )}
                                  value={formik.values.FT_BEN_JobHistory}
                                  disabled={!formik.values.FT_BEN_JobPosition}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_JobHistory",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track historical Job Position and status
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_TempEmployee"
                                  label="Temporary Status"
                                  value={formik.values.FT_BEN_TempEmployee}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_TempEmployee",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track Employee Temporary Status
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_Ethnicity"
                                  label={t(
                                    "objBen:objects.employee.fields.ethnicity"
                                  )}
                                  value={formik.values.FT_BEN_Ethnicity}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_Ethnicity",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track the Ethnicity of an Employee
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_CustomJobPosition"
                                  label={t(
                                    "objBen:objects.employee.fields.customJobPosition"
                                  )}
                                  value={formik.values.FT_BEN_CustomJobPosition}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_CustomJobPosition",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track the{" "}
                                  {t(
                                    "objBen:objects.employee.fields.customJobPosition"
                                  )}{" "}
                                  of Employee form.
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>
                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_CustomEmpCategory"
                                  label={t(
                                    "objBen:objects.employee.fields.customCategory"
                                  )}
                                  value={formik.values.FT_BEN_CustomEmpCategory}
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_CustomEmpCategory",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track the{" "}
                                  {t(
                                    "objBen:objects.employee.fields.customCategory"
                                  )}{" "}
                                  of an Employee.
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>

                              <Grid item xs>
                                <IppSwitch
                                  id="FT_BEN_EmployeePostalCode"
                                  label={t(
                                    "objPlt:platformwide.fields.postalcode"
                                  )}
                                  value={
                                    formik.values.FT_BEN_EmployeePostalCode
                                  }
                                  onChangeFunction={(
                                    event: ChangeEvent,
                                    newValue: any
                                  ) => {
                                    formik.handleChange(event);
                                    formik.setFieldValue(
                                      "FT_BEN_EmployeePostalCode",
                                      newValue
                                    );
                                  }}
                                  isEditing={isEditing}
                                />
                                <Typography
                                  variant="caption"
                                  paragraph
                                  sx={{ paddingLeft: "45px" }}
                                >
                                  Track the{" "}
                                  {t("objPlt:platformwide.fields.postalcode")}{" "}
                                  on the Employee Form
                                </Typography>
                                <Divider variant="middle" />
                              </Grid>
                            </Grid>
                            <Divider variant="middle" />
                          </Grid>
                        </>
                      )}

                      <Grid item xs sx={{ paddingTop: "15px" }}>
                        <IppFormDivider title="Spend" />
                        <Typography variant="caption" paragraph>
                          These features are used to customize Spend:
                        </Typography>
                        <Grid
                          container
                          item
                          xs
                          direction={"column"}
                          sx={{ paddingLeft: "15px" }}
                        >
                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_Spend"
                              label={t("objBen:objects.payment.directspend")}
                              value={formik.values.FT_BEN_Spend}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue("FT_BEN_Spend", newValue);
                              }}
                              isEditing={isEditing}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature tracks Direct Spend.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_SpendReport"
                              label={t("objBen:objects.spendreporting.name")}
                              value={formik.values.FT_BEN_SpendReport}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue(
                                  "FT_BEN_SpendReport",
                                  newValue
                                );
                              }}
                              isEditing={isEditing}
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature tracks Spend Reports.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/*
                      // Below currently commented out as it is only for Imperial at this time

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_Tier"
                          label={t("objBen:objects.tiers.name", { count: 2 })}
                          value={formik.values.FT_BEN_Tier}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_Tier", newValue);
                          }}
                          isEditing={isEditing}
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          {t("strGen:pages.features.toggles.tiers")}
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    */}
                    </Paper>
                  </Grid>
                )}
            </Grid>
          </Box>

          {
            //----------SYSTEM USER TOGGLES --------------//
          }
          {(currentProfile.SystemUser || ft_dev) && (
            <Box
              padding={1}
              sx={{
                flexGrow: 1,
                borderRadius: 1,
                bgcolor: "#FFF2F3",
                marginY: 2,
                marginX: 1,
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h6" color="error" fontWeight="bold">
                  System User Toggles
                </Typography>
              </Box>

              <Divider sx={{ marginBottom: 2 }} />

              <Grid
                container
                direction={"row"}
                display="flex"
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                justifyContent="space-around"
              >
                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider title="Platform" titleColor="error" />

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_PILSD"
                          label="Proximity/Legal Subdivision"
                          value={formik.values.FT_ENG_PILSD}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_ENG_PILSD", newValue);
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used to track Legal Site Descriptors
                          and their Proximity to major operations.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      {/* <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_RelOwner"
                          label="Relationship Owner"
                          value={formik.values.FT_ENG_RelOwner}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_ENG_RelOwner", newValue);
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        ></Typography>
                        <Divider variant="middle" />
                      </Grid> */}
                    </Grid>
                  </Paper>
                </Grid>

                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider
                        title="Engagement and Relations"
                        titleColor="error"
                      />

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_ContactIssue"
                          label={t("objStk:objects.issue.contactissues")}
                          value={formik.values.FT_ENG_ContactIssue}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_ENG_ContactIssue",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          {t("strGen:pages.features.toggles.issuetoggle", {
                            fieldname: t("objPlt:objects.contact.name_other"),
                          })}
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_GroupIssue"
                          label={t("objStk:objects.issue.groupissues")}
                          value={formik.values.FT_ENG_GroupIssue}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_ENG_GroupIssue", newValue);
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          {t("strGen:pages.features.toggles.issuetoggle", {
                            fieldname: t("objPlt:objects.group.name_other"),
                          })}
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_Importance"
                          label="Level of Importance"
                          value={formik.values.FT_ENG_Importance}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_ENG_Importance", newValue);
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used to hide the Level of Importance
                          column from the Groups Inventory page -- this column
                          will only show if this toggle is turned on and Levels
                          of Engagement is turned off.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_Thread"
                          label="Communication Threads"
                          value={formik.values.FT_ENG_Thread}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_ENG_Thread", newValue);

                            if (!newValue) {
                              formik.setFieldValue(
                                "FT_ENG_ThreadQuickAdd",
                                false
                              );
                            }
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used to link Communications in
                          Threads.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_ENG_ThreadQuickAdd"
                          label="Threads Quick Add"
                          value={formik.values.FT_ENG_ThreadQuickAdd}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_ENG_ThreadQuickAdd",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature allows users to link Communications to
                          Threads directly in the Add and Edit Communication
                          forms.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider
                        title="Commitment and Agreements"
                        titleColor="error"
                      />
                    </Grid>
                  </Paper>
                </Grid>

                <Grid container item xs direction={"column"}>
                  <Paper elevation={6} className={classes.boxSpace}>
                    <Grid item xs>
                      <IppFormDivider
                        title="Benefits Tracking"
                        titleColor="error"
                      />

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_ActivityLocation"
                          label="Activity Location"
                          value={formik.values.FT_BEN_ActivityLocation}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_BEN_ActivityLocation",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used track the location that a Company
                          is active in.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_BenefitsMenu"
                          label="Benefits Menu"
                          value={formik.values.FT_BEN_BenefitsMenu}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue(
                              "FT_BEN_BenefitsMenu",
                              newValue
                            );
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used hide Spend, Employees and Work
                          Hours from the Benefits menu.
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      {ft_ben_WorkHourSubContractor && (
                        <Grid item xs>
                          <IppSwitch
                            id="FT_BEN_Contract"
                            label="Contract"
                            value={formik.values.FT_BEN_Contract}
                            onChangeFunction={(
                              event: ChangeEvent,
                              newValue: any
                            ) => {
                              formik.handleChange(event);
                              formik.setFieldValue("FT_BEN_Contract", newValue);

                              if (
                                newValue &&
                                formik.values.FT_BEN_WorkHourSubContractor
                              ) {
                                formik.setFieldValue(
                                  "FT_BEN_WorkHourSubContractor",
                                  false
                                );
                              }
                            }}
                            isEditing={isEditing}
                            color="error"
                          />
                          <Typography
                            variant="caption"
                            paragraph
                            sx={{ paddingLeft: "45px" }}
                          >
                            NOTE: This feature will be visible in the main
                            Features list if the Work Hour Period Subcontractor
                            Feature is turned off. Turning this Feature on will
                            turn off the Work Hour Period Subcontractor Feature.
                          </Typography>
                          <Typography
                            variant="caption"
                            paragraph
                            sx={{ paddingLeft: "45px" }}
                          >
                            This feature tracks the impact of a contract and how
                            readily they are being fulfilled.
                          </Typography>
                          <Divider variant="middle" />
                        </Grid>
                      )}

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_CustomRnD"
                          label="Custom R&D Expenditures"
                          value={formik.values.FT_BEN_CustomRnD}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_CustomRnD", newValue);

                            if (newValue) {
                              formik.setFieldValue("FT_BEN_RND", false);
                              formik.setFieldValue("FT_BEN_Asset", false);
                              formik.setFieldValue(
                                "FT_BEN_AllocatedDate",
                                false
                              );
                            }
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          NOTE: Turning this Feature on will turn off the{" "}
                          {t("objBen:objects.initiative.rndtoggle")} Feature.
                        </Typography>
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature is used in connection with Research &
                          Development , Education and Training Expenditures.
                        </Typography>
                      </Grid>

                      {ft_ben_CustomRnD && (
                        <>
                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_RND"
                              label={t("objBen:objects.initiative.rndtoggle")}
                              value={formik.values.FT_BEN_RND}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue("FT_BEN_RND", newValue);

                                if (!newValue) {
                                  formik.setFieldValue("FT_BEN_Asset", false);
                                  formik.setFieldValue(
                                    "FT_BEN_AllocatedDate",
                                    false
                                  );
                                } else {
                                  formik.setFieldValue(
                                    "FT_BEN_CustomRnD",
                                    false
                                  );
                                }
                              }}
                              isEditing={isEditing}
                              color="error"
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              NOTE: Turning this Feature on will turn off the
                              Custom R&D Feature.
                            </Typography>
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature is used in connection with{" "}
                              {t("objBen:objects.initiative.rndtoggle")} to
                              track the Assets that an Expense is applied to.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_Asset"
                              label={t("objBen:objects.asset.name")}
                              value={formik.values.FT_BEN_Asset}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue("FT_BEN_Asset", newValue);

                                if (!newValue) {
                                  formik.setFieldValue(
                                    "FT_BEN_AllocatedDate",
                                    false
                                  );
                                }
                              }}
                              isEditing={isEditing}
                              disabled={!formik.values.FT_BEN_RND}
                              color="error"
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature is used in connection with
                              Initiatives, Research & Development and Education
                              & Training.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>

                          <Grid item xs>
                            <IppSwitch
                              id="FT_BEN_AllocatedDate"
                              label={t(
                                "objBen:objects.rndExpense.fields.applieddate"
                              )}
                              value={formik.values.FT_BEN_AllocatedDate}
                              onChangeFunction={(
                                event: ChangeEvent,
                                newValue: any
                              ) => {
                                formik.handleChange(event);
                                formik.setFieldValue(
                                  "FT_BEN_AllocatedDate",
                                  newValue
                                );
                              }}
                              isEditing={isEditing}
                              disabled={!formik.values.FT_BEN_Asset}
                              color="error"
                            />
                            <Typography
                              variant="caption"
                              paragraph
                              sx={{ paddingLeft: "45px" }}
                            >
                              This feature controls if Allocated Date is year
                              only on both the Research & Development and
                              Education & Training Expense forms.
                            </Typography>
                            <Divider variant="middle" />
                          </Grid>
                        </>
                      )}

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_Tier"
                          label={t("objBen:objects.tiers.name", { count: 2 })}
                          value={formik.values.FT_BEN_Tier}
                          onChangeFunction={(
                            event: ChangeEvent,
                            newValue: any
                          ) => {
                            formik.handleChange(event);
                            formik.setFieldValue("FT_BEN_Tier", newValue);
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          {t("strGen:pages.features.toggles.tiers")}
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>

                      <Grid item xs>
                        <IppSwitch
                          id="FT_BEN_WorkHourSubContractor"
                          label="Work Hour Period Sub-Contractors"
                          value={formik.values.FT_BEN_WorkHourSubContractor}
                          onChangeFunction={(_: ChangeEvent, newValue: any) => {
                            formik.setFieldValue(
                              "FT_BEN_WorkHourSubContractor",
                              newValue
                            );

                            if (newValue && formik.values.FT_BEN_Contract) {
                              formik.setFieldValue("FT_BEN_Contract", false);
                            }
                          }}
                          isEditing={isEditing}
                          color="error"
                        />
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          NOTE: Turning this Feature on will turn off and hide
                          the Contract Feature Toggle from Users as they cannot
                          both be on at the same time.
                        </Typography>
                        <Typography
                          variant="caption"
                          paragraph
                          sx={{ paddingLeft: "45px" }}
                        >
                          This feature enables flagging a Work Hour Period as
                          being logged against a Contracting Company
                        </Typography>
                        <Divider variant="middle" />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid item xs={12}>
            <IppSubmitButton
              buttonText={t("strGen:buttons.saveobjchanges", {
                objectname: t("strGen:pages.features.name"),
              })}
              disabled={!formik.dirty}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );

  return (
    <div>
      <Root>
        <Box display="flex" justifyContent="center">
          <Paper className={classes.boxSpace}>
            <Grid container className={classes.editForm} spacing={1}>
              {isLoading ? (
                <IppSkeleton height={400} />
              ) : (
                <Grid item sm={12}>
                  {editForm}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Root>
    </div>
  );
};
