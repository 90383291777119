import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { Grid, Typography } from "@mui/material";
import { WorkHourDetail } from "api/workHourDetailAPI";
import { addWorkHourDetail, updWorkHourDetail } from "./WorkHourDetailSlice";
import { ChangeEvent, useEffect, useState } from "react";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppTextField } from "components/IppTextField";
import { WorkHourDetailValidationSchema } from "./WorkHourDetailValidation";
import { checkEmployeeNeedsReview } from "utils/benefits/employeeFunctions";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { useTypedTranslation } from "utils/customHooks";
import { IppNumberField } from "components/IppNumberField";

interface WorkHourDetailChildFormProps {
  handleClose: any;
  parentID: number;
  whd?: WorkHourDetail;
}

export const WorkHourDetailChildForm = (
  props: WorkHourDetailChildFormProps
) => {
  const { handleClose, parentID, whd } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  // const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);
  const t = useTypedTranslation(["objBen"]);

  const [showEmpWarning, setShowEmpWarning] = useState(false);

  let currentWHD: WorkHourDetail = whd || {
    WorkHourDetailID: -1,
    PeriodID: parentID,
    EmployeeID: -1,
    RegularHours: 0,
    AltHours2: 0,
    AltHours3: 0,
  };

  const {
    ft_ben_AltWorkHours2,
    ft_ben_AltWorkHours3,
    ft_ben_JobPosition,
    ft_ben_Region,
    ft_ben_DiversityDeclined,
  } = useSelector((state: RootState) => state.client);

  const { employeeList, employeesById } = useSelector(
    (state: RootState) => state.employees
  );

  const employees = employeeList.map((id) => employeesById[id]);

  const { jobPositionList, jobPositionsById } = useSelector(
    (state: RootState) => state.jobPositions
  );

  const jobPositions = ft_ben_JobPosition
    ? jobPositionList.map((id) => jobPositionsById[id])
    : [];

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (ft_ben_Region)
          dispatch(fetchRegionsByRecordType(accessToken, "Employee"));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [ft_ben_Region, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        delete values.CompanyEmployeeID;
        delete values.WorkHourDetailID;

        if (whd) {
          dispatch(
            updWorkHourDetail(accessToken, whd.WorkHourDetailID, values)
          );
        } else {
          dispatch(addWorkHourDetail(accessToken, values));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const subFormik = useFormik({
    initialValues: currentWHD,
    validationSchema: WorkHourDetailValidationSchema(
      ft_ben_AltWorkHours2 || false,
      ft_ben_AltWorkHours3 || false
    ),
    onSubmit: onSub,
  });

  useEffect(() => {
    if (subFormik.values.EmployeeID > 0) {
      const employee = employeesById[subFormik.values.EmployeeID];
      const toggles = {
        ft_ben_JobPosition: ft_ben_JobPosition ?? false,
        ft_ben_DiversityDeclined: ft_ben_DiversityDeclined ?? false,
        hasRegionTypes: hasRegionTypes,
      };

      const empNeedsReview = checkEmployeeNeedsReview(
        employee,
        toggles,
        jobPositions
      );

      if (showEmpWarning !== empNeedsReview) setShowEmpWarning(empNeedsReview);
    }
  }, [subFormik.values.EmployeeID]);

  return (
    <>
      <form onSubmit={subFormik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {whd ? "Edit " : "Add "} Work-Hour details below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <IppAutocomplete
              id="EmployeeID"
              options={employees}
              value={employees.find((obj) => {
                return obj.EmployeeID === subFormik.values.EmployeeID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  subFormik.setFieldValue("EmployeeID", newValue.EmployeeID);
                } else {
                  subFormik.setFieldValue("EmployeeID", -1);
                }
              }}
              label="Employee"
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.CompanyEmployeeID}
              textValueFunction={
                subFormik.values.EmployeeID > 0 &&
                employeesById[subFormik.values.EmployeeID]
                  ? employeesById[subFormik.values.EmployeeID].CompanyEmployeeID
                  : ""
              }
              touchedFunction={subFormik.touched.EmployeeID}
              errorFunction={subFormik.errors.EmployeeID}
            />
            {showEmpWarning && (
              <Typography variant="caption">
                <strong>
                  Due to Platform updates, this Employee is missing required
                  information. After this record is saved, please visit
                  Employees Needing Review.
                </strong>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <IppNumberField
              id="RegularHours"
              label={
                ft_ben_AltWorkHours2 || ft_ben_AltWorkHours3
                  ? t("objBen:objects.workhour.detail.fields.regularhours")
                  : t("objBen:objects.workhour.detail.fields.hours")
              }
              value={subFormik.values.RegularHours}
              onChangeFunction={(newValue) =>
                subFormik.setFieldValue("RegularHours", newValue)
              }
              isEditing={true}
              touchedExpression={subFormik.touched.RegularHours}
              errorsExpression={subFormik.errors.RegularHours}
              allowNegative={false}
            />
          </Grid>
          {ft_ben_AltWorkHours2 && (
            <Grid item xs={12}>
              <IppNumberField
                id="AltHours2"
                label={t("objBen:objects.workhour.detail.fields.althours1")}
                value={subFormik.values.AltHours2}
                onChangeFunction={(newValue) =>
                  subFormik.setFieldValue("AltHours2", newValue)
                }
                isEditing={true}
                touchedExpression={subFormik.touched.AltHours2}
                errorsExpression={subFormik.errors.AltHours2}
                allowNegative={false}
              />
            </Grid>
          )}
          {ft_ben_AltWorkHours3 && (
            <Grid item xs={12}>
              <IppNumberField
                id="AltHours3"
                label={t("objBen:objects.workhour.detail.fields.althours2")}
                value={subFormik.values.AltHours3}
                onChangeFunction={(newValue) =>
                  subFormik.setFieldValue("AltHours3", newValue)
                }
                isEditing={true}
                touchedExpression={subFormik.touched.AltHours3}
                errorsExpression={subFormik.errors.AltHours3}
                allowNegative={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={true}
              setIsEditing={null}
              isAdding={!whd}
              showCancel={true}
              resetFunction={handleClose}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};
