import { useTypedTranslation } from "utils/customHooks";
import * as yup from "yup";

export const EnTExpenseValidation = (isAdding: boolean) => {
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);

  let validationSchema: Record<string, yup.AnySchema> = {
    // no longer required
    InitiativeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.initiative.name"),
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.initiative.name"),
        })
      ),
    CourseName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.entExpense.fields.coursename"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.entExpense.fields.coursename"),
          count: 250,
        })
      ),
    Classification: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
          count: 250,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
        })
      ),
    Date: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.entExpense.fields.expensedate"),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.entExpense.fields.expensedate"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    Provider: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.entExpense.fields.provider"),
          count: 250,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.provider"),
        })
      ),
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    AssetID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.asset.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.asset.name"),
        })
      )
      .when("Applied", (applied, schema) => {
        return applied[0]
          ? schema.required(
              t("strGen:validation.required", {
                fieldname: t("objBen:objects.asset.name"),
              })
            )
          : schema.nullable();
      }),
    Applied: yup.boolean().required(
      t("strGen:validation.required", {
        fieldname: t("objBen:objects.entExpense.fields.applied"),
      })
    ),
    AppliedDate: yup
      .date()
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.entExpense.fields.applieddate"),
        })
      )
      .typeError(
        t("strGen:validation.date.yearformat", {
          fieldname: t("objBen:objects.entExpense.fields.applieddate"),
        })
      )
      .when("Applied", (applied, schema) => {
        if (applied[0]) {
          return schema.required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.entExpense.fields.applieddate"),
            })
          );
        } else {
          return schema.nullable().notRequired();
        }
      }),
    Location: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.entExpense.fields.location"),
          count: 250,
        })
      )
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.location"),
        })
      ),
    ExpenseType: yup
      .string()
      .trim()
      .nullable()
      .oneOf(
        ["Education", "Training"],
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.expenseType"),
        })
      ),
    Description: yup
      .string()
      .nullable()
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.description"),
        })
      ),
    SourceOfFunds: yup
      .string()
      .trim()
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.entExpense.fields.sourceoffunds"),
          count: 100,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
  };
  if (!isAdding) {
    validationSchema["Classification"] = yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.entExpense.fields.classification"),
        })
      );

    validationSchema["ExpenseAmount"] = yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.rndExpense.fields.expenseamount"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.rndExpense.fields.expenseamount"),
        })
      );
  } else {
    validationSchema["EnTExpenses"] = yup
      .array()
      .required("At least one expense is required")
      .min(
        1,
        t("strGen:validation.morethanone", { fieldname: "Expense", count: 1 })
      )
      .test(
        "EnTExpenses",
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.entExpense.expense"),
        }),
        (arrayValue) => {
          return (
            !!arrayValue &&
            arrayValue.length > 0 &&
            arrayValue.filter(
              (v) =>
                v.classification &&
                (v.amount !== undefined || v.amount !== null)
            ).length === arrayValue.length
          );
        }
      );
  }

  return yup.object().shape(validationSchema);
};
