import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import {
  addClientCompanyStructure,
  fetchClientCompanyStructures,
  delClientCompanyStructure,
} from "./ClientCompanyStructureSlice";
import { fetchCompanyStructures } from "features/datalists/CompanyStructureSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { useTypedTranslation } from "utils/customHooks";
import { IppSaveButton } from "components/Buttons/IppSaveButton";

const PREFIX = "ClientCompanyStructureForm";
const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  isRequired: boolean;
}

export const ClientCompanyStructureForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientCompanyStructuresById,
    clientCompanyStructureList,
    isLoading: isLoadingClientCompanyStructures,
  } = useSelector((state: RootState) => state.clientCompanyStructures);

  const clientCompanyStructures = clientCompanyStructureList.map(
    (id) => clientCompanyStructuresById[id]
  );

  const {
    companyStructuresById,
    companyStructureList,
    isLoading: isLoadingCompanyStructures,
  } = useSelector((state: RootState) => state.companyStructures);

  const companyStructures = companyStructureList.map(
    (id) => companyStructuresById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(fetchCompanyStructures(accessToken));
        dispatch(fetchClientCompanyStructures(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (
      pageLoaded &&
      !isLoadingClientCompanyStructures &&
      !isLoadingCompanyStructures &&
      allGroups.length === 0
    ) {
      initializeList();
    }
  }, [
    pageLoaded,
    isLoadingClientCompanyStructures,
    isLoadingCompanyStructures,
  ]);

  const initializeList = () => {
    const newAllGroups: listValue[] = [];
    //const newSelectedGroups: listValue[] = [];

    companyStructures.forEach((companyStructure) => {
      const foundItem = clientCompanyStructures.find(
        (clientCompanyStructure) => {
          return (
            clientCompanyStructure.CompanyStructureID ===
            companyStructure.CompanyStructureID
          );
        }
      );
      if (!foundItem) {
        newAllGroups.push({
          itemID: companyStructure.CompanyStructureID,
          itemLabel: companyStructure.CompanyStructureName,
          wasSelected: false,
          isRequired: false,
        });
      }
      setAllGroups(newAllGroups);

      let newSelectedGroups = clientCompanyStructures.map(
        (clientCompanyStructure) => {
          let n: listValue = {
            itemID: clientCompanyStructure.CompanyStructureID,
            itemLabel: clientCompanyStructure.CompanyStructureName,
            wasSelected: true,
            isRequired: clientCompanyStructure.IsRequired,
          };
          return n;
        }
      );
      setSelectedGroups(newSelectedGroups);
    });
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });

    allGroups.forEach((group) => {
      if (group.wasSelected) {
        dispatch(delClientCompanyStructure(accessToken, group.itemID));
      }
    });

    // insert new items
    selectedGroups.forEach((group) => {
      if (!group.wasSelected) {
        dispatch(
          addClientCompanyStructure(accessToken, {
            ClientID: clientId,
            CompanyStructureID: group.itemID,
            //IsRequired: group.isRequired,
          })
        );
      }
    });
    dispatch(openSnackBar("Client Company Structures Updated", "success"));
    dispatch(push("/admin/clientCompanyStructures"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  let fullList = isLoadingClientCompanyStructures ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {allGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList = isLoadingClientCompanyStructures ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {selectedGroups.map((item) => {
          return item.isRequired ? (
            <Tooltip title="This is a required item and cannot be removed">
              <ListItem button role="listitem" key={item.itemID}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          ) : (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.itemID}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                {t("objBen:objects.company.fields.ownershipstructure")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select the{" "}
                {t("objBen:objects.company.fields.ownershipstructure")} you wish
                to have appear in dropdown lists. Only the items you select will
                be available to users. Selecting an{" "}
                {t("objBen:objects.company.fields.ownershipstructure")} from one
                list will move it into the other list. Once you have completed
                your changes, click
                <strong> {t("strGen:buttons.savechanges")}</strong> to finalize
                them.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">
                {t("strGen:lookuplist.availableoptions")}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">
                {t("strGen:lookuplist.selectedoptions")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
