import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { addAsset, updAsset, fetchAssets, delAsset } from "./AssetSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
//import { InitiativeValidation } from "./InitiativeValidation";
import { useTypedTranslation } from "utils/customHooks";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { IppTextField } from "components/IppTextField";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { AssetValidationSchema } from "./AssetValidation";
import { IppFormDivider } from "components/IppFormDivider";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { current } from "@reduxjs/toolkit";
import { fetchAssetPercentagesByAsset } from "./AssetPercentageSlice";
import { AssetPercentagePage } from "./AssetPercentagePage";

const PREFIX = "AssetForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxspace: `${PREFIX}-boxspace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    //padding: theme.spacing(2),
    maxWidth: 1150,
  },
  [`& .${classes.boxspace}`]: {
    padding: theme.spacing(1),
  },
}));

export const AssetForm = (props: any) => {
  let assetData = props.asset || {};
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const dispatch = useDispatch();
  const customBP = IsMedSmall();

  const [asset, setAsset] = useState<any>();
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);
  const [isDirty, setIsDirty] = useState(false);
  const [isAdding, setIsAdding] = useState(assetData.AssetID ? false : true);
  const [isEditing, setIsEditing] = useState(assetData.AssetID ? false : true);
  const [activeTab, setActiveTab] = useState(0);

  const showEdit = UserWriteAccess("Benefits");

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );
  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const {
    assetPercentagesById,
    assetPercentageList,
    isLoading: assetPercentageIsLoading,
  } = useSelector((state: RootState) => state.assetPercentages);

  const assetPercentages = assetPercentageList.map(
    (assetPercentageID) => assetPercentagesById[assetPercentageID]
  );

  const { isLoading: companyIsLoading } = useSelector(
    (state: RootState) => state.companies
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        //dispatch(fetchAssets(accessToken));
        dispatch(fetchAssetPercentagesByAsset(accessToken, assetData.AssetID));
        dispatch(fetchCompanies(accessToken, clientId));
        if (assetData.AssetID) {
          const count = await countAttachments(
            accessToken,
            "Assets",
            assetData.AssetID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (
          attachments.length > 0 //&& !isAdding
        ) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === assetData.AssetID &&
              item.RecordType === "Assets"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addAsset(accessToken, values));

        setIsDirty(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(updAsset(accessToken, values.AssetID, values));

          setIsDirty(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //add case
    assetData.AssetName = "";
    assetData.OwnerPercentage = 1.0;
    assetData.AssetDetails = "";
  }

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delAsset(accessToken, assetData.AssetID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: assetData,
    validationSchema: AssetValidationSchema(),
    onSubmit: submitFunc,
  });

  let viewForm = (
    <Box display={"flex"} justifyContent={"center"}>
      <Grid container spacing={1} className={classes.editForm}>
        <Grid item xs={12}>
          <Paper className={classes.boxspace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objBen:objects.asset.name")}
                isEditing={isEditing}
                isAdding={isAdding}
                showReturn={true}
              />
              <IppFormDivider
                title={t("objBen:objects.asset.fields.assetdetails")}
              />

              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objBen:objects.asset.fields.assetname")}
                  value={formik.values.AssetName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objBen:objects.asset.fields.details")}
                  value={formik.values.AssetDetails}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.company.name")}
                  value={currentProfile.CompanyName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.asset.fields.ownerpercentage")}
                  value={
                    (
                      Math.round(formik.values.OwnerPercentage * 1000) / 10
                    ).toFixed(1) + "%"
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  showDelete={showEdit}
                  resetFunction={() => formik.resetForm()}
                  deleteFunction={handleDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt when={isDirty} message={t("strGen:prompts.unsavedchanges")} />
      )}
      <Grid container spacing={1} className={classes.editForm}>
        <IppFormHeader
          title={t("objBen:objects.asset.name")}
          isEditing={isEditing}
          isAdding={isAdding}
          showReturn={true}
        />

        <Grid item xs={12}>
          <IppTextField
            id="AssetName"
            label={t("objBen:objects.asset.fields.assetname")}
            required
            value={formik.values.AssetName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.AssetName}
            errorsExpression={formik.errors.AssetName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="AssetDetails"
            label={t("objBen:objects.asset.fields.details")}
            value={formik.values.AssetDetails}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.AssetDetails}
            errorsExpression={formik.errors.AssetDetails}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <IppFormDivider
          title={"Percentage Details"}
          conditionalText="Your own Percentage is not editable. It's calculated from the partner values"
        />
        <Grid item xs={6}>
          <IppStaticTextfield
            id="CompanyName"
            label={t("objBen:objects.company.name")}
            value={currentProfile.CompanyName}
          />
        </Grid>
        <Grid item xs={6}>
          <IppStaticTextfield
            id="OwnerPercentage"
            label={t("objBen:objects.asset.fields.ownerpercentage")}
            value={formik.values.OwnerPercentage * 100 + "%"}
          />
        </Grid>

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            showDelete={isEditing}
            resetFunction={() => formik.resetForm()}
            deleteFunction={handleDelete}
          />
        </Grid>
      </Grid>
    </form>
  );

  let pageForm =
    clientIsLoading || companyIsLoading || assetPercentageIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent={"center"}>
        <Paper className={classes.boxspace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth={"lg"}>
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`Other Percentages (${assetPercentages.length})`}
                      />
                      <Tab
                        value={1}
                        label={`Attachments (${attachCount.Count})`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <Grid container>
                        <AssetPercentagePage asset={assetData} />
                      </Grid>
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={1}>
                    <Box>
                      <Grid container>
                        <IppAttachmentInventory
                          recordType="Assets"
                          itemID={assetData.AssetID}
                          companyID={currentProfile.CompanyID}
                          projectID={0}
                          moduleID={1}
                        />
                      </Grid>
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root> {pageForm}</Root>;
};
