import React, { useEffect } from "react";
import { IconButton, Menu, MenuItem, Fade, ListItemIcon } from "@mui/material";
import { Apps } from "@mui/icons-material";
import PeopleIcon from "@mui/icons-material/People";
import ChatIcon from "@mui/icons-material/Chat";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { Link } from "react-router-dom";
import { fetchClientModules } from "features/roles/ClientModuleSlice";
import { useTypedTranslation } from "utils/customHooks";

export const AppSelector = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchClientModules(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const iconList = (moduleID: number) => {
    switch (moduleID) {
      case 1:
        return <PeopleIcon />;
      case 2:
        return <AssignmentIcon />;
      case 3:
        return <ChatIcon />;
      case 5:
        return <ReceiptLongIcon />;
      case 6:
        return <HealthAndSafetyIcon />;
      default:
        return null;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Apps />
      </IconButton>
      <Menu
        id="app-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {clientModules.length > 0 ? (
          clientModules.map((item) => (
            <MenuItem
              key={item.ClientModuleID}
              component={Link}
              to={"/" + item.ShortName}
              onClick={(e: any) => {
                handleClose();
              }}
            >
              <ListItemIcon>{iconList(item.ModuleID)}</ListItemIcon>
              {item.ModuleName}
            </MenuItem>
          ))
        ) : (
          <MenuItem>{t("strGen:header.appselector.norolesmessage")}</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default AppSelector;
