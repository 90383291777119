import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import LoadingIndicator from "components/LoadingIndicator";
import { ChangeEvent, createRef, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IppDatePicker } from "components/IppDatePicker";
import {
  fetchVendorActiveEmployeesByCategory,
  fetchVendorActiveEmployeess,
} from "./VendorActiveEmployees";
import { fetchVendorSocioEconEmployeess } from "./VendorSocioEconEmployeesSlice";
import { fetchVendorSocioEconSpends } from "./VendorSocioEconSpendSlice";
import { fetchVendorThirdPartySpends } from "./VendorThirdPartySpendSlice";
import { IppTabPanel } from "components/IppTabPanel";
import { IppSkeleton } from "components/IppSkeleton";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import {
  Grid as KendoGrid,
  GridColumn,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { IppExportButton } from "components/Buttons/IppExportButton";
import { IppAutocomplete } from "components/IppAutocomplete";
import { useTypedTranslation } from "utils/customHooks";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";

export const VendorInsightsPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "strGen"]);

  const {
    ft_ben_SpendReport,
    isLoading: clientIsLoading,
    ft_ben_Region,
    ft_ben_CustomEmpCategory,
  } = useSelector((state: RootState) => state.client);

  const [activeTab, setActiveTab] = useState(0);
  let dt = new Date();
  let st = new Date(dt.getFullYear(), dt.getMonth() - 3, 1);
  let nd = new Date(dt.getFullYear(), dt.getMonth() + 0, 0);
  const [startDate, setStartDate] = useState(st);
  const [endDate, setEndDate] = useState(nd);
  const [projectRegionID, setProjectRegionID] = useState(0);
  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);
  const regionOptions = [
    {
      RegionID: 0,
      RegionName: t("strGen:components.all"),
    },
    ...regions,
  ];

  const {
    vendorActiveEmployeesList,
    vendorActiveEmployeessById,
    vendorActiveEmployeesByCategoryList,
    vendorActiveEmployeesByCategoryById,
    isLoading: vacIsLoading,
  } = useSelector((state: RootState) => state.vendorActiveEmployees);

  const vendorActiveEmployees = vendorActiveEmployeesList.map(
    (id) => vendorActiveEmployeessById[id]
  );
  const vendorActiveEmployeesByCategory =
    vendorActiveEmployeesByCategoryList.map(
      (id) => vendorActiveEmployeesByCategoryById[id]
    );

  const {
    vendorSocioEconEmployeesList,
    vendorSocioEconEmployeessById,
    isLoading: vseIsLoading,
  } = useSelector((state: RootState) => state.vendorSocioEconEmployees);

  const vendorSocioEconEmployees = vendorSocioEconEmployeesList.map(
    (id) => vendorSocioEconEmployeessById[id]
  );

  const socEmp = vendorSocioEconEmployees.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.indigenousEmployees != null && p.TotalEmployees != null) {
      n.EmployeeResult = p.indigenousEmployees / p.TotalEmployees;
    } else {
      n.EmployeeResult = 0;
    }

    if (p.IndigenousHours != null && p.TotalHours != null) {
      n.HoursResult = p.IndigenousHours / p.TotalHours;
    } else {
      n.HoursResult = 0;
    }

    return n;
  });

  const {
    vendorSocioEconSpendList,
    vendorSocioEconSpendsById,
    isLoading: vssIsLoading,
  } = useSelector((state: RootState) => state.vendorSocioEconSpend);

  const vendorSocioEconSpend = vendorSocioEconSpendList.map(
    (id) => vendorSocioEconSpendsById[id]
  );

  const socioSpendData = vendorSocioEconSpend.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.TotalSpend > 0 && p.IndigenousSpend > 0) {
      n.Percentage = p.IndigenousSpend / p.TotalSpend;
    } else {
      n.Percentage = 0.0;
    }

    return n;
  });

  const {
    vendorThirdPartySpendList,
    vendorThirdPartySpendsById,
    isLoading: vtpIsLoading,
  } = useSelector((state: RootState) => state.vendorThirdPartySpend);

  const vendorThirdPartySpend = vendorThirdPartySpendList.map(
    (id) => vendorThirdPartySpendsById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          fetchVendorActiveEmployeess(accessToken, startDate, endDate, 0)
        );
        if (ft_ben_CustomEmpCategory) {
          dispatch(
            fetchVendorActiveEmployeesByCategory(
              accessToken,
              startDate,
              endDate,
              0
            )
          );
        }
        dispatch(
          fetchVendorSocioEconEmployeess(
            accessToken,
            startDate,
            endDate,
            projectRegionID
          )
        );
        dispatch(
          fetchVendorSocioEconSpends(
            accessToken,
            startDate,
            endDate,
            projectRegionID
          )
        );
        dispatch(
          fetchVendorThirdPartySpends(accessToken, startDate, endDate, 0)
        );
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Project"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch, startDate, endDate, projectRegionID]);

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const labelContent = (props: {
    dataItem: { value: any; IndigenousGroupName: any };
  }) => {
    return `${props.dataItem.IndigenousGroupName}`;
  };
  const labelCategoryContent = (props: {
    dataItem: { value: any; CustomEmpCategoryName: any };
  }) => {
    return `${props.dataItem.CustomEmpCategoryName}`;
  };

  const _exportSpend = createRef<ExcelExport>();
  const _exportHours = createRef<ExcelExport>();
  const _exportThirdParty = createRef<ExcelExport>();
  const _exportActive = createRef<ExcelExport>();

  let insightsForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box padding={1}>
              <Typography variant="h4" component="h1">
                Insights
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper>
            <Box padding={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Data Selection</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Please use the selectors below to select the date and region
                    range.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <IppDatePicker
                    id="StartDate"
                    label="Select Start Date"
                    required={true}
                    disableFuture={true}
                    onChangeFunction={(newValue: any) => {
                      setStartDate(newValue);
                    }}
                    isEditing={true}
                    setIsEditing={null}
                    value={startDate}
                    // monthOnly={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IppDatePicker
                    id="EndDate"
                    label="Select End Date"
                    required={true}
                    disableFuture={true}
                    onChangeFunction={(newValue: any) => {
                      setEndDate(newValue);
                    }}
                    isEditing={true}
                    setIsEditing={null}
                    value={endDate}
                  />
                </Grid>
                {hasRegionTypes && (
                  <Grid item xs={12}>
                    <IppAutocomplete
                      id="RegionID"
                      options={regionOptions}
                      value={regions.find((obj) => {
                        return obj.RegionID === projectRegionID;
                      })}
                      placeholder={t("strGen:components.all")}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          setProjectRegionID(newValue.RegionID);
                        } else {
                          setProjectRegionID(0);
                        }
                      }}
                      label={t(
                        "objBen:objects.company.fields.region.projectRegion"
                      )}
                      required={true}
                      isEditing={true}
                      setIsEditing={() => {}}
                      optionLabelFunction={(option: any) => option.RegionName}
                      toolTip={`Filter Expenditure and Employment by ${t(
                        "objBen:objects.company.fields.region.projectRegion"
                      )}`}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper>
            <Box padding={1}>
              <Tabs
                value={activeTab}
                onChange={handleTabSelect}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab value={0} label="Socioeconomic Performance" />
                <Tab value={1} label="Active Employees" />
                {ft_ben_SpendReport && (
                  <Tab value={2} label="Third Party Spend" />
                )}
              </Tabs>

              <IppTabPanel value={activeTab} index={0}>
                {vseIsLoading || vssIsLoading ? (
                  <IppSkeleton height={300} />
                ) : (
                  <Grid container spacing={2}>
                    {ft_ben_SpendReport && (
                      <Grid item xs={12}>
                        <ExcelExport data={socioSpendData} ref={_exportSpend}>
                          <KendoGrid data={socioSpendData}>
                            <GridToolbar>
                              <Grid container>
                                <Grid item xs={8}>
                                  <Typography variant="h6">
                                    Expenditures
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Grid container justifyContent="flex-end">
                                    <IppExportButton
                                      exporter={_exportSpend}
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </GridToolbar>
                            <GridColumn
                              field="ContractNumber"
                              title="Contract"
                            />
                            <GridColumn field="ContractTitle" title="Title" />
                            <GridColumn
                              field="IndigenousSpend"
                              title="Indigenous Spend"
                              format="{0:c0}"
                            />
                            <GridColumn
                              field="TotalSpend"
                              title="Total Spend"
                              format="{0:c0}"
                            />
                            <GridColumn
                              field="Percentage"
                              title="Result"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ContractTarget"
                              title="Contract Target"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ProjectTarget"
                              title="Project Target"
                              format="{0:p0}"
                            />
                          </KendoGrid>
                        </ExcelExport>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <ExcelExport data={socEmp} ref={_exportHours}>
                        <KendoGrid data={socEmp}>
                          <GridToolbar>
                            <Grid container>
                              <Grid item xs={8}>
                                <Typography variant="h6">Employment</Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Grid container justifyContent="flex-end">
                                  <IppExportButton
                                    exporter={_exportHours}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </GridToolbar>
                          <GridColumn field="ContractNumber" title="Contract" />
                          <GridColumn field="ContractTitle" title="Title" />
                          <GridColumn title="Employees">
                            <GridColumn
                              field="indigenousEmployees"
                              title="Indigenous"
                              format="{0:n0}"
                            />
                            <GridColumn
                              field="TotalEmployees"
                              title="Total"
                              format="{0:n0}"
                            />
                            <GridColumn
                              field="EmployeeResult"
                              title="Result"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ContractEmployeeTarget"
                              title="Contract Target"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ProjectEmployeeTarget"
                              title="Project Target"
                              format="{0:p0}"
                            />
                          </GridColumn>
                          <GridColumn title="Hours">
                            <GridColumn
                              field="IndigenousHours"
                              title="Indigenous"
                              format="{0:n0}"
                            />
                            <GridColumn
                              field="TotalHours"
                              title="Total"
                              format="{0:n0}"
                            />
                            <GridColumn
                              field="HoursResult"
                              title="Result"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ContractHoursTarget"
                              title="Contract Target"
                              format="{0:p0}"
                            />
                            <GridColumn
                              field="ProjectHoursTarget"
                              title="Project Target"
                              format="{0:p0}"
                            />
                          </GridColumn>
                        </KendoGrid>
                      </ExcelExport>
                    </Grid>
                  </Grid>
                )}
              </IppTabPanel>
              <IppTabPanel value={activeTab} index={1}>
                {vacIsLoading ? (
                  <IppSkeleton height={300} />
                ) : (
                  <Grid container>
                    <Grid container item spacing={2}>
                      <Grid item xs={6}>
                        <ExcelExport
                          data={vendorActiveEmployees}
                          ref={_exportActive}
                        >
                          <KendoGrid data={vendorActiveEmployees}>
                            <GridToolbar>
                              <Grid container>
                                <Grid item xs={8}>
                                  <Typography variant="h6">
                                    Active Employees
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Grid container justifyContent="flex-end">
                                    <IppExportButton
                                      exporter={_exportActive}
                                      variant="outlined"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </GridToolbar>
                            <GridColumn
                              field="IndigenousGroupName"
                              title="Affiliation"
                            />
                            <GridColumn
                              field="EmployeeCount"
                              title="Employees"
                              format="{0:n0}"
                            />
                          </KendoGrid>
                        </ExcelExport>
                      </Grid>
                      <Grid item xs={6}>
                        <Chart style={{ height: 350 }}>
                          <ChartTitle text="Employees by Affiliation" />

                          <ChartLegend visible={false} />

                          <ChartSeries>
                            <ChartSeriesItem
                              data={vendorActiveEmployees}
                              type="pie"
                              field="EmployeeCount"
                              categoryField="IndigenousGroupName"
                              labels={{
                                visible: true,
                                content: labelContent,
                                align: "column",
                              }}
                              tooltip={{ visible: true, format: "{0:n0}" }}
                            />
                          </ChartSeries>
                        </Chart>
                      </Grid>
                    </Grid>
                    {ft_ben_CustomEmpCategory && (
                      <Grid container item spacing={2}>
                        <Grid item xs={6}>
                          <ExcelExport
                            data={vendorActiveEmployeesByCategory}
                            ref={_exportActive}
                          >
                            <KendoGrid data={vendorActiveEmployeesByCategory}>
                              <GridToolbar>
                                <Grid container>
                                  <Grid item xs={8}>
                                    <Typography variant="h6">
                                      {`Active ${t(
                                        "objBen:objects.employee.name_other"
                                      )} By
                                      ${t(
                                        "objBen:objects.employee.fields.customCategory"
                                      )}`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Grid container justifyContent="flex-end">
                                      <IppExportButton
                                        exporter={_exportActive}
                                        variant="outlined"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </GridToolbar>
                              <GridColumn
                                field="CustomEmpCategoryName"
                                title={t(
                                  "objBen:objects.employee.fields.customCategory"
                                )}
                              />
                              <GridColumn
                                field="EmployeeCount"
                                title={t("objBen:objects.employee.name_other")}
                                format="{0:n0}"
                              />
                            </KendoGrid>
                          </ExcelExport>
                        </Grid>
                        <Grid item xs={6}>
                          <Chart style={{ height: 350 }}>
                            <ChartTitle
                              text={`${t(
                                "objBen:objects.employee.name_other"
                              )} by ${t(
                                "objBen:objects.employee.fields.customCategory"
                              )}`}
                            />

                            <ChartLegend visible={false} />

                            <ChartSeries>
                              <ChartSeriesItem
                                data={vendorActiveEmployeesByCategory}
                                type="pie"
                                field="EmployeeCount"
                                categoryField="CustomEmpCategoryName"
                                labels={{
                                  visible: true,
                                  content: labelCategoryContent,
                                  align: "column",
                                }}
                                tooltip={{ visible: true, format: "{0:n0}" }}
                              />
                            </ChartSeries>
                          </Chart>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </IppTabPanel>
              {ft_ben_SpendReport && (
                <IppTabPanel value={activeTab} index={2}>
                  {vtpIsLoading ? (
                    <IppSkeleton height={300} />
                  ) : (
                    <ExcelExport
                      data={vendorThirdPartySpend}
                      ref={_exportThirdParty}
                    >
                      <KendoGrid data={vendorThirdPartySpend}>
                        <GridToolbar>
                          <Grid container>
                            <Grid item xs={8}>
                              <Typography variant="h6">
                                Third Party Spend
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid container justifyContent="flex-end">
                                <IppExportButton
                                  exporter={_exportThirdParty}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </GridToolbar>
                        <GridColumn field="CompanyName" title="Third Party" />
                        <GridColumn
                          field="IndigenousGroupName"
                          title="Affiliation"
                        />
                        <GridColumn field="TierName" title="Tier" />
                        <GridColumn field="Spend" format="{0:c0}" />
                      </KendoGrid>
                    </ExcelExport>
                  )}
                </IppTabPanel>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );

  return <div id="vendor-insights-form">{insightsForm}</div>;
};
