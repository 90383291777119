import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchWorkHourPeriods } from "./WorkHourPeriodSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

interface workHourPeriodInvProps {
  companyID?: number;
}

export const WorkHourPeriodInventoryPage = (props: workHourPeriodInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen"]);

  const {
    workHourPeriodList,
    isLoading,
    error: workHourPeriodsError,
    workHourPeriodsById,
  } = useSelector((state: RootState) => state.workHourPeriods);

  const workHourPeriods = workHourPeriodList.map(
    (id) => workHourPeriodsById[id]
  );

  const whp = workHourPeriods.map((p) => {
    let n = Object.assign({}, p) as any;

    n.StartDate = ConvertDateOffset(p.StartDate);
    n.EndDate = ConvertDateOffset(p.EndDate);

    n.TotalHours = p.RegHours + p.AltHours2 + p.AltHours3;

    return n;
  });

  const dataColumns = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "numeric",
      format: "W-{0:0}",
      columnWidth: 80,
    },
    { field: "CompanyName", title: "Company", filter: "text" },
    { field: "ProjectName", title: "Project" },
    { field: "ProjectComponentName", title: "Component" },
    {
      field: "StartDate",
      title: "Start Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "EndDate",
      title: "End Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "EmployeeCount",
      title: "Employees",
      filter: "numeric",
      format: "{0:n0}",
      columnWidth: 125,
    },
    {
      field: "TotalHours",
      title: t("objBen:objects.workhour.period.totalhours"),
      filter: "numeric",
      // format to not show decimals here
      format: "{0:n0}",
      columnWidth: 125,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchWorkHourPeriods(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (workHourPeriodsError) {
    return <ErrorPage errMsg={workHourPeriodsError} />;
  }

  let workHourPeriodInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Work-Hour Periods"
        tableData={whp}
        idField="PeriodID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        showAdd={true}
        showTemplate={false}
        sortColumn={4}
        sortReverse={true}
        searchByColumn={"CompanyName"}
        searchByPlaceholder={"Search by Company"}
      />
    </div>
  );

  return <div id="workHourPeriod-inventory-page">{workHourPeriodInvView}</div>;
};
