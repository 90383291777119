import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";
import { addEnTExpense, updEnTExpense } from "./EnTExpenseSlice";
import { useFormik } from "formik";
import { EnTExpenseValidation } from "./EnTExpenseValidation";
import LoadingIndicator from "components/LoadingIndicator";
import { Grid, Typography } from "@mui/material";
import { IppAutocomplete } from "components/IppAutocomplete";
import { classifications } from "features/datalists/Classification";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppDatePicker } from "components/IppDatePicker";
import { ConvertDateOffset, GetJSDate, GetJSYear } from "utils/DateFunctions";
import { IppTextField } from "components/IppTextField";
import { IppCheckbox } from "components/IppCheckbox";
import { IppFormDivider } from "components/IppFormDivider";
import { IppRnDClassifications } from "components/IppRnDClassifications";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

interface EnTExpenseChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  parentID: number; // if called from child grid, init parent value
  expense?: any; // existing expense to be edited
}

export const EnTExpenseChildForm = (props: EnTExpenseChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const [canSave] = useState(false);
  const [isEditing, setIsEditing] = useState(true);

  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentValue,
    parentID,
    expense,
  } = props;

  const {
    isLoading: clientIsLoading,
    ft_ben_AllocatedDate,
    ft_ben_Asset,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  // no need to fetch these as they will exist from the parent form

  const { assetsById, assetList } = useSelector(
    (state: RootState) => state.assets
  );
  const assets = assetList.map((id) => assetsById[id]);

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((id) => companiesById[id]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);

  let expenseData: any;

  if (expense) {
    expenseData = expense;
  } else {
    expenseData = {
      InitiativeID: parentTitle === "Initiative" ? parentID : null,
      CourseName: parentValue.InitiativeName,
      ExpenseAmount: 0,
      Classification: "",
      Date: null,
      CompanyID: parentValue.CompanyID, // always taken from parent that is required
      AssetID: parentValue.AssetID, // null if parent is null
      Applied: false,
      AppliedDate: null,
      SourceOfFunds: parentValue.Owner,
      EnTExpenses: [],
      ExpenseType: null,
    };
  }

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (expense) {
          dispatch(
            updEnTExpense(accessToken, values.EnTExpenseID, values, false)
          );
        } else {
          dispatch(addEnTExpense(accessToken, values, false));
        }
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: expenseData,
    validationSchema: EnTExpenseValidation(!expense),
    onSubmit: onSub,
  });

  // Expense Type Options, todo: get from BE in the future if neccessary
  const expenseTypeOptions = [
    { id: 0, value: t("objBen:objects.entExpense.education") },
    { id: 1, value: t("objBen:objects.entExpense.training") },
  ];

  let detailForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        {expense && isEditing && (
          <Grid item xs={12}>
            <Typography variant="h6">Edit E&T Expense</Typography>
          </Grid>
        )}
        {expense && (
          <>
            <Grid item xs={6}>
              <IppAutocomplete
                id="Classification"
                options={classifications}
                label={t("objBen:objects.entExpense.fields.classification")}
                value={formik.values.Classification}
                required={true}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onChangeFunction={(_: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("Classification", newValue);
                  } else {
                    formik.setFieldValue("Classification", null);
                  }
                }}
                errorFunction={formik.errors.Classification}
                touchedFunction={formik.touched.Classification}
                optionLabelFunction={(option: any) => option}
              />
            </Grid>
            <Grid item xs={6}>
              <IppCurrencyField
                id="ExpenseAmount"
                label={t("objBen:objects.rndExpense.fields.expenseamount")}
                required
                value={formik.values.ExpenseAmount}
                onChangeFunction={(newValue) =>
                  formik.setFieldValue("ExpenseAmount", newValue)
                }
                touchedExpression={formik.touched.ExpenseAmount}
                errorsExpression={formik.errors.ExpenseAmount}
                isEditing={isEditing}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <IppDatePicker
            id="Date"
            label={t("objBen:objects.rndExpense.fields.expensedate")}
            required={true}
            value={ConvertDateOffset(formik.values.Date)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("Date", GetJSDate(newValue), true);
              formik.setFieldTouched("Date", true, false);
            }}
            errorsExpression={formik.errors.Date}
            touchedExpression={formik.touched.Date}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableFuture={true}
            toolTip="The date when the Expense was created or paid for."
          />
        </Grid>
        <Grid item xs={6}>
          <IppTextField
            id="CourseName"
            label={t("objBen:objects.entExpense.fields.course")}
            required={true}
            value={formik.values.CourseName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CourseName}
            errorsExpression={formik.errors.CourseName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        {ft_ben_Asset && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="AssetID"
              options={assets}
              value={assets.find((obj) => {
                return obj.AssetID === formik.values.AssetID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("AssetID", newValue.AssetID);
                } else {
                  formik.setFieldValue("AssetID", null);
                  formik.setFieldValue("Applied", false);
                }
              }}
              label={t("objBen:objects.asset.name")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.AssetName}
              errorFunction={formik.errors.AssetID}
              touchedFunction={formik.touched.AssetID}
              textValueFunction={formik.values.AssetName}
              placeholder="What asset was this expense applied to?"
            />
          </Grid>
        )}
        {/* expense type */}
        <Grid item xs={6}>
          <IppAutocomplete
            id="ExpenseType"
            options={expenseTypeOptions}
            value={expenseTypeOptions.find((obj) => {
              return obj.value === formik.values.ExpenseType;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ExpenseType", newValue.value);
              } else {
                formik.setFieldValue("ExpenseType", null);
              }
            }}
            label={t("objBen:objects.entExpense.fields.expenseType")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.value}
            errorFunction={formik.errors.ExpenseType}
            touchedFunction={formik.touched.ExpenseType}
            textValueFunction={formik.values.ExpenseType?.value}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="CompanyID"
            required
            options={confirmedCompanies.sort((a, b) =>
              a.ShowAsActive.localeCompare(b.ShowAsActive)
            )}
            groupBy={(option: any) => option.ShowAsActive}
            value={confirmedCompanies.find((obj) => {
              return obj.CompanyID === formik.values.CompanyID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("CompanyID", newValue.CompanyID);
              } else {
                formik.setFieldValue("CompanyID", null);
              }
            }}
            label={t("objBen:objects.company.name")}
            isEditing={currentProfile.IsClient && isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CompanyName}
            renderOption={(props: any, option: Company) => {
              return CompanyOptionsRender(props, option);
            }}
            placeholder="Paid by"
            touchedFunction={formik.touched.CompanyID}
            errorFunction={formik.errors.CompanyID}
            textValueFunction={
              !companyIsLoading &&
              formik.values.CompanyID > 0 &&
              companiesById[formik.values.CompanyID]
                ? companiesById[formik.values.CompanyID].CompanyName
                : ""
            }
          />
        </Grid>
        {currentProfile.IsClient && (
          <Grid item xs={6}>
            <IppTextField
              id="SourceOfFunds"
              label={t("objBen:objects.rndExpense.fields.sourceoffunds")}
              value={formik.values.SourceOfFunds}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.SourceOfFunds}
              errorsExpression={formik.errors.SourceOfFunds}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        {ft_ben_Asset && (
          <>
            <Grid item xs={6}>
              <IppCheckbox
                id="Applied"
                label={t("objBen:objects.entExpense.fields.applied")}
                value={formik.values.Applied}
                onChangeFunction={formik.handleChange}
                isEditing={isEditing}
                disabled={
                  !(formik.values.CompanyID > 0 && formik.values.AssetID > 0)
                }
              />
            </Grid>
            {formik.values.Applied && (
              <Grid item xs={6}>
                <IppDatePicker
                  id="AppliedDate"
                  label={t("objBen:objects.entExpense.fields.applieddate")}
                  required={true}
                  value={ConvertDateOffset(formik.values.AppliedDate)}
                  onChangeFunction={(newValue: any) => {
                    formik.setFieldValue(
                      "AppliedDate",
                      ft_ben_AllocatedDate
                        ? GetJSYear(newValue, 1, 2)
                        : GetJSDate(newValue),
                      true
                    );
                    formik.setFieldTouched("AppliedDate", true, false);
                  }}
                  errorsExpression={formik.errors.AppliedDate}
                  touchedExpression={formik.touched.AppliedDate}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  disableFuture={true}
                  yearOnly={ft_ben_AllocatedDate}
                  disabled={!formik.values.Applied}
                />
              </Grid>
            )}
          </>
        )}
        {!expense && (
          <Grid xs={12} item>
            <IppFormDivider title="Detailed Expense Classifications" />
            <IppRnDClassifications
              id="EnTExpenses"
              value={formik.values.EnTExpenses}
              onChangeFunction={(item: any) => {
                formik.setFieldValue("EnTExpenses", item);
              }}
              touchedFunction={formik.touched.EnTExpenses}
              label={t("objBen:objects.entExpense.tooltips.expenselist")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={!expense}
            submitDisabled={canSave}
            showCancel={true}
            resetFunction={closeAction}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="expense-child-form">{detailForm}</div>;
};
