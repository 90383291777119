import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import { fetchWorkHourPeriodExceptions } from "./WorkHourPeriodExceptionSlice";
import { IppSkeleton } from "components/IppSkeleton";

interface WorkHourReportingStatusForm {
  instanceId?: string;
}

export const WorkHourReportingStatusForm = (
  props: WorkHourReportingStatusForm
) => {
  const { instanceId } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { location } = useSelector((state: RootState) => state.router);

  const {
    workHourPeriodExceptionList,
    isLoading: periodIsLoading,
    error: periodError,
  } = useSelector((state: RootState) => state.workHourPeriodExceptions);

  // This slice isn't mapped by ID so the List can be used directly
  const workhours = workHourPeriodExceptionList;

  const dataColumns = [
    { field: "CompanyName", title: "Company" },
    { field: "ProjectName", title: "Project" },
    { field: "LastReport", title: "Last Hours" },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchWorkHourPeriodExceptions(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (periodError) {
    return <ErrorPage errMsg={periodError} />;
  }

  let workHourForm = periodIsLoading ? (
    <IppSkeleton height={500} />
  ) : (
    <div>
      <IppInventory
        title="Work-Hour Reports Outstanding"
        tableData={workhours}
        idField="CompanyID"
        columns={dataColumns}
        isFiltered={true}
        showDetail={true}
        linkColumn={0}
        route={`${location.pathname}/companies`}
        showAdd={false}
        showUpload={false}
        showTemplate={false}
        sortColumn={2}
        instanceId={instanceId}
      />
    </div>
  );

  return <div id="workhour-review-page">{workHourForm}</div>;
};
