import { Box, Grid, Paper, Typography } from "@mui/material";
import LoadingIndicator from "components/LoadingIndicator";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { NewsFeedPage } from "./NewsFeedPage";
import { useEffect } from "react";
import { fetchEngagementTiles } from "./EngagementTileSlice";
import IppShadowBox from "components/IppShadowBox";
import { useAuth0 } from "@auth0/auth0-react";
import { EngagementHealthChart } from "./EngagementHealthChart";
import { EngagementLevelChart } from "./EngagementLevelChart";
import { useTypedTranslation } from "utils/customHooks";

export const StakeholderHomePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objStk", "objPlt", "strGen"]);

  const { ft_eng_Grievance, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { engagementTileList, engagementTilesById } = useSelector(
    (state: RootState) => state.engagementTiles
  );

  const tileData = engagementTileList.map((id) => engagementTilesById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEngagementTiles(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let pageView = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box padding={1}>
              <Typography variant="h5" component="h1">
                {t("strGen:pages.home.engagement.title")}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <IppShadowBox
            title={t(
              "strGen:pages.home.engagement.shadowboxes.inreviecommunications",
              { count: 2 }
            )}
            value={
              tileData.length > 0
                ? tileData[0].IncompleteInteractions.toString()
                : ".."
            }
            pageName="/engagement/communications/review"
            filter={null}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <IppShadowBox
            title={t(
              "strGen:pages.home.engagement.shadowboxes.overduecommunications",
              { count: 2 }
            )}
            value={
              tileData.length > 0
                ? tileData[0].OverdueInteractions.toString()
                : ".."
            }
            colorSet="red"
            pageName="/engagement/communications"
            filter={{
              filters: [
                {
                  logic: "or",
                  filters: [
                    {
                      field: "StatusTypeName",
                      operator: "eq",
                      value: "Overdue",
                    },
                  ],
                },
              ],
              logic: "and",
            }}
          />
        </Grid>
        {ft_eng_Grievance ? (
          <Grid item xs={6} md={3}>
            <IppShadowBox
              title={t(
                "strGen:pages.home.engagement.shadowboxes.opengrievances",
                { count: 2 }
              )}
              value={
                tileData.length > 0
                  ? tileData[0].UnresolvedGrievances.toString()
                  : ".."
              }
              colorSet="blue"
              pageName="/engagement/grievances"
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "GrievanceStatusText",
                        operator: "neq",
                        value: "Completed",
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={6} md={3}>
            <IppShadowBox
              title={t("strGen:pages.home.engagement.shadowboxes.openactions", {
                count: 2,
              })}
              value={
                tileData.length > 0 ? tileData[0].OpenActions.toString() : ".."
              }
              colorSet="blue"
              pageName="/engagement/communicationactions"
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objStk:objects.interactionaction.statustype.notstarted"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objStk:objects.interactionaction.statustype.inprogress"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          </Grid>
        )}
        {ft_eng_Grievance ? (
          <Grid item xs={6} md={3}>
            <IppShadowBox
              title={t(
                "strGen:pages.home.engagement.shadowboxes.resolvedgrievances",
                { count: 2 }
              )}
              value={
                tileData.length > 0
                  ? tileData[0].ResolvedGrievances.toString()
                  : ".."
              }
              colorSet="green"
              pageName="/engagement/grievances"
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "GrievanceStatusText",
                        operator: "eq",
                        value: "Completed",
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={6} md={3}>
            <IppShadowBox
              title={t(
                "strGen:pages.home.engagement.shadowboxes.overdueactions",
                {
                  count: 2,
                }
              )}
              value={
                tileData.length > 0
                  ? tileData[0].OverdueActions.toString()
                  : ".."
              }
              colorSet="green"
              pageName="/engagement/communicationactions"
              filter={{
                filters: [
                  {
                    logic: "and",
                    filters: [
                      {
                        field: "RequiredDate",
                        operator: "lt",
                        value: new Date(),
                      },
                    ],
                  },
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objStk:objects.interactionaction.statustype.notstarted"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objStk:objects.interactionaction.statustype.inprogress"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <IppShadowBox
            title={t(
              "strGen:pages.home.engagement.shadowboxes.engagementhealth.title"
            )}
            component={<EngagementHealthChart />}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <IppShadowBox
            title={t(
              "strGen:pages.home.engagement.shadowboxes.engagementlevel.title"
            )}
            component={<EngagementLevelChart />}
          />
        </Grid>
        <Grid item xs={12}>
          <IppShadowBox
            title={t("strGen:pages.home.engagement.newsfeed")}
            component={<NewsFeedPage />}
          />
        </Grid>
      </Grid>
    </div>
  );

  return <div id="stakeholder-home">{pageView}</div>;
};
