import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchUsers } from "./UsersSlice";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import LoadingIndicator from "components/LoadingIndicator";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import { useTypedTranslation } from "utils/customHooks";

interface ULProps {}

export const UsersListPage = ({}: ULProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "objBen", "strGen"]);

  //const compID = 4;

  const {
    userList,
    isLoading,
    error: usersError,
    usersById,
  } = useSelector((state: RootState) => state.users);

  const {
    companiesById,
    companyList,
    error: companyError,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentCompany, isLoading: currentCompIsLoading } = useSelector(
    (state: RootState) => state.currentCompany
  );

  const users = userList.map((id) => usersById[id]);

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchUsers(accessToken, clientId));
          dispatch(fetchCompanies(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, currentCompany, dispatch, getAccessTokenSilently]);

  if (usersError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{usersError.toString()}</div>
      </div>
    );
  }

  //console.log("USERS", users);
  const newList = users.map((p) => {
    let n = Object.assign({}, p) as any;
    //console.log("N", n);
    if (!companyIsLoading && companiesById[n.CompanyID]) {
      n.CompanyName = companiesById[n.CompanyID].CompanyName;
    }
    let pd = new Date(p.LastActive);
    n.LastActive = pd;
    return n;
  });

  const dataColumns = [
    { field: "Surname", title: t("objPlt:objects.user.fields.surname") },
    { field: "FirstName", title: t("objPlt:objects.user.fields.firstname") },
    { field: "CompanyName", title: t("objBen:objects.company.name") },
    {
      field: "EmailAddress",
      title: t("objPlt:objects.user.fields.emailaddress"),
    },
    {
      field: "IsActive",
      title: t("objPlt:objects.user.fields.isactive"),
      filter: "boolean",
    },
    {
      field: "LastActive",
      title: t("objPlt:objects.user.fields.lastonline"),
      filter: "date",
      format: "{0:g}",
    },
  ];

  if (usersError) {
    return <ErrorPage errMsg={usersError} />;
  }

  let renderedList =
    isLoading || clientIsLoading || currentCompIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.user.name", { count: 2 })}
          tableData={newList}
          idField="UserAccountID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showTemplate={false}
          searchByColumn={"Surname"}
          searchByPlaceholder={t("strGen:filters.searchby", {
            fieldname: t("objPlt:objects.user.fields.surname"),
          })}
        />
      </div>
    );

  return <div id="user-list-page">{renderedList}</div>;
};
