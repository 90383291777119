import { Close, Edit, Expand } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { SanitizeHTML } from "utils/customXSS";
import { IppFormDivider } from "./IppFormDivider";
import { IppButton } from "./Buttons/IppButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppRichTextProps {
  label: string;
  value: any;
  isEditing: boolean;
  setIsEditing: any;
  showTitle?: boolean;
  showEdit?: boolean;
  height?: string | number;
}

export const IppDisplayRichText = (props: IppRichTextProps) => {
  const { label, value, isEditing, setIsEditing, showTitle, showEdit, height } =
    props;
  const t = useTypedTranslation(["strGen"]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const editChange = () => {
    setIsEditing(!isEditing);
  };

  const sanitizedHTML = SanitizeHTML(value);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const onCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs>
          <IppFormDivider title={label} />
        </Grid>

        {value && showTitle && (
          <Grid item width={180}>
            <IppButton
              onClick={openDialog}
              size="small"
              startIcon={<Expand />}
              variant="text"
              sx={{
                fontSize: "0.7rem",
              }}
              noTimeout
            >
              {t("strGen:buttons.clicktoexpand")}
            </IppButton>
          </Grid>
        )}

        {showEdit && (
          <Grid item width={30}>
            <IconButton onClick={editChange} size="small">
              <Edit fontSize="inherit" />
            </IconButton>
          </Grid>
        )}

        <Grid item xs={12}>
          {showTitle ? (
            <div
              className="div-scroll"
              style={{
                height: height,
                overflow: "auto",
                whiteSpace: "pre-wrap",
              }}
            >
              <Typography variant="caption">
                <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
              </Typography>
            </div>
          ) : (
            <div
              style={{
                height: height,
                overflow: "auto",
                whiteSpace: "pre-wrap",
              }}
              dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            />
          )}
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={onCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </DialogContent>
        <DialogActions>
          <IppCancelButton
            buttonText={t("strGen:buttons.close")}
            onClick={onCloseDialog}
            startIcon={<Close />}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

IppDisplayRichText.defaultProps = {
  showTitle: true,
};
