import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  VendorSocioEconEmployees,
  VendorSocioEconEmployeesResult,
  getVendorSocioEconEmployees,
} from "api/benefits/vendorInsights/vendorSocioEconEmployeesAPI";
import { AppThunk } from "app/store";

interface VendorSocioEconEmployeesState {
  vendorSocioEconEmployeessById: Record<number, VendorSocioEconEmployees>;
  vendorSocioEconEmployeesList: number[];
  isLoading: boolean;
  error: string | null;
}

const VendorSocioEconEmployeesInitialState: VendorSocioEconEmployeesState = {
  vendorSocioEconEmployeessById: {},
  vendorSocioEconEmployeesList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: VendorSocioEconEmployeesState) {
  state.isLoading = true;
}

function loadingFailed(
  state: VendorSocioEconEmployeesState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const vendorSocioEconEmployeess = createSlice({
  name: "vendorSocioEconEmployees",
  initialState: VendorSocioEconEmployeesInitialState,
  reducers: {
    getVendorSocioEconEmployeessStart: startLoading,
    getVendorSocioEconEmployeessSuccess(
      state,
      { payload }: PayloadAction<VendorSocioEconEmployeesResult>
    ) {
      const { vendorSocioEconEmployees } = payload;
      state.isLoading = false;
      state.error = null;

      vendorSocioEconEmployees.forEach((vendorSocioEconEmployees) => {
        state.vendorSocioEconEmployeessById[
          vendorSocioEconEmployees.ContractID
        ] = vendorSocioEconEmployees;
      });

      state.vendorSocioEconEmployeesList = vendorSocioEconEmployees.map(
        (vendorSocioEconEmployees) => vendorSocioEconEmployees.ContractID
      );
    },
    getVendorSocioEconEmployeessFailure: loadingFailed,
  },
});

export const {
  getVendorSocioEconEmployeessStart,
  getVendorSocioEconEmployeessSuccess,
  getVendorSocioEconEmployeessFailure,
} = vendorSocioEconEmployeess.actions;

export default vendorSocioEconEmployeess.reducer;

export const fetchVendorSocioEconEmployeess =
  (
    accessToken: String,
    startDate: Date,
    endDate: Date,
    regionID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getVendorSocioEconEmployeessStart());
      const vendorSocioEconEmployeess = await getVendorSocioEconEmployees(
        accessToken,
        startDate,
        endDate,
        regionID
      );
      dispatch(getVendorSocioEconEmployeessSuccess(vendorSocioEconEmployeess));
    } catch (err: any) {
      dispatch(getVendorSocioEconEmployeessFailure(err.toString()));
    }
  };
