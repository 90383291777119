import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchSuppliers } from "./SupplierSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTranslation } from "react-i18next";

export const SupplierInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom", "objSlr"]);

  const {
    supplierList,
    suppliersById,
    isLoading: supplierIsLoading,
    error: supplierError,
  } = useSelector((state: RootState) => state.suppliers);

  const suppliers = supplierList.map((id) => suppliersById[id]);

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_Sentiment,
  } = useSelector((state: RootState) => state.client);

  const data = suppliers.map((p) => {
    let n = Object.assign({}, p) as any;
    n.FullLocation = p.TownCity
      ? p.TownCity + ", " + p.ProvinceState
      : p.ProvinceState;

    n.ContactInfo = p.FirstName
      ? p.FirstName + " " + p.LastName + ` - ${p.Email}`
      : `*none - ${p.Email}`;
    return n;
  });

  const dataColumns = [
    {
      field: "BusinessName",
      title: t("objSlr:objects.supplier.fields.businessname"),
    },
    {
      field: "CoreBusiness",
      title: "Core Business",
      commaDelimited: true,
    },
    {
      field: "RegisteredProvinces",
      title: "Registered Provinces",
      commaDelimited: true,
    },
    {
      field: "GwoTraining",
      title: "GWO",
      filter: "boolean",
      columnWidth: 100,
    },
    {
      field: "CorCertified",
      title: "COR",
      filter: "boolean",
      columnWidth: 100,
    },
    {
      field: "HSECertified",
      title: "HSE",
      filter: "boolean",
      columnWidth: 100,
    },
    {
      field: "ContactInfo",
      title: t("objPlt:objects.contact.name", { count: 2 }),
    },
    {
      field: "FullLocation",
      title: t("objPlt:objects.contact.fields.location"),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchSuppliers(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (supplierError) {
    return <ErrorPage errMsg={supplierError} />;
  }

  let supplierInvView =
    supplierIsLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objSlr:objects.supplier.name", { count: 2 })}
          tableData={data}
          idField="SupplierID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showUpload={false}
          showTemplate={false}
          sortColumn={2}
          sortReverse={true}
          linkColumn={0}
          searchByColumn={"BusinessName"}
          searchByPlaceholder={t("objSlr:objects.supplier.fields.businessname")}
        />
      </div>
    );

  return <div id="supplier-inventory-page">{supplierInvView}</div>;
};
