import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addSupplier, updSupplier, delSupplier } from "./SupplierSlice";
import {
  Alert,
  AlertTitle,
  Box,
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt, useLocation } from "react-router";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import {
  ConvertDateOffset,
  ConvertToJSDate,
} from "../../../utils/DateFunctions";
import { IppDisplayField } from "components/IppDisplayField";
import { fetchUsers } from "features/users/UsersSlice";
import { ValidationSchema } from "./SupplierFormValidation";
import { IppDatePicker } from "components/IppDatePicker";
import { countAttachments } from "api/attachmentsAPI";
import { fetchUserRoles } from "features/roles/UserRoleSlice";
import { useTypedTranslation } from "utils/customHooks";
import { IppDisplayChip } from "components/IppDisplayChip";
import { UserWriteAccess } from "utils/userAccess";
import { IppFormDivider } from "components/IppFormDivider";
import { IppLocationDisplay } from "components/IppLocationDisplay";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { IppYesNoAutocomplete } from "components/IppYesNoAutocomplete";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { IppDynamicAddress } from "components/IppDynamicAddress";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { IppButton } from "components/Buttons/IppButton";

const PREFIX = "SupplierForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

// should match the SupplierRegistration Controller

const BusinessTypes = [
  "Turbine Installation",
  "Material Type",
  "Enviro consultant",
  "Geotech consultant",
  "Electrical consultant",
  "Civil consultant",
  "Transportation Consultant",
  "Major equipment consultant",
  "Earthworks",
  "Forestry",
  "Foundation/Concrete",
  "High Voltage",
  "Mechanical",
  "Heavy Civil",
  "Electrical",
  "Engineering",
  "Survey",
  "Wind",
  "Solar",
  "Hydrogen",
  "Equipment Rental",
  "Instrumentation",
  "Electrical Installations",
  "Installations",
  "Procurement",
  "Mechanical Installations",
  "Other",
];

export const SupplierForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objSlr", "objStk"]);
  const location = useLocation();
  let isReviewPage = location.pathname.includes("/review/");

  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  let itemData = props.supplier || {};

  const [isEditing, setIsEditing] = useState(
    itemData.SupplierID ? false : true
  );

  const [isAdding, setIsAdding] = useState(itemData.SupplierID ? false : true);

  const showEdit = UserWriteAccess("Suppplier", itemData.ProjectID);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = projectList.map((id) => projectsById[id]);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { countriesStatesList, countriesStatesById } = useSelector(
    (state: RootState) => state.countriesStates
  );

  const countriesStates = countriesStatesList.map(
    (id) => countriesStatesById[id]
  );

  const { clientCountryList, clientCountriesById } = useSelector(
    (state: RootState) => state.clientCountries
  );
  const clientCountriesObject = clientCountryList.map(
    (id) => clientCountriesById[id]
  );

  const clientCountries = clientCountriesObject.map((item) => item.CountryName);
  const [provinces, setProvinces] = useState(
    countriesStates
      .filter((obj: any) => clientCountries.includes(obj.CountryName))
      .map((obj: any) => {
        return obj.ProvinceState;
      })
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchUsers(accessToken, clientId));
        if (currentProfile.UserAccountID) {
          dispatch(fetchUserRoles(accessToken, currentProfile.UserAccountID));
        }

        if (itemData.SupplierID) {
          const count = await countAttachments(
            accessToken,
            "Suppliers",
            itemData.SupplierID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.SupplierID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.SupplierID &&
              item.RecordType === "Suppliers"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addSupplier(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.SupplierID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(updSupplier(accessToken, values.SupplierID, values));
          setIsEditing(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData = {
      BusinessName: "",
      Address: "",
      TownCity: "",
      ProvinceState: "",
      CountryName: "",
      PostalCode: "",
      Website: "",
      Title: "",
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      CorpNumber: "",
      RegisteredProvinces: "",
      Insurance: 0,
      CoreBusiness: [],
      IsoCertified: null,
      IsoNumber: "",
      CorCertified: null,
      CertDate: null,
      HSECertified: null,
      IndigenousOwned: null,
      IndigenousCommunity: "",
      IndigenousCorpPercentage: null,
      InclusionPolicy: null,
      NumberEmployees: "",
      IndigenousEmployeePercentage: null,
      GwoTraining: null,
      EnergyProjects: null,
      ProjectReferences: "",
      InternalNotes: "",
    };
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        isReviewPage
          ? dispatch(delSupplier(accessToken, itemData.SupplierID, true))
          : dispatch(delSupplier(accessToken, itemData.SupplierID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(),
    onSubmit: submitFunc,
  });

  let markAprroved: (values: any) => void;
  if (itemData.SupplierID) {
    //mark as approved case
    markAprroved = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          isReviewPage
            ? dispatch(
                updSupplier(accessToken, values.SupplierID, values, true)
              )
            : dispatch(updSupplier(accessToken, values.SupplierID, values));

          setIsEditing(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  const handlePercentageChange =
    (fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value.trim();
      formik.setFieldValue(fieldName, newValue === "" ? null : newValue);
    };

  let attachmentForm = (
    <Root>
      <Box display="flex" justifyContent="center" mt={2}>
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm}>
            <IppAttachmentInventory
              recordType="Suppliers"
              itemID={itemData.SupplierID}
              companyID={currentProfile.CompanyID}
              projectID={0}
              moduleID={5}
            />
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objSlr:objects.supplier.name")}
                isEditing={isEditing}
                isAdding={isAdding}
              />
              {/* Review banner*/}
              {(!formik.isValid || !itemData.IsApproved) && (
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    action={
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        <IppButton
                          color="success"
                          size="small"
                          variant="outlined"
                          id="form"
                          onClick={() => {
                            markAprroved(formik.initialValues);
                            //formik.submitForm();
                          }}
                        >
                          Approve
                        </IppButton>
                        <Tooltip
                          title="This will permanetly delete the item"
                          arrow
                        >
                          <IppButton
                            color="error"
                            size="small"
                            variant="outlined"
                            id="form"
                            onClick={() => {
                              handleDelete();
                            }}
                          >
                            Reject
                          </IppButton>
                        </Tooltip>
                      </ButtonGroup>
                    }
                  >
                    <AlertTitle>Pending Approval</AlertTitle>
                    {
                      "This contractors detail was submitted and is pending approval"
                    }{" "}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objSlr:objects.supplier.name")}
                  showLabel={false}
                  value={formik.values.BusinessName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEmphasis={true}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppLocationDisplay
                  address={formik.values.Address}
                  townCity={formik.values.TownCity}
                  provinceState={formik.values.ProvinceState}
                  country={formik.values.CountryName}
                  postalCode={formik.values.PostalCode}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
                <Grid item xs={12}>
                  <IppDisplayField
                    label={t("objSlr:objects.supplier.fields.website")}
                    value={formik.values.Website}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              </Grid>
              <IppFormDivider title="Contact Person" />
              <Grid item xs={6}>
                <IppDisplayField
                  label="Full Name"
                  value={`${formik.values.Title || ""} ${
                    formik.values.FirstName
                  } ${formik.values.LastName}`}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Email"
                  value={formik.values.Email}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Phone Number"
                  value={formik.values.Phone}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <IppFormDivider title="Coporation Details" />
              <Grid item xs={6}>
                <IppDisplayField
                  label="Corporation Number"
                  value={formik.values.CorpNumber}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  label="Insurance Number"
                  value={formik.values.Insurance}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showCurrency
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayChip
                  label="Registered Provinces"
                  value={formik.values.RegisteredProvinces}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayChip
                  label="Business Type"
                  value={formik.values.CoreBusiness}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <IppFormDivider title="Certifications" />
              <Grid item xs={6}>
                <IppDisplayField
                  label="ISO-Certified"
                  value={
                    formik.values.IsoNumber
                      ? formik.values.IsoNumber
                      : "Not Certified"
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="COR-Certified"
                  value={
                    formik.values.CertDate &&
                    typeof formik.values.CertDate === "string"
                      ? ConvertToJSDate(formik.values.CertDate)
                      : "None"
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayCheckbox
                  label="HSE-Certified"
                  isChecked={formik.values.HSECertified}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <IppFormDivider title="Supplier Diversity" />
              <Grid item xs={6}>
                <IppDisplayField
                  label="Indigenous Entity/Community"
                  value={formik.values.IndigenousCommunity}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={3}>
                <IppDisplayField
                  label="Indigenous Percentage"
                  value={formik.values.IndigenousCorpPercentage}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={3}>
                <IppDisplayCheckbox
                  label="Diversity, Equity and Inclusion Policy"
                  isChecked={formik.values.InclusionPolicy}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <IppFormDivider title="Employee Details" />
              <Grid item xs={6}>
                <IppDisplayField
                  label="Total Full-Time Employees"
                  value={formik.values.NumberEmployees}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Indigenous Employees Percentage"
                  value={formik.values.IndigenousEmployeePercentage}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <IppFormDivider title="Former Experiences" />
              <Grid item xs={6}>
                <IppDisplayCheckbox
                  label="Employees GWO training"
                  isChecked={formik.values.GwoTraining}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayCheckbox
                  label="Renewable energy experience"
                  isChecked={formik.values.EnergyProjects}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayField
                  label="Project References"
                  value={formik.values.ProjectReferences}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <IppFormDivider title="Notes" />
              <Grid item xs={12}>
                <IppDisplayField
                  label="Internal Notes"
                  value={formik.values.InternalNotes}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={() => handleDelete()}
                  //fetchFunc={fetchDeleteChecksBySupplier}
                  info={t("strGen:prompts.deletechecks.unlinkitems")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      {/* SUPPLIER INFORMATION */}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <IppTextField
            id="BusinessName"
            value={formik.values.BusinessName}
            onChangeFunction={formik.handleChange}
            label="Business Name"
            errorsExpression={formik.errors.BusinessName}
            touchedExpression={formik.touched.BusinessName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <IppFormDivider title="Main Office" />
        <IppLocationAutoComplete
          formik={formik}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          townCityOptions={{ show: true, required: true }}
          provinceStateOptions={{ show: true, required: true }}
          countryOptions={{ show: true, required: true }}
        />

        <Grid item xs={12}>
          <IppTextField
            id="Website"
            label="Website URL (optional)"
            value={formik.values.Website}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.Website}
            touchedExpression={formik.touched.Website}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      {/* CONTACT PERSON */}
      <Grid item container spacing={2}>
        <IppFormDivider title="Contact Person" />
        <Grid item xs={12} sm={12}>
          <IppTextField
            id="Title"
            value={formik.values.Title}
            onChangeFunction={formik.handleChange}
            label="Title (optional)"
            errorsExpression={formik.errors.Title}
            touchedExpression={formik.touched.Title}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="FirstName"
            value={formik.values.FirstName}
            onChangeFunction={formik.handleChange}
            label="First Name"
            errorsExpression={formik.errors.FirstName}
            touchedExpression={formik.touched.FirstName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="LastName"
            value={formik.values.LastName}
            onChangeFunction={formik.handleChange}
            label="Last Name"
            errorsExpression={formik.errors.LastName}
            touchedExpression={formik.touched.LastName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="Email"
            value={formik.values.Email}
            onChangeFunction={formik.handleChange}
            label="Contact Email"
            errorsExpression={formik.errors.Email}
            touchedExpression={formik.touched.Email}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="Phone"
            value={formik.values.Phone}
            onChangeFunction={formik.handleChange}
            label="Contact Phone Number"
            errorsExpression={formik.errors.Phone}
            touchedExpression={formik.touched.Phone}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      {/* CORPORATION DETAILS */}
      <Grid item container spacing={2}>
        <IppFormDivider title="Corporation Details" />
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="CorpNumber"
            value={formik.values.CorpNumber}
            onChangeFunction={formik.handleChange}
            label="Corporation Number"
            errorsExpression={formik.errors.CorpNumber}
            touchedExpression={formik.touched.CorpNumber}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <IppCurrencyField
            id="Insurance"
            label="What is the insurance liability limit carried by the corporation?"
            value={parseFloat(formik.values.Insurance)}
            onChangeFunction={(newValue) =>
              formik.setFieldValue("Insurance", newValue)
            }
            errorsExpression={formik.errors.Insurance}
            touchedExpression={formik.touched.Insurance}
            isEditing={isEditing}
            allowNegative={false}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTokenMultiSelect
            id="CoreBusiness"
            options={BusinessTypes}
            value={
              formik.values.CoreBusiness
                ? typeof formik.values.CoreBusiness === "string"
                  ? formik.values.CoreBusiness.split(",")
                  : formik.values.CoreBusiness
                : []
            }
            setValue={(value: string[] | string | null) => {
              formik
                .setFieldValue("CoreBusiness", value)
                .catch((error) => console.error(error));
            }}
            label="What are the core businesses of the corporation? (multiple)"
            errorFunction={([] as any[])
              .concat(formik.errors.CoreBusiness || [])
              .filter(Boolean)}
            touchedFunction={
              formik.touched.CoreBusiness && formik.errors.CoreBusiness
                ? true
                : false
            }
            noOptionsText="No options"
            optionLabelFunction={(option: any) => option}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        {/* dynamic address */}
        <Grid xs={12} item>
          <IppFormDivider title="Registered Provinces" />
          <IppDynamicAddress
            id="RegisteredProvinces"
            options={provinces}
            value={formik.values.RegisteredProvinces}
            onChangeFunction={(item: any) => {
              formik.setFieldValue("RegisteredProvinces", item);
            }}
            touchedFunction={formik.touched.RegisteredProvinces}
            label="What municipal district/county does the company have established offices in?"
            isEditing={isEditing || isAdding}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      {/* CERTIFICATIONS */}
      <Grid item container spacing={2}>
        <IppFormDivider title="Certifications" />
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <IppYesNoAutocomplete
              id="IsoCertified"
              value={formik.values.IsoCertified}
              onChangeFunction={(
                event: React.ChangeEvent<object>,
                newValue: any
              ) => {
                if (newValue) {
                  formik
                    .setFieldValue("IsoCertified", newValue.value)
                    .catch((error) => console.error(error));
                } else {
                  formik
                    .setFieldValue("IsoCertified", null)
                    .catch((error) => console.error(error));
                }
              }}
              label="Are you ISO-Net certified?"
              errorsExpression={formik.errors.IsoCertified}
              touchedExpression={formik.touched.IsoCertified}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="IsoNumber"
              value={formik.values.IsoNumber}
              onChangeFunction={formik.handleChange}
              label="If so, please provide ISO #"
              errorsExpression={formik.errors.IsoNumber}
              touchedExpression={formik.touched.IsoNumber}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <IppYesNoAutocomplete
              id="CorCertified"
              value={formik.values.CorCertified}
              onChangeFunction={(
                event: React.ChangeEvent<object>,
                newValue: any
              ) => {
                if (newValue) {
                  formik
                    .setFieldValue("CorCertified", newValue.value)
                    .catch((error) => console.error(error));
                } else {
                  formik
                    .setFieldValue("CorCertified", null)
                    .catch((error) => console.error(error));
                }
              }}
              label="Are you COR® certified?"
              errorsExpression={formik.errors.CorCertified}
              touchedExpression={formik.touched.CorCertified}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="CertDate"
              label="If so, please provide certification date"
              value={ConvertDateOffset(formik.values.CertDate)}
              onChangeFunction={(newValue: Date | null) => {
                // Issue with GetJSDate - seems to work fine without it
                // const jsDate = newValue ? GetJSDate(newValue) : undefined;
                formik
                  .setFieldValue("CertDate", newValue, true)
                  .catch((error) => console.error(error));
                formik
                  .setFieldTouched("CertDate", true, false)
                  .catch((error) => console.error(error)); //affects live validations
              }}
              errorsExpression={formik.errors.CertDate}
              touchedExpression={formik.touched.CertDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <IppYesNoAutocomplete
                id="HSECertified"
                value={formik.values.HSECertified}
                onChangeFunction={(event, newValue) => {
                  if (newValue) {
                    formik
                      .setFieldValue("HSECertified", newValue.value)
                      .catch((error) => console.error(error));
                  } else {
                    formik
                      .setFieldValue("HSECertified", null)
                      .catch((error) => console.error(error));
                  }
                }}
                label="Are you HSE certified?"
                errorsExpression={formik.errors.HSECertified}
                touchedExpression={formik.touched.HSECertified}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* SUPPLIER DIVERSITY */}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <IppFormDivider title="Supplier Diversity" />
        </Grid>
        <Grid item xs={6}>
          <IppYesNoAutocomplete
            id="IndigenousOwned"
            value={formik.values.IndigenousOwned}
            onChangeFunction={(event, newValue) => {
              if (newValue) {
                formik
                  .setFieldValue("IndigenousOwned", newValue.value)
                  .catch((error) => console.error(error));
              } else {
                formik
                  .setFieldValue("IndigenousOwned", null)
                  .catch((error) => console.error(error));
              }
            }}
            label="Is the corporation owned by or partnered with an Indigenous entity/community?"
            errorsExpression={formik.errors.IndigenousOwned}
            touchedExpression={formik.touched.IndigenousOwned}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>

        <Grid item xs={6}>
          <IppTextField
            id="IndigenousCommunity"
            value={formik.values.IndigenousCommunity}
            onChangeFunction={formik.handleChange}
            label="If so, what Indigenous entity/community?"
            errorsExpression={formik.errors.IndigenousCommunity}
            touchedExpression={formik.touched.IndigenousCommunity}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="IndigenousCorpPercentage"
            value={formik.values.IndigenousCorpPercentage}
            //adornment="%"
            //alignment="end"
            onChangeFunction={handlePercentageChange(
              "IndigenousCorpPercentage"
            )}
            label="What percentage is Indigenous held? (optional)"
            errorsExpression={formik.errors.IndigenousCorpPercentage}
            touchedExpression={formik.touched.IndigenousCorpPercentage}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            toolTip="Use whole numbers - ie 25% should be 25, not 0.25"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppYesNoAutocomplete
            id="InclusionPolicy"
            value={formik.values.InclusionPolicy}
            onChangeFunction={(event, newValue) => {
              if (newValue) {
                formik
                  .setFieldValue("InclusionPolicy", newValue.value)
                  .catch((error) => console.error(error));
              } else {
                formik
                  .setFieldValue("InclusionPolicy", null)
                  .catch((error) => console.error(error));
              }
            }}
            label="Does the company have a Diversity, Equity, and Inclusion Policy?"
            errorsExpression={formik.errors.InclusionPolicy}
            touchedExpression={formik.touched.InclusionPolicy}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      {/* EMPLOYEE DETAILS */}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <IppFormDivider title="Employee Details" />
        </Grid>
        <Grid item xs={6}>
          <IppTextField
            id="NumberEmployees"
            value={formik.values.NumberEmployees}
            onChangeFunction={formik.handleChange}
            label="Please state number of full-time employees (optional)"
            errorsExpression={formik.errors.NumberEmployees}
            touchedExpression={formik.touched.NumberEmployees}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppTextField
            id="IndigenousEmployeePercentage"
            value={formik.values.IndigenousEmployeePercentage}
            //adornment="%"
            //alignment="end"
            onChangeFunction={handlePercentageChange(
              "IndigenousEmployeePercentage"
            )}
            label="Percentage of employees that identify as Indigenous (optional)"
            errorsExpression={formik.errors.IndigenousEmployeePercentage}
            touchedExpression={formik.touched.IndigenousEmployeePercentage}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            toolTip="Use whole numbers - ie 25% should be 25, not 0.25"
          />
        </Grid>
      </Grid>

      {/* FORMER EXPERIENCE */}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <IppFormDivider title="Former Experience" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppYesNoAutocomplete
            id="GwoTraining"
            value={formik.values.GwoTraining}
            onChangeFunction={(event, newValue) => {
              if (newValue) {
                formik
                  .setFieldValue("GwoTraining", newValue.value)
                  .catch((error) => console.error(error));
              } else {
                formik
                  .setFieldValue("GwoTraining", null)
                  .catch((error) => console.error(error));
              }
            }}
            label="Have employees obtained GWO (Global Wind Organization) training?"
            errorsExpression={formik.errors.GwoTraining}
            touchedExpression={formik.touched.GwoTraining}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IppYesNoAutocomplete
            id="EnergyProjects"
            value={formik.values.EnergyProjects}
            onChangeFunction={(event, newValue) => {
              if (newValue) {
                formik
                  .setFieldValue("EnergyProjects", newValue.value)
                  .catch((error) => console.error(error));
              } else {
                formik
                  .setFieldValue("EnergyProjects", null)
                  .catch((error) => console.error(error));
              }
            }}
            label="Does the company have experience in renewable energy projects?"
            errorsExpression={formik.errors.EnergyProjects}
            touchedExpression={formik.touched.EnergyProjects}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="ProjectReferences"
            value={formik.values.ProjectReferences}
            onChangeFunction={formik.handleChange}
            label="Please provide project references (optional)"
            errorsExpression={formik.errors.ProjectReferences}
            touchedExpression={formik.touched.ProjectReferences}
            multiLine={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      {/* Internal notes*/}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <IppFormDivider title="Internal Notes (optional)" />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="InternalNotes"
            value={formik.values.InternalNotes}
            onChangeFunction={formik.handleChange}
            label="Internal Notes"
            errorsExpression={formik.errors.InternalNotes}
            touchedExpression={formik.touched.InternalNotes}
            multiLine={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <IppFormButtons
          isEditing={isEditing}
          isAdding={isAdding}
          setIsEditing={setIsEditing}
          resetFunction={() => formik.resetForm()}
          showDelete={true}
          deleteFunction={() => handleDelete()}
          approval={!itemData.IsApproved}
        />
      </Grid>
    </form>
  );

  let pageForm =
    clientIsLoading || projectIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>
              {viewForm}
              {attachmentForm}
            </div>
          )}
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
