import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchAssets } from "./AssetSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { useTranslation } from "react-i18next";

export const AssetInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objStk", "objPlt", "strGen", "objCom"]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    assetList,
    assetIsLoading,
    assetError: error,
    assetsById,
  } = useSelector((state: RootState) => state.assets);

  const assets = assetList.map((id) => assetsById[id]);

  const data = assets.map((p) => {
    let newItem = Object.assign({}, p) as any;
    newItem.OwnerPercentage = newItem.OwnerPercentage * 100;

    return newItem;
  });

  const dataColumns = [
    {
      field: "AssetName",
      title: t("objBen:objects.asset.name"),
    },
    {
      field: "AssetDetails",
      filter: "text",
      title: t("objBen:objects.asset.fields.details"),
    },
    {
      field: "OwnerPercentage",
      title: t("objBen:objects.asset.fields.ownerpercentage"),
      format: "{0:n1}%",
      filter: "numeric",
      columnWidth: 150,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAssets(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  if (error) {
    return <ErrorPage errMsg={error} />;
  }

  let AssetInvView =
    assetIsLoading || clientIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objBen:objects.asset.name", { count: 2 })}
          tableData={data}
          idField="AssetID"
          columns={dataColumns}
          isFiltered={false}
          showDetail
          showAdd
          showUpload={false}
          showTemplate={false}
          searchByColumn="AssetName"
          searchByPlaceholder={t("strGen:filters.searchby", {
            fieldname: t("objBen:objects.asset.name"),
          })}
        />
      </div>
    );

  return <div id="Asset-inventory-page">{AssetInvView}</div>;
};
