import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchProjects } from "./ProjectSlice";
import { push } from "redux-first-history";

import { IppInventory } from "components/Inventory/IppInventory";
import LoadingIndicator from "components/LoadingIndicator";

import {
  Grid as KendoGrid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { IsExtraLargeScreen } from "utils/mediaQueries";
import { fetchRegionsByRecordType } from "features/benefits/regions/RegionSlice";

export const ProjectInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen"]);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const {
    clientId,
    ft_ben_Region,
    ft_all_ProjectLead,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const {
    projectList,
    isLoading,
    error: projError,
    projectsById,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((ProjectID) => projectsById[ProjectID]);

  const projCols = [
    { field: "ProjectName", title: t("objPlt:objects.project.name") },
    {
      field: "ProjectCode",
      title: t("objPlt:objects.project.fields.projectcode"),
      columnWidth: IsExtraLargeScreen() ? 150 : 120,
    },
    ...(ft_all_ProjectLead
      ? [
          {
            field: "ProjectLeadName",
            title: t("objPlt:objects.project.fields.projectlead"),
          },
        ]
      : []),
    ...(hasRegionTypes
      ? [{ field: "RegionName", title: t("objPlt:platformwide.fields.region") }]
      : [
          {
            field: "Location",
            title: t("objPlt:objects.project.fields.location"),
          },
        ]),
    {
      field: "ProvinceState",
      title: t("objPlt:platformwide.fields.provincestate"),
    },
    {
      field: "CountryName",
      title: t("objPlt:platformwide.fields.country"),
    },
    {
      field: "ProjectDescription",
      title: t("objPlt:objects.project.fields.description"),
      filter: "string",
      //canTruncate: true,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchProjects(accessToken));
          if (ft_ben_Region)
            dispatch(fetchRegionsByRecordType(accessToken, "Project"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const handleClick = (event: React.MouseEvent<unknown>, ProjectID: number) => {
    const route: string = "/admin/projects/" + ProjectID;
    // console.log("ROUTE", route);
    dispatch(push(route));
  };

  const handleAdd = (event: React.MouseEvent<unknown>) => {
    const route: string = "/admin/projects/new";
    // console.log("ROUTE", route);
    dispatch(push(route));
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  if (projError) {
    return (
      <div>
        <h1>{t("strGen:errors.wentwrong")}</h1>
        <div>{projError.toString()}</div>
      </div>
    );
  }

  let projInvView =
    isLoading || clientIsLoading || regionIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.project.name", { count: 5 })}
          tableData={projects}
          idField="ProjectID"
          columns={projCols}
          isFiltered={true}
          showDetail={true}
          showAdd={true}
          showTemplate={false}
          searchByColumn={"ProjectName"}
          searchByPlaceholder={
            "Search by " + t("objPlt:objects.project.fields.projectname")
          }
        />
      </div>
    );

  return <div id="project-inventory-page">{projInvView}</div>;
};
