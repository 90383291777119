import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BlockBlobClient } from "@azure/storage-blob";

import {
  AllFeatureToggles,
  Client,
  DiversityMonitoring,
  EditableFeatureToggles,
  getClientId,
  TriggeredNotifications,
  updateClient,
  updateClientDiversity,
  updateClientFeatureToggles,
  updateClientLogo,
  updateClientTrigeredNotifs,
} from "api/clientAPI";
import { fetchCurrentCompany } from "features/benefits/companies/CurrentCompanySlice";
import { AppThunk } from "app/store";
import { push } from "redux-first-history";
import * as Constants from "utils/snackBarConstants";

interface ClientState {
  clientName: string;
  clientId: number;
  clientLogo: string;
  headerColor: string;
  primaryColor: string;
  secondaryColor: string;
  clientSearchString: string;
  isLoading: boolean;
  error: string | null;
  logoPath: string;
  customTrainingUrl: string;
  ft_eng_lvlOfEngagement: boolean;
  ft_eng_Comment: boolean;
  ft_eng_RelCommunication: boolean;
  ft_eng_Importance: boolean;
  ft_eng_RelOwner: boolean;
  ft_ben_Contract: boolean;
  ft_eng_LegalSubDiv: boolean;
  ft_all_ExternalPersonRes: boolean;
  ft_eng_Sentiment: boolean;
  ft_eng_GroupIssue: boolean;
  ft_eng_ContactIssue: boolean;
  ft_eng_InternalNotes: boolean;
  ft_comeng_CommitmentInteraction: boolean;
  ft_eng_Grievance: boolean;
  ft_com_Phase: boolean;
  ft_eng_Phase: boolean;
  ft_all_Tag: boolean;
  ft_ben_Tier: boolean;
  ft_ben_Spend: boolean;
  ft_all_ProjectTypes: boolean;
  ft_eng_BCER: boolean;
  ft_ben_AltWorkHours2: boolean;
  ft_ben_AltWorkHours3: boolean;
  dm_WomanOwned: boolean;
  dm_VeteranOwned: boolean;
  dm_Disability: boolean;
  dm_VisibleMinority: boolean;
  dm_LGBTQ: boolean;
  dm_Local: boolean;
  authOrgID: string;
  ft_ben_TempEmployee: boolean;
  tn_CompanyReject: boolean;
  tn_CompanyAccept: boolean;
  tn_PaymentPeriodReject: boolean;
  tn_PaymentPeriodAccepted: boolean;
  tn_PersonResponsibleAssigned: boolean;
  ft_ben_BenefitsMenu: boolean;
  ft_ben_JobPosition: boolean;
  ft_dev: boolean;
  ft_ben_JobHistory: boolean;
  ft_ben_DiversityDeclined: boolean;
  ft_ben_RND: boolean;
  ft_ben_CustomRnD: boolean;
  ft_ben_CommunityInvestment: boolean;
  ft_ben_AllocatedDate: boolean;
  ft_ben_Region: boolean;
  ft_ben_BusinessCategory: boolean;
  ft_all_ProjectLead: boolean;
  ft_ben_ActivityLocation: boolean;
  ft_ben_Asset: boolean;
  ft_com_Priority: boolean;
  ft_com_Associated: boolean;
  ft_com_DateApplicable: boolean;
  ft_com_InternalNotes: boolean;
  ft_ben_SpendReportDate: boolean;
  ft_ben_SpendReport: boolean;
  ft_ben_CustomJobPosition: boolean;
  ft_eng_IntenalCommunication: boolean;
  ft_ben_WorkForce: boolean;
  ft_eng_Thread: boolean;
  ft_ben_WorkHourSubContractor: boolean;
  ft_eng_PILSD: boolean;
  ft_ben_Ethnicity: boolean;
  ft_eng_PreferredCommunicationMethod: boolean;
  ft_eng_ThreadQuickAdd: boolean;
  ft_ben_EmployeePostalCode: boolean;
  ft_ben_CustomEmpCategory: boolean;
}

const pathArray = window.location.pathname.split("/");
const clientName = pathArray[1];

const clientInitialState: ClientState = {
  clientName: clientName,
  clientId: -1,
  clientLogo: "",
  headerColor: "",
  primaryColor: "",
  secondaryColor: "",
  clientSearchString: "",
  isLoading: false,
  error: null,
  logoPath: "",
  customTrainingUrl: "",
  ft_eng_lvlOfEngagement: false,
  ft_eng_Comment: false,
  ft_eng_RelCommunication: false,
  ft_eng_Importance: false,
  ft_eng_RelOwner: false,
  ft_ben_Contract: false,
  ft_eng_LegalSubDiv: false,
  ft_all_ExternalPersonRes: false,
  ft_eng_Sentiment: false,
  ft_eng_GroupIssue: false,
  ft_eng_ContactIssue: false,
  ft_eng_InternalNotes: false,
  ft_comeng_CommitmentInteraction: false,
  ft_eng_Grievance: false,
  ft_com_Phase: false,
  ft_eng_Phase: false,
  dm_WomanOwned: false,
  dm_VeteranOwned: false,
  dm_Disability: false,
  dm_VisibleMinority: false,
  dm_LGBTQ: false,
  dm_Local: false,
  authOrgID: "",
  ft_all_Tag: false,
  ft_ben_Tier: false,
  ft_ben_Spend: false,
  ft_all_ProjectTypes: false,
  ft_eng_BCER: false,
  ft_ben_AltWorkHours2: false,
  ft_ben_AltWorkHours3: false,
  ft_ben_TempEmployee: false,
  tn_CompanyReject: false,
  tn_CompanyAccept: false,
  tn_PaymentPeriodReject: false,
  tn_PaymentPeriodAccepted: false,
  tn_PersonResponsibleAssigned: false,
  ft_ben_BenefitsMenu: false,
  ft_ben_JobPosition: false,
  ft_dev: false,
  ft_ben_JobHistory: false,
  ft_ben_DiversityDeclined: false,
  ft_ben_RND: false,
  ft_ben_CustomRnD: false,
  ft_ben_CommunityInvestment: false,
  ft_ben_AllocatedDate: false,
  ft_ben_Region: false,
  ft_ben_BusinessCategory: false,
  ft_all_ProjectLead: false,
  ft_ben_ActivityLocation: false,
  ft_ben_Asset: false,
  ft_com_Priority: false,
  ft_com_Associated: false,
  ft_com_DateApplicable: false,
  ft_com_InternalNotes: false,
  ft_ben_SpendReportDate: false,
  ft_ben_SpendReport: false,
  ft_ben_CustomJobPosition: false,
  ft_eng_IntenalCommunication: false,
  ft_ben_WorkForce: false,
  ft_eng_Thread: false,
  ft_ben_WorkHourSubContractor: false,
  ft_eng_PILSD: false,
  ft_ben_Ethnicity: false,
  ft_eng_PreferredCommunicationMethod: false,
  ft_eng_ThreadQuickAdd: false,
  ft_ben_EmployeePostalCode: false,
  ft_ben_CustomEmpCategory: false,
};

function startLoading(state: ClientState) {
  state.isLoading = true;
  state.error = null;
}

function startLoadingAdd(state: ClientState) {
  state.isLoading = true;
  state.error = null;
}

function loadingFailed(state: ClientState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const client = createSlice({
  name: "client",
  initialState: clientInitialState,
  reducers: {
    getClientIdStart: startLoading,
    getClientIdSuccess(state, { payload }: PayloadAction<Client>) {
      state.clientId = payload.ClientID;
      state.clientLogo = payload.ClientLogo;
      state.headerColor = payload.HeaderColor;
      state.primaryColor = payload.PrimaryColor;
      state.secondaryColor = payload.SecondaryColor;
      state.clientSearchString = payload.ClientSearchString;
      state.isLoading = false;
      state.error = null;
      state.logoPath = payload.LogoPath;
      state.customTrainingUrl = payload.CustomTrainingUrl || "";
      state.ft_eng_lvlOfEngagement = payload.FT_ENG_LvlOfEngagement;
      state.ft_eng_Comment = payload.FT_ENG_Comment;
      state.ft_eng_RelCommunication = payload.FT_ENG_RelCommunication;
      state.ft_eng_Importance = payload.FT_ENG_Importance;
      state.ft_eng_RelOwner = payload.FT_ENG_RelOwner;
      state.ft_ben_Contract = payload.FT_BEN_Contract;
      state.ft_eng_LegalSubDiv = payload.FT_ENG_LegalSubDiv;
      state.ft_all_ExternalPersonRes = payload.FT_ALL_ExternalPersonRes;
      state.ft_eng_Sentiment = payload.FT_ENG_Sentiment;
      state.ft_eng_GroupIssue = payload.FT_ENG_GroupIssue;
      state.ft_eng_ContactIssue = payload.FT_ENG_ContactIssue;
      state.ft_eng_InternalNotes = payload.FT_ENG_InternalNotes;
      state.ft_comeng_CommitmentInteraction =
        payload.FT_COMENG_CommitmentInteraction;
      state.ft_eng_Grievance = payload.FT_ENG_Grievance;
      state.ft_com_Phase = payload.FT_COM_Phase;
      state.ft_eng_Phase = payload.FT_ENG_Phase;
      state.dm_WomanOwned = payload.DM_WomanOwned;
      state.dm_VeteranOwned = payload.DM_VeteranOwned;
      state.dm_Disability = payload.DM_Disability;
      state.dm_VisibleMinority = payload.DM_VisibleMinority;
      state.dm_LGBTQ = payload.DM_LGBTQ;
      state.dm_Local = payload.DM_Local;
      state.authOrgID = payload.AuthOrgID || "";
      state.ft_all_Tag = payload.FT_ALL_Tag;
      state.ft_ben_Tier = payload.FT_BEN_Tier;
      state.ft_ben_Spend = payload.FT_BEN_Spend;
      state.ft_all_ProjectTypes = payload.FT_ALL_ProjectTypes;
      state.ft_eng_BCER = payload.FT_ENG_BCER;
      state.ft_ben_AltWorkHours2 = payload.FT_BEN_AltWorkHours2;
      state.ft_ben_AltWorkHours3 = payload.FT_BEN_AltWorkHours3;
      state.ft_ben_TempEmployee = payload.FT_BEN_TempEmployee;
      state.tn_CompanyAccept = payload.TN_CompanyAccept;
      state.tn_CompanyReject = payload.TN_CompanyReject;
      state.tn_PaymentPeriodReject = payload.TN_PaymentPeriodReject;
      state.tn_PaymentPeriodAccepted = payload.TN_PaymentPeriodAccepted;
      state.tn_PersonResponsibleAssigned = payload.TN_PersonResponsibleAssigned;
      state.ft_ben_BenefitsMenu = payload.FT_BEN_BenefitsMenu;
      state.ft_ben_JobPosition = payload.FT_BEN_JobPosition;
      state.ft_dev = payload.FT_DEV;
      state.ft_ben_JobHistory = payload.FT_BEN_JobHistory;
      state.ft_ben_DiversityDeclined = payload.FT_BEN_DiversityDeclined;
      state.ft_ben_RND = payload.FT_BEN_RND;
      state.ft_ben_CustomRnD = payload.FT_BEN_CustomRnD;
      state.ft_ben_CommunityInvestment = payload.FT_BEN_CommunityInvestment;
      state.ft_ben_AllocatedDate = payload.FT_BEN_AllocatedDate;
      state.ft_ben_Region = payload.FT_BEN_Region;
      state.ft_ben_BusinessCategory = payload.FT_BEN_BusinessCategory;
      state.ft_all_ProjectLead = payload.FT_ALL_ProjectLead;
      state.ft_ben_ActivityLocation = payload.FT_BEN_ActivityLocation;
      state.ft_ben_Asset = payload.FT_BEN_Asset;
      state.ft_com_Priority = payload.FT_COM_Priority;
      state.ft_com_Associated = payload.FT_COM_Associated;
      state.ft_com_DateApplicable = payload.FT_COM_DateApplicable;
      state.ft_com_InternalNotes = payload.FT_COM_InternalNotes;
      state.ft_ben_SpendReportDate = payload.FT_BEN_SpendReportDate;
      state.ft_ben_SpendReport = payload.FT_BEN_SpendReport;
      state.ft_ben_CustomJobPosition = payload.FT_BEN_CustomJobPosition;
      state.ft_eng_IntenalCommunication = payload.FT_ENG_IntenalCommunication;
      state.ft_ben_WorkForce = payload.FT_BEN_WorkForce;
      state.ft_eng_Thread = payload.FT_ENG_Thread;
      state.ft_ben_WorkHourSubContractor = payload.FT_BEN_WorkHourSubContractor;
      state.ft_eng_PILSD = payload.FT_ENG_PILSD;
      state.ft_ben_Ethnicity = payload.FT_BEN_Ethnicity;
      state.ft_eng_PreferredCommunicationMethod =
        payload.FT_ENG_PreferredCommunicationMethod;
      state.ft_eng_ThreadQuickAdd = payload.FT_ENG_ThreadQuickAdd;
      state.ft_ben_CustomEmpCategory = payload.FT_BEN_CustomEmpCategory;
      state.ft_ben_EmployeePostalCode = payload.FT_BEN_EmployeePostalCode;
    },
    createClientStart: startLoadingAdd,
    updateClientSuccess(state, { payload }: PayloadAction<Partial<Client>>) {
      state.clientLogo = payload.ClientLogo ?? state.clientLogo;
      state.headerColor = payload.HeaderColor ?? state.headerColor;
      state.primaryColor = payload.PrimaryColor ?? state.primaryColor;
      state.secondaryColor = payload.SecondaryColor ?? state.secondaryColor;
      state.clientSearchString =
        payload.ClientSearchString ?? state.clientSearchString;
      state.logoPath = payload.LogoPath ?? state.logoPath;
      state.isLoading = false;
      state.error = null;
      state.ft_eng_lvlOfEngagement =
        payload.FT_ENG_LvlOfEngagement ?? state.ft_eng_lvlOfEngagement;
      state.ft_eng_Comment = payload.FT_ENG_Comment ?? state.ft_eng_Comment;
      state.ft_eng_RelCommunication =
        payload.FT_ENG_RelCommunication ?? state.ft_eng_RelCommunication;
      state.ft_eng_Importance =
        payload.FT_ENG_Importance ?? state.ft_eng_Importance;
      state.ft_eng_RelOwner = payload.FT_ENG_RelOwner ?? state.ft_eng_RelOwner;
      state.ft_ben_Contract = payload.FT_BEN_Contract ?? state.ft_ben_Contract;
      state.ft_eng_LegalSubDiv =
        payload.FT_ENG_LegalSubDiv ?? state.ft_eng_LegalSubDiv;
      state.ft_all_ExternalPersonRes =
        payload.FT_ALL_ExternalPersonRes ?? state.ft_all_ExternalPersonRes;
      state.ft_eng_Sentiment =
        payload.FT_ENG_Sentiment ?? state.ft_eng_Sentiment;
      state.ft_eng_GroupIssue =
        payload.FT_ENG_GroupIssue ?? state.ft_eng_GroupIssue;
      state.ft_eng_ContactIssue =
        payload.FT_ENG_ContactIssue ?? state.ft_eng_ContactIssue;
      state.ft_eng_InternalNotes =
        payload.FT_ENG_InternalNotes ?? state.ft_eng_InternalNotes;
      state.ft_comeng_CommitmentInteraction =
        payload.FT_COMENG_CommitmentInteraction ??
        state.ft_comeng_CommitmentInteraction;
      state.ft_eng_Grievance =
        payload.FT_ENG_Grievance ?? state.ft_eng_Grievance;
      state.ft_com_Phase = payload.FT_COM_Phase ?? state.ft_com_Phase;
      state.ft_eng_Phase = payload.FT_ENG_Phase ?? state.ft_eng_Phase;
      state.dm_WomanOwned = payload.DM_WomanOwned ?? state.dm_WomanOwned;
      state.dm_VeteranOwned = payload.DM_VeteranOwned ?? state.dm_VeteranOwned;
      state.dm_Disability = payload.DM_Disability ?? state.dm_Disability;
      state.dm_Local = payload.DM_Local ?? state.dm_Local;
      state.dm_VisibleMinority =
        payload.DM_VisibleMinority ?? state.dm_VisibleMinority;
      state.dm_LGBTQ = payload.DM_LGBTQ ?? state.dm_LGBTQ;
      state.ft_all_Tag = payload.FT_ALL_Tag ?? state.ft_all_Tag;
      state.ft_ben_Tier = payload.FT_BEN_Tier ?? state.ft_ben_Tier;
      state.ft_ben_Spend = payload.FT_BEN_Spend ?? state.ft_ben_Spend;
      state.ft_all_ProjectTypes =
        payload.FT_ALL_ProjectTypes ?? state.ft_all_ProjectTypes;
      state.ft_eng_BCER = payload.FT_ENG_BCER ?? state.ft_eng_BCER;
      state.ft_ben_AltWorkHours2 =
        payload.FT_BEN_AltWorkHours2 ?? state.ft_ben_AltWorkHours2;
      state.ft_ben_AltWorkHours3 =
        payload.FT_BEN_AltWorkHours3 ?? state.ft_ben_AltWorkHours3;
      state.ft_ben_TempEmployee =
        payload.FT_BEN_TempEmployee ?? state.ft_ben_TempEmployee;
      state.tn_CompanyAccept =
        payload.TN_CompanyAccept ?? state.tn_CompanyAccept;
      state.tn_CompanyReject =
        payload.TN_CompanyReject ?? state.tn_CompanyReject;
      state.tn_PaymentPeriodReject =
        payload.TN_PaymentPeriodReject ?? state.tn_PaymentPeriodReject;
      state.tn_PaymentPeriodAccepted =
        payload.TN_PaymentPeriodAccepted ?? state.tn_PaymentPeriodAccepted;
      state.tn_PersonResponsibleAssigned =
        payload.TN_PersonResponsibleAssigned ??
        state.tn_PersonResponsibleAssigned;
      state.ft_ben_BenefitsMenu =
        payload.FT_BEN_BenefitsMenu ?? state.ft_ben_BenefitsMenu;
      state.ft_ben_JobPosition =
        payload.FT_BEN_JobPosition ?? state.ft_ben_JobPosition;
      state.ft_dev = payload.FT_DEV ?? state.ft_dev;
      state.ft_ben_JobHistory =
        payload.FT_BEN_JobHistory ?? state.ft_ben_JobHistory;
      state.ft_ben_DiversityDeclined =
        payload.FT_BEN_DiversityDeclined ?? state.ft_ben_DiversityDeclined;
      state.ft_ben_RND = payload.FT_BEN_RND ?? state.ft_ben_RND;
      state.ft_ben_CustomRnD =
        payload.FT_BEN_CustomRnD ?? state.ft_ben_CustomRnD;
      state.ft_ben_CommunityInvestment =
        payload.FT_BEN_CommunityInvestment ?? state.ft_ben_CommunityInvestment;
      state.ft_ben_AllocatedDate =
        payload.FT_BEN_AllocatedDate ?? state.ft_ben_AllocatedDate;
      state.ft_ben_Region = payload.FT_BEN_Region ?? state.ft_ben_Region;
      state.ft_ben_BusinessCategory =
        payload.FT_BEN_BusinessCategory ?? state.ft_ben_BusinessCategory;
      state.ft_all_ProjectLead =
        payload.FT_ALL_ProjectLead ?? state.ft_all_ProjectLead;
      state.ft_ben_ActivityLocation =
        payload.FT_BEN_ActivityLocation ?? state.ft_ben_ActivityLocation;
      state.ft_ben_Asset = payload.FT_BEN_Asset ?? state.ft_ben_Asset;
      state.ft_com_Priority = payload.FT_COM_Priority ?? state.ft_com_Priority;
      state.ft_com_Associated =
        payload.FT_COM_Associated ?? state.ft_com_Associated;
      state.ft_com_DateApplicable =
        payload.FT_COM_DateApplicable ?? state.ft_com_DateApplicable;
      state.ft_com_InternalNotes =
        payload.FT_COM_InternalNotes ?? state.ft_com_InternalNotes;
      state.ft_ben_SpendReportDate =
        payload.FT_BEN_SpendReportDate ?? state.ft_ben_SpendReportDate;
      state.ft_ben_SpendReport =
        payload.FT_BEN_SpendReport ?? state.ft_ben_SpendReport;
      state.ft_ben_CustomJobPosition =
        payload.FT_BEN_CustomJobPosition ?? state.ft_ben_CustomJobPosition;
      state.ft_eng_IntenalCommunication =
        payload.FT_ENG_IntenalCommunication ??
        state.ft_eng_IntenalCommunication;
      state.ft_ben_WorkForce =
        payload.FT_BEN_WorkForce ?? state.ft_ben_WorkForce;
      state.ft_eng_Thread = payload.FT_ENG_Thread ?? state.ft_eng_Thread;
      state.ft_ben_WorkHourSubContractor =
        payload.FT_BEN_WorkHourSubContractor ??
        state.ft_ben_WorkHourSubContractor;
      state.ft_eng_PILSD = payload.FT_ENG_PILSD ?? state.ft_eng_PILSD;
      state.ft_ben_Ethnicity =
        payload.FT_BEN_Ethnicity ?? state.ft_ben_Ethnicity;
      state.ft_eng_PreferredCommunicationMethod =
        payload.FT_ENG_PreferredCommunicationMethod ??
        state.ft_eng_PreferredCommunicationMethod;
      state.ft_eng_ThreadQuickAdd =
        payload.FT_ENG_ThreadQuickAdd ?? state.ft_eng_ThreadQuickAdd;
      state.ft_ben_EmployeePostalCode =
        payload.FT_BEN_EmployeePostalCode ?? state.ft_ben_EmployeePostalCode;
      state.ft_ben_CustomEmpCategory =
        payload.FT_BEN_CustomEmpCategory ?? state.ft_ben_CustomEmpCategory;
    },
    getClientIdFailure: loadingFailed,
    createClientFailure: loadingFailed,
  },
});

export const {
  getClientIdStart,
  getClientIdSuccess,
  getClientIdFailure,
  createClientStart,
  updateClientSuccess,
  createClientFailure,
} = client.actions;

export default client.reducer;

export const fetchClientId =
  (accessToken: String, name: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getClientIdStart());
      const client = await getClientId(accessToken, name);
      dispatch(getClientIdSuccess(client));
      dispatch(fetchCurrentCompany(accessToken, client.ClientID));
    } catch (err: any) {
      dispatch(getClientIdFailure(err.toString()));
    }
  };

export const updClient =
  (
    accessToken: String,
    clientID: number,
    newClient: Partial<Client>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientStart());
      const client = await updateClient(accessToken, clientID, newClient);

      dispatch(updateClientSuccess(client));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/admin/branding"));
      }
    } catch (err: any) {
      dispatch(createClientFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

const removeSystemUserToggles = (
  features: Partial<AllFeatureToggles>
): Partial<EditableFeatureToggles> => {
  const {
    FT_ENG_Importance,
    FT_ENG_RelOwner,
    FT_ENG_GroupIssue,
    FT_ENG_ContactIssue,
    FT_BEN_Tier,
    FT_BEN_BenefitsMenu,
    FT_BEN_CustomRnD,
    FT_BEN_ActivityLocation,
    FT_ENG_Thread,
    FT_ENG_PILSD,
    FT_BEN_WorkHourSubContractor,
    ...rest
  } = features;
  return rest;
};

export const changeClientFeatures =
  (
    accessToken: string,
    updatedFeatures: Partial<AllFeatureToggles>,
    isSystemUser: boolean,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientStart());

      if (!isSystemUser) {
        updatedFeatures = removeSystemUserToggles(updatedFeatures);
      }

      const client = await updateClientFeatureToggles(
        accessToken,
        updatedFeatures
      );

      dispatch(updateClientSuccess(client));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));

      if (setReturnRoute) {
        // Set return route for other features page
        dispatch(push("/admin/features"));
      }
    } catch (err: any) {
      dispatch(createClientFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const changeClientLogo =
  (
    accessToken: string,
    clientID: number,
    file: any,
    newClient: Partial<Client>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientStart());

      const updClient: Partial<Client> = {
        ClientID: clientID,
        ClientName: newClient.ClientName || "",
        HeaderColor: newClient.HeaderColor || "",
        PrimaryColor: newClient.PrimaryColor || "",
        SecondaryColor: newClient.SecondaryColor || "",
        ClientSearchString: newClient.ClientSearchString || "",
        ClientLogo: file.name,
        LogoPath: newClient.LogoPath || "",
        LogoIsUploaded: false,
      };

      // update db and get and Azure upload token
      const client = await updateClientLogo(accessToken, clientID, updClient);

      const blockBlobClient = new BlockBlobClient(client.LogoPath);
      const promise = blockBlobClient.uploadData(file.getRawFile());
      await promise;

      // //Update DB isUploaded flag
      // let completedAttachment = await updateAttachment(
      //   accessToken,
      //   attachment.AttachmentID,
      //   { IsUploaded: true }
      // );

      // completedAttachment.FilePath = attachment.FilePath;

      // update isloaded flag
      let completedClient = await updateClient(accessToken, client.ClientID, {
        LogoIsUploaded: true,
      });

      updClient.LogoPath = client.LogoPath;

      dispatch(updateClientSuccess(updClient));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createClientFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientDiversity =
  (
    accessToken: String,
    clientID: number,
    updatedValues: Partial<DiversityMonitoring>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientStart());

      const client = await updateClientDiversity(
        accessToken,
        clientID,
        updatedValues
      );

      dispatch(updateClientSuccess(client));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/admin/diversityMonitoring"));
      }
    } catch (err: any) {
      dispatch(createClientFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updClientTriggeredNotifs =
  (
    accessToken: String,
    updatedValues: Partial<TriggeredNotifications>,
    setReturnRoute: boolean
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createClientStart());

      const client = await updateClientTrigeredNotifs(
        accessToken,
        updatedValues
      );

      dispatch(updateClientSuccess(client));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute) {
        dispatch(push("/admin/triggerednotificationsfeatures"));
      }
    } catch (err: any) {
      dispatch(createClientFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
