import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AffiliationHoursChart } from "./charts/AffiliationHoursChart";
import { EmployeesByGenderChart } from "./charts/EmployeesByGenderChart";
import { ExpenditureChart } from "./charts/ExpenditureChart";
import { WorkHourChart } from "./charts/WorkHourChart";
import { RootState } from "app/rootReducer";
import LoadingIndicator from "components/LoadingIndicator";
import IppShadowBox from "components/IppShadowBox";
import { useTranslation } from "react-i18next";
import { IppSkeleton } from "components/IppSkeleton";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { checkShadowBoxCount } from "utils/functions";
import { fetchBenefitsTiles } from "./charts/BenefitsTilesSlice";
import { WorkHourReportingStatusForm } from "features/platform/admin/charts/WorkHourReportingStatusForm";
import { PaymentPeriodExceptionForm } from "features/platform/admin/charts/PaymentPeriodExceptionForm";

export const BenefitsHomePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objBen"]);
  //const [newCompRequests, setNewCompRequests] = useState(0);

  const {
    ft_ben_SpendReport,
    ft_ben_WorkForce,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    benefitsTilesList,
    benefitsTilesById,
    isLoading: tileIsLoading,
  } = useSelector((state: RootState) => state.benefitsTiles);

  const tileData = benefitsTilesList.map((id) => benefitsTilesById[id]);

  const shadowBoxData = {
    UnconfirmedCompanies: tileData[0]?.UnconfirmedCompanies ?? "..",
    IncompleteCompanies: tileData[0]?.IncompleteCompanies ?? "..",
    PaymentPeriodsNeedingReview:
      tileData[0]?.PaymentPeriodsNeedingReview ?? "..",
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchBenefitsTiles(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, dispatch]);

  let homePageForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <Box padding={1}>
              <Typography variant="h5" component="h1">
                {t("objBen:objects.benefitshome.header")}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {currentProfile.IsClient ? (
          tileIsLoading ? (
            <>
              <Grid item xs={6} md={3}>
                <IppSkeleton height={198} />
              </Grid>
              <Grid item xs={6} md={3}>
                <IppSkeleton height={198} />
              </Grid>
              <Grid item xs={6} md={3}>
                <IppSkeleton height={198} />
              </Grid>
              <Grid item xs={6} md={3}>
                <IppSkeleton height={198} />
              </Grid>
            </>
          ) : (
            // If spend report enabled, show company-related details
            <>
              {ft_ben_SpendReport ? (
                <>
                  <Grid item xs={6} md={ft_ben_SpendReport ? 4 : 6}>
                    <IppShadowBox
                      title="New Company Requests"
                      value={shadowBoxData.UnconfirmedCompanies.toString()}
                      colorSet={checkShadowBoxCount(
                        shadowBoxData.UnconfirmedCompanies
                      )}
                      pageName="/benefits/companies/unconfirmed"
                      minHeight="150px"
                    />
                  </Grid>
                  <Grid item xs={6} md={ft_ben_SpendReport ? 4 : 6}>
                    <IppShadowBox
                      title="Incomplete Company Profiles"
                      value={shadowBoxData.IncompleteCompanies.toString()}
                      colorSet={checkShadowBoxCount(
                        shadowBoxData.IncompleteCompanies
                      )}
                      pageName="/benefits/companies/review"
                      minHeight="150px"
                    />
                  </Grid>
                </>
              ) : (
                // if spend reports disabled, show employee data instead
                <>
                  <Grid item xs={12} lg={6} xl={6}>
                    <IppShadowBox
                      title={t(
                        "objBen:objects.benefitshome.Affiliationhourschart"
                      )}
                      component={<AffiliationHoursChart />}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={6}>
                    <IppShadowBox
                      title={t(
                        "objBen:objects.benefitshome.Employeesbygenderchart"
                      )}
                      component={<EmployeesByGenderChart />}
                    />
                  </Grid>
                </>
              )}

              {ft_ben_SpendReport && (
                <Grid item xs={6} md={4}>
                  <IppShadowBox
                    title="In-Review Spend Reports"
                    value={shadowBoxData.PaymentPeriodsNeedingReview.toString()}
                    colorSet={checkShadowBoxCount(
                      shadowBoxData.PaymentPeriodsNeedingReview
                    )}
                    pageName="/benefits/paymentPeriods"
                    filter={{
                      filters: [
                        {
                          logic: "or",
                          filters: [
                            {
                              field: "WorkflowStageName",
                              operator: "eq",
                              value: "Submitted for Review",
                            },
                          ],
                        },
                      ],
                      logic: "and",
                    }}
                    minHeight="150px"
                  />
                </Grid>
              )}
              {ft_ben_WorkForce && (
                <Grid item xs={12} md={ft_ben_SpendReport ? 6 : 12}>
                  <Paper>
                    <WorkHourReportingStatusForm />
                  </Paper>
                </Grid>
              )}
              {ft_ben_SpendReport && (
                <Grid item xs={12} md={ft_ben_WorkForce ? 6 : 12}>
                  <Paper>
                    <PaymentPeriodExceptionForm instanceId="PmtPeriodExceptions" />
                  </Paper>
                </Grid>
              )}
            </>
          )
        ) : (
          <>
            <Grid item xs={12} md={6} xl={ft_ben_SpendReport ? 6 : 12}>
              <IppShadowBox
                title={t("objBen:objects.benefitshome.workhourchart")}
                component={<WorkHourChart />}
              />
            </Grid>
            {ft_ben_SpendReport && (
              <Grid item xs={12} md={6} xl={6}>
                <IppShadowBox
                  title={t("objBen:objects.benefitshome.expenditurechart")}
                  component={<ExpenditureChart />}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={6} xl={6}>
              <IppShadowBox
                title={t("objBen:objects.benefitshome.Affiliationhourschart")}
                component={<AffiliationHoursChart />}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <IppShadowBox
                title={t("objBen:objects.benefitshome.Employeesbygenderchart")}
                component={<EmployeesByGenderChart />}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );

  return <div id="benefit-form">{homePageForm}</div>;
};
