import axiosClient from "api/axiosClient";
import { DateAsString } from "utils/DateFunctions";

export interface VendorActiveEmployees {
  IndigenousGroupID: number;
  IndigenousGroupName: string;
  EmployeeCount: number;
}

export interface VendorActiveEmployeesResult {
  vendorActiveEmployees: VendorActiveEmployees[];
}

export interface VendorActiveEmployeesByCategory {
  CustomEmpCategoryID: number;
  CustomEmpCategoryName: string;
  EmployeeCount: number;
}

export interface VendorActiveEmployeesByCategoryResult {
  vendorActiveEmployeesByCategory: VendorActiveEmployeesByCategory[];
}

export async function getVendorActiveEmployees(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number
): Promise<VendorActiveEmployeesResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/activeEmployees/${start}/${end}/${regionID}`;
  try {
    const { data } = await axiosClient.get<VendorActiveEmployeesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getVendorActiveEmployeesByCategory(
  accessToken: String,
  startDate: Date,
  endDate: Date,
  regionID: number
): Promise<VendorActiveEmployeesByCategoryResult> {
  const start = DateAsString(startDate);
  const end = DateAsString(endDate);
  const url = `/charts/activeEmployeesByCategory/${start}/${end}/${regionID}`;
  try {
    const { data } =
      await axiosClient.get<VendorActiveEmployeesByCategoryResult>(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

    return data;
  } catch (err: any) {
    throw err;
  }
}
