import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import i18next from "./i18n/config"; //Initialize i18n
import { getClientAuthOrgId } from "./api/clientAPI";

import { store, history } from "./app/store";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import TenantNameRedirect from "features/auth/TenantNameRedirect";
import { StrictMode } from "react";
import { clarity } from "react-microsoft-clarity";

//Move this to .env file
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
const auth0ClientId = process.env.REACT_APP_AUTH0_UL_CLIENT_ID || "";
const pathArray = window.location.pathname.split("/");
let redirectLoc = window.location.origin + "/" + pathArray[1];

//Helper function to find a specific query param in the URL
function getQueryVariable(variable: string) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  //console.log("Query variable %s not found", variable);
}

//This handles the redirect after pasword change
const queryParamString = window.location.search;
if (queryParamString) {
  const foundOrgName = getQueryVariable("organization_name");
  if (foundOrgName) {
    redirectLoc = redirectLoc + foundOrgName;
    history.replace({
      pathname: foundOrgName,
      search: "",
    });
    window.location.reload();
  }
}

const onRedirectCallback = async (appState: any) => {
  const cleanPath = appState.returnTo.split("?");
  const pathArray = cleanPath[0].split("/");

  let removedTenant = "";
  for (let i = 2; i < pathArray.length; i++) {
    removedTenant += "/";
    removedTenant += pathArray[i];
  }

  history.replace({
    pathname: removedTenant || "/home",
    search: "",
  });
};

const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

const render = () => {
  const App = require("./app/App").default;

  //localStorage.setItem('orgId', getClientAuthOrgId(pathArray[1]))

  const getOrgId = () => {
    return localStorage.getItem("orgId") || undefined;
  };

  ReactDOM.render(
    <StrictMode>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        useFormData={false}
        authorizationParams={{
          redirect_uri: redirectLoc,
          //onRedirectCallback: onRedirectCallback,
          audience: audience,
          organization: getOrgId(),
        }}
        onRedirectCallback={onRedirectCallback}
        //redirectUri={redirectLoc}
        //onRedirectCallback={onRedirectCallback}
        //audience={audience}
      >
        <TenantNameRedirect>
          <Provider store={store}>
            <App history={history} />
          </Provider>
        </TenantNameRedirect>
      </Auth0Provider>
    </StrictMode>,
    document.getElementById("root")
  );
};

//let orgIDvar = "";

i18next
  .init({
    lng: "en", // if using a language detector, do not define the lng option
    ns: ["strGen", "objPlt", "objBen", "objCom", "objStk"],
    fallbackNS: ["strGen", "objPlt", "objBen", "objCom", "objStk"],
    fallbackLng: "en",
    lowerCaseLng: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      allowMultiLoading: true,
    },
    react: {
      bindI18n: "added",
      bindI18nStore: "added",
    },
    returnNull: false,
    debug: process.env.NODE_ENV === "development" ? true : false, //This should be switched to only be used in dev
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
  })
  .then(() => {
    if (pathArray && pathArray[1] != "") {
      getClientAuthOrgId()
        .then((authOrgID) => localStorage.setItem("orgId", authOrgID))
        .then(() => render());
    } else render();
  });

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render);
}

// clarity setup

clarity.init(process.env.REACT_APP_CLARITY_ID ?? "");

// Extract client name from the pathname
const userID = window.location.pathname.split("/")[1];
// only run on local or dev
if (
  clarity.hasStarted() &&
  (process.env.PLATFORM_VERSION == "local" ||
    process.env.NODE_ENV == "development")
) {
  clarity.identify(userID, {
    userID,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
