import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";
import { maxDecimalPlaces } from "validation/validationFunctions";

export const WorkHourDetailValidationSchema = (
  altWorkHours2Enabled: boolean,
  altWorkHours3Enabled: boolean
) => {
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const validateHours = (
    hours1: number | undefined,
    hours2: number | undefined,
    hours3: number | undefined
  ): boolean => {
    if (hours1 || hours2 || hours3) {
      return true;
    }
    return false;
  };

  return yup.object({
    EmployeeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.employee.name"),
        })
      ),
    RegularHours: yup.number().when([], {
      is: () => altWorkHours2Enabled || altWorkHours3Enabled === true,
      then: (schema) => {
        return schema
          .test(
            "check-hours",
            t("strGen:validation.workhours.hoursrequired"),
            function (value, context) {
              return validateHours(
                value,
                context.parent.AltHours2,
                context.parent.AltHours3
              );
            }
          )
          .test(
            "max-two-decimal-places",
            t("strGen:validation.maxdecimals", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.regularhours"
              ),
              count: 2,
            }),
            function (value) {
              return maxDecimalPlaces(value, 2);
            }
          )
          .typeError(
            t("strGen:validation.typeerror.number", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.regularhours"
              ),
            })
          );
      },
      otherwise: (schema) =>
        schema
          .required(
            t("strGen:validation.required", {
              fieldname: t("objBen:objects.workhour.detail.fields.hours"),
            })
          )
          .test(
            "is-non-zero",
            t("strGen:validation.nonzero", {
              fieldname: t("objBen:objects.workhour.detail.fields.hours"),
            }),
            function (value) {
              return value !== 0;
            }
          )
          .test(
            "max-two-decimal-places",
            t("strGen:validation.maxdecimals", {
              fieldname: t("objBen:objects.workhour.detail.fields.hours"),
              count: 2,
            }),
            function (value) {
              return maxDecimalPlaces(value, 2);
            }
          )
          .typeError(
            t("strGen:validation.typeerror.number", {
              fieldname: t("objBen:objects.workhour.detail.fields.hours"),
            })
          ),
    }),
    AltHours2: yup.number().when([], {
      is: () => altWorkHours2Enabled === true,
      then: (schema) => {
        return schema
          .test(
            "check-hours",
            t("strGen:validation.workhours.hoursrequired"),
            function (value, context) {
              return validateHours(
                context.parent.RegularHours,
                value,
                context.parent.AltHours3
              );
            }
          )
          .test(
            "max-two-decimal-places",
            t("strGen:validation.maxdecimals", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.althours1full"
              ),
              count: 2,
            }),
            function (value) {
              return maxDecimalPlaces(value, 2);
            }
          )
          .typeError(
            t("strGen:validation.typeerror.number", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.althours1full"
              ),
            })
          );
      },
      otherwise: (schema) => schema.nullable(),
    }),
    AltHours3: yup.number().when([], {
      is: () => altWorkHours3Enabled === true,
      then: (schema) => {
        return schema
          .test(
            "check-hours",
            t("strGen:validation.workhours.hoursrequired"),
            function (value, context) {
              return validateHours(
                context.parent.RegularHours,
                context.parent.AltHours2,
                value
              );
            }
          )
          .test(
            "max-two-decimal-places",
            t("strGen:validation.maxdecimals", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.althours2full"
              ),
              count: 2,
            }),
            function (value) {
              return maxDecimalPlaces(value, 2);
            }
          )
          .typeError(
            t("strGen:validation.typeerror.number", {
              fieldname: t(
                "objBen:objects.workhour.detail.fields.althours2full"
              ),
            })
          );
      },
      otherwise: (schema) => schema.nullable(),
    }),
  });
};
