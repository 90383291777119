import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetchIndigenousGroups } from "features/datalists/IndigenousGroupSlice";
import { fetchClientGroups } from "./ClientGroupSlice";
import { IppSkeleton } from "components/IppSkeleton";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { addClientGroup, delClientGroup } from "./ClientGroupSlice";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "ClientGroupForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface listValue {
  itemID: number;
  itemLabel: string;
  wasSelected: boolean; // to track deletions
  clientGroupID: number;
  employeeCount: number;
  companyCount: number;
}

export const ClientGroupForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);

  // allGroups contains unfiltered list of groups
  // filteredGroups contains list of groups with filter applied
  // selectedGroups contains list of items selected
  const [allGroups, setAllGroups] = useState<listValue[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<listValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<listValue[]>([]);
  const [filterValue, setFilterValue] = useState("");

  const { clientId } = useSelector((state: RootState) => state.client);

  const {
    clientGroupList,
    clientGroupsById,
    isLoading: clientGroupIsLoading,
    error: clientGroupError,
  } = useSelector((state: RootState) => state.clientGroups);

  const clientGroups = clientGroupList.map((id) => clientGroupsById[id]);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    isLoading: indigenousGroupIsLoading,
    error: indigenousGroupError,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (id) => indigenousGroupsById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchIndigenousGroups(accessToken));
        dispatch(fetchClientGroups(accessToken));
        setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (
      pageLoaded &&
      !indigenousGroupIsLoading &&
      !clientGroupIsLoading &&
      allGroups.length === 0
    ) {
      initializeLists();
    }
  }, [pageLoaded, indigenousGroupIsLoading, clientGroupIsLoading]);

  const initializeLists = () => {
    // initialize full list
    let data1: listValue[] = [];
    indigenousGroups.forEach((item) => {
      // check if item is already selected
      const foundItem = clientGroups.find((obj) => {
        return obj.IndigenousGroupID === item.IndigenousGroupID;
      });
      if (!foundItem) {
        let n: listValue = {
          itemID: item.IndigenousGroupID,
          itemLabel: item.IndigenousGroupName,
          wasSelected: false,
          clientGroupID: -1,
          employeeCount: 0,
          companyCount: 0,
        };
        data1.push(n);
      }
    });
    setAllGroups(data1);
    setFilteredGroups(data1);

    let data2 = clientGroups.map((item) => {
      let n: listValue = {
        itemID: item.IndigenousGroupID,
        itemLabel: item.IndigenousGroupName,
        wasSelected: true,
        clientGroupID: item.ClientGroupID,
        employeeCount: item.EmployeeCount,
        companyCount: item.CompanyCount,
      };
      return n;
    });
    setSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });
    // Delete items marked for removal
    // if item was selected and is now in all groups,
    // then item was REMOVED
    allGroups.forEach((item) => {
      if (item.wasSelected) {
        dispatch(delClientGroup(accessToken, item.clientGroupID));
      }
    });

    // insert new items
    selectedGroups.forEach((item) => {
      if (!item.wasSelected) {
        const newItem = { ClientID: clientId, IndigenousGroupID: item.itemID };
        dispatch(addClientGroup(accessToken, newItem));
      }
    });
    dispatch(openSnackBar("Updates completed", "success"));
    dispatch(push("/admin/clientgroups"));
  };

  const handleToggleRight = (value: listValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredGroups((oldArray) =>
      filteredGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const handleToggleLeft = (value: listValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.itemLabel.toLowerCase(),
          fb = b.itemLabel.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );

    setFilteredGroups(
      filteredGroups.filter(function (a) {
        return a !== value;
      })
    );
  };

  const onFilterChange = (e: any) => {
    const val = e.target.value || "";
    setFilterValue(val);
    if (val === "") {
      setFilteredGroups(allGroups);
    } else {
      setFilteredGroups(
        allGroups.filter(function (a) {
          return a.itemLabel.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  let fullList = indigenousGroupIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.itemID}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList = clientGroupIsLoading ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {selectedGroups.map((item) => {
          return item.employeeCount > 0 || item.companyCount > 0 ? (
            <Tooltip
              title={
                "Item cannot be removed; " +
                item.employeeCount +
                " employee(s) and " +
                item.companyCount +
                " companies with this affiliation"
              }
            >
              <ListItem button role="listitem" key={item.itemID}>
                <ListItemIcon>
                  <Lock />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          ) : (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.itemID}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.itemLabel} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                {t("objPlt:platformwide.fields.indigenousgroup_other")}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                {`This option allows you to select the 
                ${t(
                  "objPlt:platformwide.fields.indigenousgroup_other"
                )} you wish to
                have appear in dropdown lists. Only the items you select will be
                available to users. Selecting an ${t(
                  "objPlt:platformwide.fields.indigenousgroup"
                )} from one list
                will move it into the other list. Once you have completed your
                changes, click`}
                <strong> {t("strGen:buttons.savechanges")} </strong> to finalize
                them.
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">Available Groups</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search for..."
                value={filterValue}
                onChange={onFilterChange}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Selected Groups</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
