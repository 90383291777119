import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ActionReminder,
  ActionRemindersResult,
  getActionReminders,
  createActionReminder,
  updateActionReminder,
  deleteActionReminder,
} from "api/actionReminderAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";

interface ActionReminderState {
  actionRemindersById: Record<number, ActionReminder>;
  actionReminderList: number[];
  isLoading: boolean;
  error: string | null;
}

const ActionReminderInitialState: ActionReminderState = {
  actionRemindersById: {},
  actionReminderList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ActionReminderState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ActionReminderState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const actionReminders = createSlice({
  name: "actionReminders",
  initialState: ActionReminderInitialState,
  reducers: {
    getActionRemindersStart: startLoading,
    getActionRemindersSuccess(
      state,
      { payload }: PayloadAction<ActionRemindersResult>
    ) {
      const { actionReminders } = payload;
      // console.log("PAYLOAD", actionReminders);
      state.isLoading = false;
      state.error = null;

      actionReminders.forEach((actionReminder) => {
        state.actionRemindersById[actionReminder.ActionReminderID] =
          actionReminder;
      });

      state.actionReminderList = actionReminders.map(
        (actionReminder) => actionReminder.ActionReminderID
      );
    },
    getActionRemindersFailure: loadingFailed,
    createActionReminderStart: startLoading,
    createActionReminderSuccess(
      state,
      { payload }: PayloadAction<ActionReminder>
    ) {
      const { ActionReminderID } = payload;
      state.actionRemindersById[ActionReminderID] = payload;
      state.actionReminderList.push(ActionReminderID);

      state.isLoading = false;
      state.error = null;
    },
    updateActionReminderSuccess(
      state,
      { payload }: PayloadAction<ActionReminder>
    ) {
      const { ActionReminderID } = payload;
      state.actionRemindersById[ActionReminderID] = payload;

      state.isLoading = false;
      state.error = null;
    },
    deleteActionReminderSuccess(state, { payload }: PayloadAction<number>) {
      const ActionReminderID = payload;
      delete state.actionRemindersById[ActionReminderID];
      state.actionReminderList = state.actionReminderList.filter(
        (item) => item !== ActionReminderID
      );

      state.isLoading = false;
      state.error = null;
    },
    createActionReminderFailure: loadingFailed,
  },
});

export const {
  getActionRemindersStart,
  getActionRemindersSuccess,
  getActionRemindersFailure,
  createActionReminderStart,
  createActionReminderSuccess,
  updateActionReminderSuccess,
  deleteActionReminderSuccess,
  createActionReminderFailure,
} = actionReminders.actions;

export default actionReminders.reducer;

export const fetchActionReminders =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getActionRemindersStart());
      const actionReminders = await getActionReminders(accessToken);
      //console.log("RETURN", actionReminders);
      dispatch(getActionRemindersSuccess(actionReminders));
    } catch (err: any) {
      dispatch(getActionRemindersFailure(err.toString()));
    }
  };

export const addActionReminder =
  (accessToken: String, newActionReminder: Partial<ActionReminder>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionReminderStart());
      const actionReminder = await createActionReminder(
        accessToken,
        newActionReminder
      );
      dispatch(createActionReminderSuccess(actionReminder));
      dispatch(push("/admin/actionReminder"));
    } catch (err: any) {
      dispatch(createActionReminderFailure(err.toString()));
    }
  };

export const updActionReminder =
  (
    accessToken: String,
    actionReminderID: number,
    newActionReminder: Partial<ActionReminder>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionReminderStart());
      const actionReminder = await updateActionReminder(
        accessToken,
        actionReminderID,
        newActionReminder
      );
      dispatch(updateActionReminderSuccess(actionReminder));
      dispatch(push("/admin/actionReminder"));
    } catch (err: any) {
      dispatch(createActionReminderFailure(err.toString()));
    }
  };

export const delActionReminder =
  (accessToken: String, actionReminderID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createActionReminderStart());
      const result = await deleteActionReminder(accessToken, actionReminderID);
      dispatch(deleteActionReminderSuccess(actionReminderID));
      dispatch(push("/admin/actionReminder"));
    } catch (err: any) {
      dispatch(createActionReminderFailure(err.toString()));
    }
  };
