import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { fetchUsers } from "features/users/UsersSlice";
import { IppAutocomplete } from "./IppAutocomplete";
import { User } from "api/usersAPI";
import { fetchAllUserRoles } from "features/roles/UserRoleSlice";
import { useTypedTranslation } from "utils/customHooks";
import { PersonRenderOption } from "utils/renderFunctions";

interface IppPersonResponsibleProps {
  id: string;
  value: any;
  projectID: number;
  onChangeFunction: any;
  label: string;
  isEditing: boolean;
  setIsEditing: any;
  optionLabelFunction?: any;
  touchedFunction?: any;
  errorFunction?: any;
  textValueFunction?: any;
  disabled?: boolean;
  required?: boolean;
  toolTip?: string;
  autoPopulate?: boolean;
}

export const IppPersonResponsible = (props: IppPersonResponsibleProps) => {
  const {
    id,
    value,
    projectID,
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    optionLabelFunction,
    touchedFunction,
    errorFunction,
    textValueFunction,
    disabled,
    required,
    toolTip,
    autoPopulate,
  } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    userList,
    usersById,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  const { userRoleList, userRolesById } = useSelector(
    (state: RootState) => state.userRoles
  );

  const userRoles = userRoleList.map((id) => userRolesById[id]);
  //console.log("pathname", window.location.pathname);

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.find((role) => {
        if (/engagement/.test(window.location.pathname)) {
          return (
            item.UserAccountID === role.UserAccountID &&
            role.ProjectID === projectID &&
            role.ModuleRoleID === 5
          );
        } else {
          return (
            item.UserAccountID === role.UserAccountID &&
            role.ProjectID === projectID &&
            (role.ModuleRoleID === 3 || role.ModuleRoleID === 4)
          );
        }
      });
      return found;
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchAllUserRoles(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectID, dispatch, getAccessTokenSilently]);

  // checks current user is in current drop down, otherwise clear person responsible field
  useEffect(() => {
    (async () => {
      try {
        if (users && value && userRoles.length > 0) {
          let userExists = false;
          for (let i = 0; i < users.length; i++) {
            if (users[i].UserAccountID === value.UserAccountID) {
              userExists = true;
              break;
            }
          }
          if (!userExists) {
            onChangeFunction();
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [users]);

  return (
    <IppAutocomplete
      id={id}
      options={users}
      renderOption={(props: any, option: User) =>
        PersonRenderOption(props, option, [option.EmailAddress], "")
      }
      value={value}
      onChangeFunction={onChangeFunction}
      label={label}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      optionLabelFunction={optionLabelFunction}
      errorFunction={errorFunction}
      touchedFunction={touchedFunction}
      textValueFunction={textValueFunction}
      noOptionsText={t("strGen:components.noqualifieduser")}
      disabled={!currentProfile.IsClient || disabled || userIsLoading}
      autoPopulate={autoPopulate}
      required={required}
      toolTip={toolTip}
    />
  );
};
