import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppTextField } from "components/IppTextField";
import { addCompany } from "features/benefits/companies/CompaniesSlice";
import { Company } from "api/companyAPI";
import { useTypedTranslation } from "utils/customHooks";
import { IppLocationAutoComplete } from "components/IppLocationAutoComplete";
import { CompanyValidationSchema } from "./CompanyValidation";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { IppDiversityCheckboxes } from "components/IppDiversityCheckboxes";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface CompanyChildFormProps {
  onClose: any;
  didSaveToken?: any;
  parentTitle?: string;
  company?: any;
}

export const CompanyChildForm = (props: CompanyChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { onClose } = props;

  const { clientId, ft_ben_Region } = useSelector(
    (state: RootState) => state.client
  );

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (IndigenousGroupID) => indigenousGroupsById[IndigenousGroupID]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchIndigenousGroupsByClient(accessToken));
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Company"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(addCompany(accessToken, values.ClientID, values));
        onClose();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  //let thisDate = new Date();
  const [newCompany, setNewCompany] = useState<Partial<Company>>({
    CompanyID: -1,
    CompanyName: "",
    VendorID: "",
    Address: "",
    TownCity: "",
    ProvinceState: "",
    CountryName: "",
    PostalCode: "",
    CategoryID: undefined,
    IndigenousGroupID: undefined,
    Indigenous: false,
    IndigenousGroupRaw: "",
    WomanOwned: false,
    VeteranOwned: false,
    Disability: false,
    VisibleMinority: false,
    Local: false,
    CompanySourceID: 3,
    ClientID: clientId,
    CompanyStructureID: 1,
    NativeAmerican: false,
    IsActive: true,
    EmployeeCount: 0,
    ProjectCount: 0,
    LastPaymentDate: undefined,
    ListingConfirmed: false,
    ShowAsActive: "Active",
    CompanyNotes: "",
    RegionID: undefined,
  });

  const formik = useFormik({
    initialValues: newCompany,
    validationSchema: CompanyValidationSchema({
      isChildSchema: true,
      regionEnabled: hasRegionTypes,
    }),
    onSubmit: onSub,
    enableReinitialize: true,
  });

  let editForm = (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <p></p>
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="CompanyName"
            label={t("objBen:objects.company.fields.companyname")}
            required={true}
            value={formik.values.CompanyName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CompanyName}
            errorsExpression={formik.errors.CompanyName}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <IppLocationAutoComplete
          formik={formik}
          isEditing={true}
          setIsEditing={null}
          townCityOptions={{ show: true, required: true }}
          provinceStateOptions={{ show: true, required: true }}
          countryOptions={{ show: true, required: true }}
        />
        {hasRegionTypes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="RegionID"
              options={regions}
              value={regions.find((obj) => {
                return obj.RegionID === formik.values.RegionID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("RegionID", newValue.RegionID);
                } else {
                  formik.setFieldValue("RegionID", undefined);
                }
              }}
              label={t("objBen:objects.company.fields.region.name")}
              required={true}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.RegionName}
              touchedFunction={formik.touched.RegionID}
              errorFunction={formik.errors.RegionID}
              textValueFunction={formik.values.RegionName}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppCheckbox
            id="Indigenous"
            label={t("objPlt:platformwide.fields.indigenouscountry")}
            value={formik.values.Indigenous as boolean}
            onChangeFunction={async (event: ChangeEvent, newValue: any) => {
              formik.setFieldValue("Indigenous", newValue);
              if (!newValue) {
                await formik.setFieldValue("IndigenousGroupID", null);
              }
            }}
            isEditing={true}
          />
        </Grid>
        {formik.values.Indigenous && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="IndigenousGroupID"
              options={indigenousGroups}
              value={indigenousGroups.find((obj) => {
                return (
                  obj.IndigenousGroupID === formik.values.IndigenousGroupID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "IndigenousGroupID",
                    newValue.IndigenousGroupID
                  );
                } else {
                  formik.setFieldValue("IndigenousGroupID", null);
                }
              }}
              label={t("objPlt:platformwide.fields.indigenousgroup")}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.IndigenousGroupName}
              touchedFunction={formik.touched.IndigenousGroupID}
              errorFunction={formik.errors.IndigenousGroupID}
              textValueFunction={
                !indigenousGroupIsLoading &&
                formik.values.IndigenousGroupID &&
                formik.values.IndigenousGroupID > 0 &&
                indigenousGroupsById[formik.values.IndigenousGroupID]
                  ? indigenousGroupsById[formik.values.IndigenousGroupID]
                      .IndigenousGroupName
                  : ""
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <IppDiversityCheckboxes
            parentTitle="Company"
            isEditing={true}
            handleChange={formik.handleChange}
            local={formik.values.Local}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id="CompanyNotes"
            label="Notes"
            value={formik.values.CompanyNotes}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CompanyNotes}
            errorsExpression={formik.errors.CompanyNotes}
            isEditing={true}
            setIsEditing={null}
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={true}
            setIsEditing={null}
            isAdding={true}
            showCancel={true}
            resetFunction={onClose}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="company-child-form">{editForm} </div>;
};
