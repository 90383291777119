import { useFormik } from "formik";
import { ThreadValidationSchema } from "./ThreadValidation";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useTypedTranslation } from "utils/customHooks";
import { useState } from "react";
import { addThread, updThread } from "./ThreadSlice";
import { Grid, Typography } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppFormButtons } from "components/Buttons/IppFormButtons";

interface threadChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  thread?: any; // existing interaction to be edited
  canWRITE?: boolean; // check if can be edited
}

export const ThreadChildForm = (props: threadChildFormProps) => {
  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentValue,
    thread,
    canWRITE,
  } = props;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  let itemData: any;

  if (thread) {
    itemData = thread;
  } else {
    itemData = {
      ThreadID: "",
      ProjectID: parentValue?.ProjectID,
      Summary: "",
      interactions: {},
    };
  }

  const [isEditing, setIsEditing] = useState(true);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        values.interactions = parentValue
          ? { InteractionID: parentValue?.InteractionID }
          : {};
        await dispatch(
          addThread(
            accessToken,
            values,
            true,
            `/engagement/communications/${parentValue?.InteractionID}`
          )
        );
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (thread) {
    submitFunc = async (values: any) => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          updThread(
            accessToken,
            values.ThreadID,
            values,
            false,
            parentValue?.InteractionID
          )
        );
        closeAction();
      } catch (e) {
        console.error(e);
      }
    };
  }

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ThreadValidationSchema(t),
    onSubmit: submitFunc,
  });

  let detailForm = (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {thread && isEditing && (
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("strGen:prompts.edit.edittitle", {
                fieldname: t("objStk:objects.thread.name"),
              })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <IppTextField
            id="ThreadTitle"
            required={true}
            label={t("objStk:objects.thread.fields.title")}
            value={formik.values.ThreadTitle}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ThreadTitle}
            touchedExpression={formik.touched.ThreadTitle}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={12}>
          <IppTextField
            id={"ProjectName"}
            label={t("objStk:objects.thread.fields.project")}
            value={parentValue?.ProjectName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disabled={true}
            onChangeFunction={() => {}}
          />
        </Grid>

        <Grid item xs={12}>
          <IppRichTextEditor
            id="Summary"
            label={t("objStk:objects.thread.fields.summary")}
            value={formik.values.Summary}
            isEditing={isEditing}
            touchedExpression={formik.touched.Summary}
            errorsExpression={formik.errors.Summary}
            setFieldValue={formik.setFieldValue}
            standardStatementsRecordType="InteractionThreadSummary"
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={!thread}
            setIsEditing={setIsEditing}
            showCancel={true}
            submitDisabled={canWRITE === false}
            resetFunction={() => {
              closeAction();
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
  return <div id="thread-form">{detailForm}</div>;
};
