import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";

import Paper from "@mui/material/Paper";
import LoadingIndicator from "components/LoadingIndicator";
import { Box, Typography } from "@mui/material";
import {
  Apps,
  ContactSupportOutlined,
  ExitToApp,
  Key,
  NotificationsNone,
  Settings,
} from "@mui/icons-material";
import { push } from "redux-first-history";
import { useTypedTranslation } from "utils/customHooks";

const PREFIX = "PlatformHomePage";

const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  page: `${PREFIX}-page`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.pageContainer}`]: {
    width: 800,
  },

  [`& .${classes.page}`]: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const PlatformHomePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );
  const clientModules = clientModuleList.map((id) => clientModulesById[id]);

  useEffect(() => {
    (async () => {
      try {
        // If there's only one module, go to Module Homepage
        if (clientModules?.length === 1) {
          dispatch(push(clientModules[0]?.ShortName));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  let platformView = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <div className={classes.pageContainer}>
            <Typography component="h1" variant="h5">
              <img
                src={process.env.PUBLIC_URL + "/iconSmall.png"}
                alt="NetBenefits"
              />
              {t("strGen:pages.home.title")}
            </Typography>
            <p>{t("strGen:pages.home.bodytext1")}</p>
            <p>{t("strGen:pages.home.bodytext2")}</p>
            <p>{t("strGen:pages.home.bodytext3")}</p>
            <table>
              <tr>
                <td>
                  <Apps />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.appmenu")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <NotificationsNone />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.notifications")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <ContactSupportOutlined />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.feedback")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Settings />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.admin")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Key />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.passchange")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <ExitToApp />
                </td>
                <td>
                  <Typography variant="caption">
                    {t("strGen:pages.home.icons.logout")}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </table>

            <p>{t("strGen:pages.home.assistance")}</p>
          </div>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="platform-home-page">{platformView}</div>;
};
