import axiosClient from "./axiosClient";

export interface CommunityInvestment {
  CommunityInvestmentID: number;
  OrganizationName: string;
  TownCity: string;
  ProvinceState: string;
  CountryName: string;
  NativeAmerican: boolean;
  Indigenous: boolean;
  SpendAmount: number;
  IndigenousGroupID: number;
  CommunityInvestmentTypeID: number;
  InvestmentDate: Date;
  InvestmentNotes: string;
  CompanyID: number;
  ProjectID: number;
  ProjectComponentID: number;
  ProjectName: string;
  Description: string;
  CommunityInvestmentGroup: string;
}

export interface CommunityInvestmentsResult {
  communityInvestments: CommunityInvestment[];
}

export async function getCommunityInvestments(
  accessToken: String
): Promise<CommunityInvestmentsResult> {
  const url = `/communityInvestment`;

  try {
    const { data } = await axiosClient.get<CommunityInvestmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommunityInvestmentsByCompany(
  accessToken: String,
  projCompID: number
): Promise<CommunityInvestmentsResult> {
  const url = `/communityInvestment/${projCompID}`;

  try {
    const { data } = await axiosClient.get<CommunityInvestmentsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommunityInvestment(
  accessToken: String,
  newCommInv: Partial<CommunityInvestment>
): Promise<CommunityInvestment> {
  const url = `/communityInvestment`;

  try {
    const { data } = await axiosClient.post<CommunityInvestment>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (error: any) {
    throw error;
  }
}

export async function updateCommunityInvestment(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<CommunityInvestment>
): Promise<CommunityInvestment> {
  const url = `/communityInvestment/` + commInvID;

  try {
    const result = await axiosClient.post<CommunityInvestment>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteCommunityInvestment(
  accessToken: String,
  commInvID: number,
  communityInvestment: Partial<CommunityInvestment>
): Promise<string> {
  const url = `/communityInvestment/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        source: communityInvestment,
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}
