import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Close, Delete } from "@mui/icons-material";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  fetchAttachments,
  deleteFileAttachment,
} from "features/platform/AttachmentSlice";
import LoadingIndicator from "./LoadingIndicator";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { AttachmentUploadDialog } from "features/platform/attachments/AttachmentFileDialog";

import ConfirmDialog from "./ConfirmDialog";
import { ConvertDateOffset } from "utils/DateFunctions";
import { AttachmentLinkDialog } from "features/platform/attachments/AttachmentLinkDialog";
import { getClickableLink } from "utils/urlUtils";
import { IppButton } from "./Buttons/IppButton";
import { IppAttachUploadButton } from "./Buttons/IppAttachUploadButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";

const PREFIX = "IppAttachmentInventory";

const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export type filterNames = "boolean" | "numeric" | "date" | "text";

interface Props {
  itemID: number;
  recordType: string;
  companyID: number;
  projectID: number;
  moduleID: number;
  disabled?: boolean;
  title?: string;
  isModalView?: boolean;
  handleModalClose?: () => void;
  objectName?: string;
}

export const IppAttachmentInventory = (props: Props) => {
  const { location } = useSelector((state: RootState) => state.router);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const {
    itemID,
    companyID,
    projectID,
    moduleID,
    title,
    disabled = false,
    isModalView = false,
    handleModalClose = () => {},
    objectName,
  } = props;

  const recType = props.recordType;

  const [addFileOpen, setAddFileOpen] = useState(false);
  const [addLinkOpen, setAddLinkOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const handleFileAddClose = () => {
    setAddFileOpen(false);
  };

  const shouldShowAttachmentInventory = (): boolean => {
    if (isModalView && (addFileOpen || addLinkOpen)) return false;

    return true;
  };

  const { clientId } = useSelector((state: RootState) => state.client);

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  // set canWRITE to true for Client Admins
  let canWRITE = true;
  let pathName: string = `${location.pathname}`;

  // if the User is not an Admin, check their Role relating to the parent Record
  if (!currentProfile.IsClientAdmin && pathName.includes("/engagement")) {
    const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

    if (
      recType.toLowerCase() === "communications" ||
      recType.toLowerCase() === "interactionactions" ||
      recType.toLowerCase() === "grievances"
    ) {
      // Must have Stakeholder PowerUser Role for the Parent Project
      canWRITE = userRoles.some(
        (role) => role.ProjectID === projectID && role.ModuleRoleID === 5
      );
    }
    if (
      recType.toLowerCase() === "stakeholdergroups" ||
      recType.toLowerCase() === "contacts"
    ) {
      // Must have a Role with WRITE access on either Stakeholder or Commitments
      canWRITE = userRoles.some(
        (role) =>
          role.ModuleRoleID === 3 ||
          role.ModuleRoleID === 4 ||
          role.ModuleRoleID === 5
      );
    }
    if (recType.toLowerCase() === "issues") {
      // Must have Stakeholder PowerUser Role
      canWRITE = userRoles.some((role) => role.ModuleRoleID === 5);
    }
  }

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList, isLoading } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const attch = attachments.map((a) => {
    // This is to adjust the date

    let newAttach = Object.assign({}, a);
    let attachDate = new Date(newAttach.UploadDate?.toString().slice(0, 10));
    newAttach.UploadDate = ConvertDateOffset(attachDate);

    // This is to adjust the uploaded by
    if (newAttach.FirstName && newAttach.Surname) {
      newAttach.UploadedBy = newAttach.FirstName + " " + newAttach.Surname;
    } else
      newAttach.UploadedBy = t("objPlt:objects.attachment.externalcontact");

    return newAttach;
  });

  const downloadAttach = (props: any) => {
    const dataURL = props.dataItem.FilePath;
    dispatch(openSnackBar(snackbarConstants.DOWNLOAD_REQUESTED, "info"));
    window.open(dataURL, "_blank");
  };

  const delAttach = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        //console.log("DELEID", deleteID);
        dispatch(
          deleteFileAttachment(accessToken, deleteID, snackbarConstants)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const nameCell = (props: GridCellProps) =>
    props.dataItem.LinkTitle === null ? (
      <td onClick={(e) => downloadAttach(props)}>
        <Tooltip title={t("objPlt:objects.attachment.download")}>
          <div style={{ cursor: "pointer", color: "blue" }}>
            {props.dataItem[props.field!]}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td
        onClick={() =>
          window.open(getClickableLink(props.dataItem.Link), "_blank")
        }
      >
        <Tooltip title={t("objPlt:objects.attachment.open")}>
          <div style={{ cursor: "pointer", color: "blue" }}>
            {props.field && props.dataItem.LinkTitle}
          </div>
        </Tooltip>
      </td>
    );

  const deleteCell = (props: any) => {
    // Hides Options Buttons completely if User is View Only
    if (canWRITE) {
      return (
        <td>
          {
            <Tooltip title={t("objPlt:objects.attachment.delete")}>
              <div
                style={{ cursor: "pointer", color: "blue" }}
                onClick={(e) => {
                  if (!canWRITE) {
                    return null;
                  }
                  setDeleteID(props.dataItem.AttachmentID);
                  setConfirmOpen(true);
                }}
              >
                <IppButton
                  variant="text"
                  startIcon={<Delete />}
                  disabled={disabled || !canWRITE}
                  color={"error"}
                />
              </div>
            </Tooltip>
          }
        </td>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAttachments(accessToken, recType, itemID));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, recType, itemID, dispatch, getAccessTokenSilently]);

  const attachmentFooterMessage = isModalView
    ? t("strGen:pages.attachments.closepopupmessage")
    : t("strGen:pages.attachments.backgrounduploadmessage");

  //is loading currently only based on client how to get other if it is dynamic
  let attachInvview = (
    <Root>
      {shouldShowAttachmentInventory() && (
        <Box display="flex" justifyContent="center" minWidth="100%">
          <>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="h6">
                  {title || t("objPlt:objects.attachment.name", { count: 2 })}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container justifyContent="flex-end">
                  {canWRITE && (
                    <>
                      <IppAttachUploadButton
                        onClick={() => setAddFileOpen(true)}
                        disabled={disabled || !canWRITE}
                      />
                      {currentProfile.IsClient && (
                        <IppAttachUploadButton
                          isLinkAttachment
                          onClick={() => setAddLinkOpen(true)}
                          disabled={disabled || !canWRITE}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              {isLoading || isUploading ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <LoadingIndicator
                    headerMessage={
                      isUploading
                        ? t("strGen:pages.attachments.beinguploaded")
                        : undefined
                    }
                    footerMessage={
                      isUploading ? attachmentFooterMessage : undefined
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <KendoGrid
                    data={attch.slice(skip, (take ? take : 10) + (skip || 0))}
                    pageable={{
                      buttonCount: 5,
                      info: true,
                      // type: "input",
                      pageSizes: [5, 10, 25, 50, 100],
                    }}
                    skip={skip}
                    take={take}
                    total={attch.length}
                    onPageChange={pageChange}
                    onRowClick={downloadAttach}
                  >
                    <GridNoRecords>
                      **{" "}
                      {t("objPlt:objects.attachment.noneadded", { count: 2 })}.
                      **
                    </GridNoRecords>
                    <GridColumn
                      cell={nameCell}
                      field="FileName"
                      title={t("objPlt:objects.attachment.fields.filename")}
                    />
                    <GridColumn
                      field="UploadedBy"
                      title={t("objPlt:objects.attachment.fields.uploadedby")}
                    />
                    <GridColumn
                      field="UploadDate"
                      title={t("objPlt:objects.attachment.fields.uploaddate")}
                      filter="date"
                      format="{0:yyyy-MM-dd}"
                    />
                    <GridColumn
                      field="FileDescription"
                      title={t("objPlt:objects.attachment.fields.description")}
                    />

                    <GridColumn
                      cell={deleteCell}
                      width="100px"
                      field="AttachmentID"
                      title=" "
                    />
                  </KendoGrid>
                </Grid>
              )}

              {isModalView && (
                <Grid item xs={12} container justifyContent="flex-start">
                  <IppCancelButton
                    buttonText={t("strGen:buttons.close")}
                    onClick={handleModalClose}
                    startIcon={<Close />}
                  />
                </Grid>
              )}
            </Grid>
          </>
        </Box>
      )}
      <AttachmentUploadDialog
        isOpen={addFileOpen}
        handleClose={handleFileAddClose}
        recordType={recType}
        recordID={itemID}
        companyID={companyID}
        projectID={projectID}
        moduleID={moduleID}
        setIsUploading={setIsUploading}
        objectName={objectName}
      />
      <AttachmentLinkDialog
        isOpen={addLinkOpen}
        handleClose={() => setAddLinkOpen(false)}
        recordType={recType}
        recordID={itemID}
        companyID={companyID}
        projectID={projectID}
        moduleID={moduleID}
        setIsUploading={setIsUploading}
        objectName={objectName}
      />

      <ConfirmDialog
        title={t("objPlt:objects.attachment.delete") + "?"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={delAttach}
      >
        {t("strGen:prompts.delete.deleteinquiry")}
      </ConfirmDialog>
    </Root>
  );

  return <div id="attachment-inventory-view">{attachInvview}</div>;
};
