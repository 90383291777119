import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchEmployeesForUploadReview } from "./EmployeeSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { useTypedTranslation } from "utils/customHooks";

interface employeeInvProps {
  companyID?: number;
}

export const EmployeeReviewInventoryPage = (props: employeeInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const compID = props.companyID;

  const { ft_ben_Region, ft_ben_JobPosition, ft_ben_CustomJobPosition } =
    useSelector((state: RootState) => state.client);

  const {
    employeeList,
    isLoading,
    error: employeesError,
    employeesById,
  } = useSelector((state: RootState) => state.employees);

  const employees = employeeList.map((id) => employeesById[id]);

  const data = employees.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.LastActive !== null) {
      n.LastActive = ConvertDateOffset(p.LastActive);
    } else {
      n.LastActive = null;
    }

    return n;
  });

  const empCols = [
    { field: "CompanyEmployeeID", title: "Employee ID" },
    { field: "CompanyName", title: "Employer" },
    {
      field: "GenderText",
      title: t("objBen:objects.employee.fields.gender"),
    },
    { field: "ProvinceState", title: "Province/State" },
    { field: "CountryName", title: "Country" },
    ...(ft_ben_Region
      ? [
          {
            field: "RegionName",
            title: t("objBen:objects.employee.fields.region"),
          },
        ]
      : []),
    ...(ft_ben_JobPosition
      ? [
          {
            field: "JobPositionName",
            title: t("objBen:objects.employee.fields.jobposition"),
          },
        ]
      : []),
    ...(ft_ben_CustomJobPosition
      ? [
          {
            field: "CustomJobPositionRaw",
            title: t("objBen:objects.employee.fields.customJobPosition"),
          },
        ]
      : []),
    {
      field: "LastActive",
      title: "Last Active",
      filter: "date",
      format: "{0:d}",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEmployeesForUploadReview(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [compID, dispatch, getAccessTokenSilently]);

  if (employeesError) {
    return <ErrorPage errMsg={employeesError} />;
  }

  let employeeInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Employees Needing Review"
        tableData={data}
        idField="EmployeeID"
        columns={empCols}
        isFiltered={true}
        showDetail={true}
        showAdd={false}
        showUpload={false}
        showTemplate={false}
        searchByColumn={"CompanyEmployeeID"}
        searchByPlaceholder={"Search by Employee ID"}
      />
    </div>
  );

  return <div id="employee-inventory-page">{employeeInvView}</div>;
};
