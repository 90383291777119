import axiosClient from "./axiosClient";

export interface WorkHourPeriod {
  PeriodID: number;
  ProjectComponentID: number;
  CompanyID: number;
  StartDate: Date;
  EndDate: Date;
  ProjectID: number;
  EmployeeCount: number;
  RegHours: number;
  AltHours2: number;
  AltHours3: number;
  SequenceID: number;
  ContractID: number;
  ContractorID: number | null;
  ContractorName?: string;
}

export interface WorkHourPeriodsResult {
  workHourPeriods: WorkHourPeriod[];
}

export async function getWorkHourPeriods(
  accessToken: String
): Promise<WorkHourPeriodsResult> {
  const url = `/workHourPeriod`;

  try {
    const { data } = await axiosClient.get<WorkHourPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getWorkHourPeriodsByProject(
  accessToken: String,
  projectID: number
): Promise<WorkHourPeriodsResult> {
  const url = `/workHourPeriod/${projectID}`;

  try {
    const { data } = await axiosClient.get<WorkHourPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getWorkHourPeriodsByCompany(
  accessToken: String,
  companyID: number
): Promise<WorkHourPeriodsResult> {
  const url = `/workHourPeriod/Company/${companyID}`;

  try {
    const { data } = await axiosClient.get<WorkHourPeriodsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createWorkHourPeriod(
  accessToken: String,
  newWorkHourPeriod: Partial<WorkHourPeriod>
): Promise<WorkHourPeriod> {
  const url = `/workHourPeriod`;

  try {
    const { data } = await axiosClient.post<WorkHourPeriod>(
      url,
      newWorkHourPeriod,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateWorkHourPeriod(
  accessToken: String,
  workHourPeriodID: number,
  newWorkHourPeriod: Partial<WorkHourPeriod>
): Promise<WorkHourPeriod> {
  const url = `/workHourPeriod/` + workHourPeriodID;

  try {
    const { data } = await axiosClient.post<WorkHourPeriod>(
      url,
      newWorkHourPeriod,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteWorkHourPeriod(
  accessToken: String,
  workHourPeriodID: number
): Promise<string> {
  const url = `/workHourPeriod/` + workHourPeriodID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    console.log("DELDATA", data);
    return data;
  } catch (err: any) {
    throw err;
  }
}
