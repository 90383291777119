import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { Key } from "@mui/icons-material";
import * as yup from "yup";
import "yup-phone";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory, Prompt } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { IppFormHeader } from "components/IppFormHeader";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import { IppCheckbox } from "components/IppCheckbox";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchModuleRoles } from "features/roles/ModuleRoleSlice";
import { addUserRole, fetchUserRoles } from "features/roles/UserRoleSlice";
import { addUser, fetchUsers, updUser } from "../../users/UsersSlice";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { delUserRole } from "features/roles/UserRoleSlice";
import { getCurrentModule } from "utils/urlUtils";
import { CustomCheckboxCell } from "components/Inventory/customCells";
import { IsMedSmall } from "utils/mediaQueries";
import { useTypedTranslation } from "utils/customHooks";
import { getDateFromDateString } from "utils/DateFunctions";
import { IppDisplayCheckbox } from "components/IppDisplayCheckbox";
import { isEqual } from "lodash";
import { pwChangeForUser } from "api/usersAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IppButton } from "components/Buttons/IppButton";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";

const PREFIX = "UserForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface UserFormProps {
  companyID: string;
}

export const UserForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "objBen", "strGen"]);
  const customBP = IsMedSmall();

  const { companyID } = useParams<UserFormProps>();
  const compID = parseInt(companyID, 10);
  const history = useHistory();

  let userData = props.user || {};
  const userCompanyID = userData?.CompanyID;

  const [isEditing, setIsEditing] = useState(
    userData.UserAccountID ? false : true
  );
  const [isAdding] = useState(userData.UserAccountID ? false : true);
  const [userID] = useState(userData.UserAccountID);
  const [projectID, setProjectID] = useState(0);
  const [moduleID, setModuleID] = useState(0);
  const [roleID, setRoleID] = useState(0);
  const [activeForNotification, setActiveForNotification] = useState(true);
  const [filteredRoles, setFilteredRoles] = useState([] as any);
  const [showBulkAdd, setShowBulkAdd] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { clientId } = useSelector((state: RootState) => state.client);
  const { currentProfile } = useSelector((state: RootState) => state.profile);
  const [editOpen, setEditOpen] = useState(false);

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const confirmedCompanies = companies.filter((comp) => comp.ListingConfirmed);
  const clientCompanyIndex = confirmedCompanies.findIndex(
    (obj) => obj.IsClient
  );

  if (clientCompanyIndex !== -1) {
    const clientCompany = confirmedCompanies[clientCompanyIndex];
    confirmedCompanies.splice(clientCompanyIndex, 1);
    confirmedCompanies.unshift(clientCompany);
  }

  const clientCompany = companies.find((obj) => obj.IsClient);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((id) => projectsById[id]);

  const {
    clientModuleList,
    clientModulesById,
    isLoading: clientModuleIsLoading,
  } = useSelector((state: RootState) => state.clientModules);

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const {
    moduleRoleList,
    moduleRolesById,
    isLoading: moduleRoleIsLoading,
  } = useSelector((state: RootState) => state.moduleRoles);

  const moduleroles = moduleRoleList.map((id) => moduleRolesById[id]);

  const {
    userRoleList,
    userRolesById,
    isLoading: userRoleIsLoading,
  } = useSelector((state: RootState) => state.userRoles);

  const userRoles = userRoleList.map((id) => userRolesById[id]);

  const {
    userList,
    isLoading: userIsLoaded,
    error: usersError,
    usersById,
  } = useSelector((state: RootState) => state.users);

  const users = userList.map((id) => usersById[id]);
  const [filteredModule, setFilteredModule] = useState(clientmodules);

  // watch for double loading things from UseState
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanies(accessToken, clientId));
        dispatch(fetchProjects(accessToken));
        dispatch(fetchModuleRoles(accessToken));
        if (!isAdding) {
          dispatch(fetchUserRoles(accessToken, userID));
        }
        if (users.length === 0) {
          dispatch(fetchUsers(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    // filter module if user is not part of client
    if (!isAdding && currentProfile.CompanyID != userData.CompanyID) {
      setFilteredModule(
        clientmodules.filter((obj) => obj.ModuleID === 1 || obj.ModuleID === 6)
      );
    }

    let mod = clientmodules.find((obj) => obj.ClientModuleID === moduleID);
    let modID = 0;
    if (mod && mod.ModuleID) {
      modID = mod.ModuleID;
    }

    //Filter roles by module
    let roles = moduleroles.filter((Record) => Record.ModuleID === modID);

    //roles = dropdown, newList = existing roles
    roles = roles.filter((r1: any) => {
      // Check if Role module is in existing
      const modExist = newList.find(
        (r2: any) => r1.ClientModuleID === r2.ClientModuleID
      );

      if (!modExist) {
        return true;
      }
      // filters out roles with same module role and projects
      const check1 = newList.find(
        (r2: any) =>
          r1.ModuleRoleID === r2.ModuleRoleID && r2.ProjectID === projectID
      );
      // checks if the moduleId matches any in the users existing roles
      // If there is a match, the item is kept in the array. Otherwise, it is removed
      const check2 =
        r1.ModuleRoleID ===
          newList.find((r2: any) => r2.ClientModuleID === r1.ClientModuleID)
            ?.ModuleRoleID || false;

      return !check1 && check2;
    });

    if (modID === 1) {
      let company = companies.filter(
        (company) => company.CompanyID === userData.CompanyID
      );

      // removes company user role for client users
      if (company[0] && company[0].IsClient) {
        roles = roles.filter(
          (moduleRole) =>
            moduleRole.RoleName.toLowerCase() !== "company user" &&
            moduleRole.ModuleID === modID
        );
      } else {
        // removes client power user roles for company users
        roles = roles.filter(
          (moduleRole) =>
            moduleRole.RoleName.toLowerCase() !== "client power user" &&
            moduleRole.ModuleID === modID
        );
      }
    }

    setFilteredRoles(roles);
  }, [moduleID, projectID]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        let curmodule = getCurrentModule();
        let returnRoute = "";

        if (curmodule === "benefits") {
          returnRoute =
            "/benefits/companies/" + formik.values.CompanyID + "/users";
        } else {
          returnRoute = "/admin/users";
        }

        dispatch(addUser(accessToken, props.clientId, values, returnRoute));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updUser(accessToken, props.clientId, values.UserAccountID, values)
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case
    userData.UserTitle = "";
    userData.FirstName = "";
    userData.Surname = "";
    userData.EmailAddress = "";

    if (compID) {
      userData.CompanyID = compID;
    } else {
      userData.CompanyID = -1;
    }
    userData.IsActive = true;
    userData.IsClientAdmin = false;
    userData.IsPrimary = false;
  }

  const newList = userRoles.map((p) => {
    let n = Object.assign({}, p) as any;
    if (!projectIsLoading && !userRoleIsLoading && projectsById[n.ProjectID]) {
      n.ProjectName = projectsById[n.ProjectID].ProjectName;
    }

    if (!clientModuleIsLoading && clientModulesById[n.ClientModuleID]) {
      n.ModuleName = clientModulesById[n.ClientModuleID].ModuleName;
    }
    if (!userRoleIsLoading && moduleRolesById[n.ModuleRoleID]) {
      n.RoleName = moduleRolesById[n.ModuleRoleID].RoleName;
    }
    return n;
  });

  interface NewRole {
    UserAccountID: number;
    ModuleRoleID: number;
    ClientModuleID: number;
    ActiveForNotification: boolean;
    ProjectID?: number;
  }

  const handleAddRole = (isBulk: boolean) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let newRole: NewRole = {
          UserAccountID: userData.UserAccountID,
          ModuleRoleID: roleID,
          ClientModuleID: moduleID,
          ActiveForNotification: activeForNotification,
        };
        if (!isBulk) {
          newRole = { ...newRole, ProjectID: projectID };
        }

        dispatch(addUserRole(accessToken, newRole, isBulk));

        setProjectID(0);
        setModuleID(0);
        setRoleID(0);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDeleteRole = (RecordID: number) => {
    // function to delete selected user role
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delUserRole(accessToken, RecordID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const RemoveRoleCell = (props: any) => {
    // custom cell for current role grid
    return (
      <td>
        <Tooltip title={t("objPlt:objects.user.role.tooltip")}>
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              if (window.confirm(t("strGen:prompts.delete.deleteinquiry")))
                handleDeleteRole(props.dataItem.UserRoleID);
            }}
          >
            <IconButton size="small" color="error">
              <Delete />
            </IconButton>
          </div>
        </Tooltip>
      </td>
    );
  };

  const validationSchema = yup.object({
    UserTitle: yup
      .string()
      .nullable()
      .max(50, "Title should not be more than 50 characters"),
    FirstName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.firstname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.firstname"),
          count: 50,
        })
      ),
    Surname: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.surname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.surname"),
          count: 50,
        })
      ),
    EmailAddress: yup
      .string()
      .email(
        t("strGen:validation.validrequired", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
        })
      )
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
          count: 100,
        })
      )
      .test(
        "checkDuplicate",
        t("strGen:validation.user.duplicate", {
          fieldname: t("objPlt:objects.user.name"),
          fieldname2: t("objPlt:objects.user.fields.emailaddress"),
        }),
        function (value: any) {
          if (value === userData.EmailAddress) {
            return true;
          }

          let email = value ? value : "";

          let checkDupe = users.filter(
            (obj: any) => obj.EmailAddress.toLowerCase() === email.toLowerCase()
          ).length;

          if (checkDupe === 0) {
            return true;
          } else {
            return false;
          }
        }
      ),
    CompanyID: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
  });

  const validationSchemaE = yup.object({
    UserTitle: yup
      .string()
      .nullable()
      .max(50, "Title should not be more than 50 characters"),
    FirstName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.firstname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.firstname"),
          count: 50,
        })
      ),
    Surname: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.surname"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.surname"),
          count: 50,
        })
      ),
    EmailAddress: yup
      .string()
      .email(
        t("strGen:validation.validrequired", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
        })
      )
      .max(
        100,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.user.fields.emailaddress"),
          count: 100,
        })
      ),
    CompanyID: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
  });

  const formik = useFormik({
    initialValues: userData,
    validationSchema: isAdding ? validationSchema : validationSchemaE,
    onSubmit: submitFunc,
  });

  const onClickPasswordChangeRequest = async (userID: number) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      pwChangeForUser(accessToken, userID).then((data: any) => {
        dispatch(openSnackBar(data, "success"));
      });
    } catch (e) {
      console.error(e);
    }
  };

  let returnPath = history.location.pathname;
  returnPath = returnPath.slice(0, returnPath.lastIndexOf("/"));

  const editForm = (
    <form onSubmit={formik.handleSubmit}>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2}>
            <IppFormHeader
              title={t("objPlt:objects.user.name")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath={returnPath}
            />
            <Grid item xs={6}>
              <IppTextField
                id="UserTitle"
                label="Position Title"
                required={false}
                value={formik.values.UserTitle}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.UserTitle}
                errorsExpression={formik.errors.UserTitle}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="FirstName"
                label={t("objPlt:objects.user.fields.firstname")}
                required={true}
                value={formik.values.FirstName}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.FirstName}
                errorsExpression={formik.errors.FirstName}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="Surname"
                label={t("objPlt:objects.user.fields.surname")}
                required={true}
                value={formik.values.Surname}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.Surname}
                errorsExpression={formik.errors.Surname}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="PrimaryPhone"
                label={t("objPlt:objects.user.fields.phoneprimary")}
                value={formik.values.PrimaryPhone}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.PrimaryPhone}
                errorsExpression={formik.errors.PrimaryPhone}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="AltPhone"
                label={t("objPlt:objects.user.fields.phonealternate")}
                value={formik.values.AltPhone}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.AltPhone}
                errorsExpression={formik.errors.AltPhone}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="EmailAddress"
                label={t("objPlt:objects.user.fields.emailaddress")}
                required={true}
                value={formik.values.EmailAddress}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.EmailAddress}
                errorsExpression={formik.errors.EmailAddress}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disabled={isEditing && !isAdding}
              />
            </Grid>
            <Grid item xs={6}>
              <IppAutocomplete
                id="CompanyID"
                options={confirmedCompanies.sort((a, b) =>
                  a.ShowAsActive.localeCompare(b.ShowAsActive)
                )}
                groupBy={(option: any) => option.ShowAsActive}
                value={confirmedCompanies.find((obj) => {
                  return obj.CompanyID === formik.values.CompanyID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("CompanyID", newValue.CompanyID);
                    formik.setFieldValue("IsClientAdmin", false);
                  } else {
                    formik.setFieldValue("CompanyID", -1);
                  }
                }}
                label={t("objBen:objects.company.name")}
                required={true}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disabled={isEditing && !isAdding}
                optionLabelFunction={(option: any) => option.CompanyName}
                renderOption={(props: any, option: Company) => {
                  return CompanyOptionsRender(props, option);
                }}
                touchedFunction={formik.touched.CompanyID}
                errorFunction={formik.errors.CompanyID}
                textValueFunction={
                  !companyIsLoading &&
                  formik.values.CompanyID > 0 &&
                  companiesById[formik.values.CompanyID]
                    ? companiesById[formik.values.CompanyID].CompanyName
                    : ""
                }
              />
            </Grid>
            {formik.values.CompanyID === clientCompany?.CompanyID && (
              <Grid item xs={6}>
                {isEditing || isAdding ? (
                  <IppCheckbox
                    id="IsClientAdmin"
                    label={t("objPlt:objects.user.fields.clientadministrator")}
                    value={formik.values.IsClientAdmin}
                    isEditing={isEditing}
                    onChangeFunction={(e: ChangeEvent) => {
                      formik.setFieldValue(
                        "IsClientAdmin",
                        !formik.values.IsClientAdmin
                      );
                    }}
                  />
                ) : (
                  <IppDisplayCheckbox
                    label={t("objPlt:objects.user.fields.clientadministrator")}
                    isChecked={formik.values.IsClientAdmin}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={6}>
              {(isEditing || isAdding) &&
              !(currentProfile.UserAccountID === userID) ? (
                <IppCheckbox
                  id="IsActive"
                  label={t("objPlt:objects.user.fields.useractive")}
                  value={formik.values.IsActive}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                />
              ) : (
                <IppDisplayCheckbox
                  label={t("objPlt:objects.user.fields.useractive")}
                  isChecked={formik.values.IsActive}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isEditing || isAdding ? (
                <IppCheckbox
                  id="IsPrimary"
                  label="Set as Primary User for Company"
                  value={formik.values.IsPrimary}
                  onChangeFunction={formik.handleChange}
                  isEditing={isEditing}
                />
              ) : (
                <IppDisplayCheckbox
                  label="Primary User for Company"
                  isChecked={formik.values.IsPrimary}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              )}
            </Grid>
            {!isEditing && (
              <Grid item xs={6}>
                <IppStaticTextfield
                  id="LastActive"
                  value={getDateFromDateString(formik.values.LastActive) || ""}
                  label={t("objPlt:objects.user.fields.lastonline")}
                />
              </Grid>
            )}
            {!isAdding &&
              !isEditing &&
              currentProfile.IsClientAdmin &&
              userID && (
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IppButton
                    color="warning"
                    startIcon={<Key />}
                    onClick={() => setConfirmOpen(true)}
                  >
                    Send Password Reset
                  </IppButton>
                </Grid>
              )}
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                resetFunction={() => formik.resetForm()}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(!confirmOpen)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">
          Send Password Reset Email?
        </DialogTitle>
        <DialogContent>
          Are you want to send a password reset email to this user?
        </DialogContent>
        <DialogActions>
          <IppButton
            onClick={() => {
              setConfirmOpen(false);
              onClickPasswordChangeRequest(userID);
            }}
            color="primary"
          >
            Yes
          </IppButton>
          <IppButton onClick={() => setConfirmOpen(false)} color="secondary">
            No
          </IppButton>
        </DialogActions>
      </Dialog>
    </form>
  );

  // State to manage Role ID when a New Module is selected
  const [newModule, setNewModule] = useState(false);

  useEffect(() => {
    // if the Module was changed, set Module Role to 0 to clear Role ID field
    if (newModule) {
      setRoleID(0);
      setNewModule(false);
    }
  }, [newModule]);

  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    // filter projects to only show projects they do not have a role for on the currently selected Module. If no Module is selected, show projects for which they could still have a new role on any Module
    let newFilteredProjs = [];

    if (moduleID && moduleID > 0) {
      newFilteredProjs = projects.filter((obj) => {
        const check = userRoles.find(
          (role) =>
            role.ProjectID === obj.ProjectID && role.ClientModuleID === moduleID
        );
        return !check;
      });
    } else {
      newFilteredProjs = projects.filter((project) => {
        return !clientmodules.every((module) => {
          return userRoles.some(
            (role) =>
              role.ProjectID === project.ProjectID &&
              role.ClientModuleID === module.ClientModuleID
          );
        });
      });
    }

    if (!isEqual(newFilteredProjs, filteredProjects)) {
      setFilteredProjects(newFilteredProjs);
    }
  }, [moduleID, userRoles, projects, clientmodules]);

  const addRoleForm = (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("objPlt:objects.user.role.header.add", {
                fieldname1: t("objPlt:objects.project.name"),
                fieldname2: t("objPlt:objects.user.role.name"),
                fieldname3: t("objPlt:objects.user.name"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id={"BulkAdd"}
              value={showBulkAdd}
              label={t("strGen:labels.user.role.bulkaddcheckbox", {
                objectname1: t("objPlt:objects.user.name"),
                objectname2: t("objPlt:objects.user.role.name", { count: 2 }),
                objectname3: t("objPlt:objects.project.name", { count: 2 }),
              })}
              tooltip={t("strGen:tooltips.user.role.bulkaddcheckbox", {
                objectname1: t("objPlt:objects.user.role.name"),
                objectname2: t("objPlt:objects.project.name", { count: 2 }),
              })}
              onChangeFunction={(newValue: any) => {
                setShowBulkAdd(!showBulkAdd);
                setProjectID(0);
              }}
              isEditing={isEditing}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCheckbox
              id="ActiveForNotification"
              label={t("objPlt:objects.user.role.notifications")}
              value={activeForNotification}
              onChangeFunction={(e: ChangeEvent) => {
                setActiveForNotification(!activeForNotification);
              }}
              isEditing={true}
            />
          </Grid>
          {!showBulkAdd && (
            <Grid item xs={6}>
              <IppAutocomplete
                id="ProjectID"
                options={filteredProjects}
                value={projects.find((obj) => {
                  return obj.ProjectID === projectID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    setProjectID(newValue.ProjectID);
                  } else setProjectID(0);
                }}
                label={t("objPlt:objects.project.name")}
                isEditing={true}
                setIsEditing={null}
                optionLabelFunction={(option: any) => option.ProjectName}
                textValueFunction={
                  projectID > 0 && !projectIsLoading
                    ? projectsById[projectID].ProjectName
                    : ""
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <IppAutocomplete
              id="ModuleID"
              options={filteredModule}
              value={
                clientmodules.find((obj) => {
                  return obj.ClientModuleID === moduleID;
                }) || ""
              }
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  setNewModule(true);
                  setModuleID(newValue.ClientModuleID);
                } else setModuleID(0);
              }}
              label={t("strGen:module")}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.ModuleName || ""}
              textValueFunction={
                moduleID > 0 && !clientModuleIsLoading
                  ? clientModulesById[moduleID].ModuleName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="RoleID"
              disabled={moduleID === 0}
              options={filteredRoles}
              value={
                moduleroles.find((obj) => {
                  return obj.ModuleRoleID === roleID;
                }) || ""
              }
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  setRoleID(newValue.ModuleRoleID);
                } else {
                  formik.setFieldValue("RoleID", -1);
                }
              }}
              label={t("objPlt:objects.user.role.name")}
              isEditing={true}
              setIsEditing={null}
              optionLabelFunction={(option: any) => option.RoleName || ""}
              textValueFunction={
                roleID > 0 && !moduleRoleIsLoading
                  ? moduleRolesById[roleID].RoleName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            {showBulkAdd ? (
              <Tooltip
                title={t("strGen:tooltips.user.role.bulkadd", {
                  objectname1: t("objPlt:objects.user.role.name"),
                  objectname2: t("objPlt:objects.project.name", { count: 2 }),
                })}
              >
                <IppButton
                  color={"warning"}
                  disabled={moduleID === 0 || roleID === 0}
                  onClick={() => handleAddRole(true)}
                >
                  {t("strGen:buttons.addtoallprojects", {
                    objectname1: t("objPlt:objects.user.role.name"),
                    objectname2: t("objPlt:objects.project.name", { count: 2 }),
                  })}
                </IppButton>
              </Tooltip>
            ) : (
              <IppButton
                color={"secondary"}
                disabled={projectID === 0 || moduleID === 0 || roleID === 0}
                onClick={() => handleAddRole(false)}
              >
                {t("strGen:buttons.addobj", {
                  objectname: t("objPlt:objects.user.role.name"),
                })}
              </IppButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );

  const currentRoleForm = (
    <Paper>
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("objPlt:objects.user.role.currentroles")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {userRoleIsLoading ? (
                  <LoadingIndicator />
                ) : (
                  <KendoGrid data={newList}>
                    <GridColumn
                      field="ProjectName"
                      title={t("objPlt:objects.project.name")}
                    />
                    <GridColumn field="ModuleName" title={t("strGen:module")} />
                    <GridColumn
                      field="RoleName"
                      title={t("objPlt:objects.user.role.name")}
                    />
                    <GridColumn
                      field="ActiveForNotification"
                      title={t("objPlt:objects.user.role.notify")}
                      cell={CustomCheckboxCell}
                      width={70}
                    />
                    <GridColumn cell={RemoveRoleCell} width={35} title="" />
                  </KendoGrid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );

  return (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      {isAdding || isEditing ? (
        <Box display="flex" justifyContent="center">
          <Grid container className={classes.editForm} spacing={1}>
            {editForm}
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={customBP ? 12 : 6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {editForm}
              </Grid>
              <Grid item xs={12}>
                {!formik.values.IsClientAdmin && addRoleForm}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={customBP ? 12 : 6}>
            {currentRoleForm}
          </Grid>
        </Grid>
      )}
    </Root>
  );
};
